import React from 'react';
import {View} from 'react-native';

import OrderStatus from '../../../types/OrderStatus';
import DraftsIcon from '../../../images/md-icons/drafts/materialicons/24px.svg';
import EmailIcon from '../../../images/md-icons/email/materialicons/24px.svg';
import AssignmentIcon from '../../../images/md-icons/assignment/materialicons/24px.svg';
import AssignmentIndIcon from '../../../images/md-icons/assignment_ind/materialicons/24px.svg';
import AssignmentReturnIcon from '../../../images/md-icons/assignment_return/materialicons/24px.svg';
import AssignmentTurnedInIcon from '../../../images/md-icons/assignment_turned_in/materialicons/24px.svg';
import EuroSymbolIcon from '../../../images/md-icons/euro_symbol/materialicons/24px.svg';
import TextSmsIcon from '../../../images/md-icons/textsms/materialicons/24px.svg';
import UpdateIcon from '../../../images/md-icons/update/materialicons/24px.svg';

const OrderStatusIcon = ({style, status, iconProps}) => {
  let icon;
  switch (status) {
    case OrderStatus.Draft: {
      icon = <DraftsIcon fill="#231fda" {...iconProps} />;
      break;
    }
    case OrderStatus.Created: {
      icon = <EmailIcon fill="#231fda" {...iconProps} />;
      break;
    }
    case OrderStatus.Pending: {
      icon = <AssignmentReturnIcon fill="#231fda" {...iconProps} />;
      break;
    }
    case OrderStatus.Diagnosis: {
      icon = <AssignmentIndIcon fill="#231fda" {...iconProps} />;
      break;
    }
    case OrderStatus.Rejected:
    case OrderStatus.ApprovalPending:
    case OrderStatus.Confirmed: {
      icon = <AssignmentIcon fill="#231fda" {...iconProps} />;
      break;
    }
    case OrderStatus.ApprovalRequested: {
      icon = <AssignmentReturnIcon fill="#231fda" {...iconProps} />;
      break;
    }
    case OrderStatus.ApprovalFinished: {
      icon = <AssignmentIcon fill="#231fda" {...iconProps} />;
      break;
    }
    case OrderStatus.Done:
    case OrderStatus.Finished: {
      icon = <AssignmentTurnedInIcon fill="#231fda" {...iconProps} />;
      break;
    }
    case OrderStatus.PaymentReceived: {
      icon = <EuroSymbolIcon fill="#49494a" {...iconProps} />;
      break;
    }
    case OrderStatus.ReminderSent: {
      icon = <TextSmsIcon fill="#49494a" {...iconProps} />;
      break;
    }
    case OrderStatus.EmailReminderSent: {
      icon = <EmailIcon fill="#49494a" {...iconProps} />;
      break;
    }
    case OrderStatus.DateChanged:
    case OrderStatus.TimeChanged: {
      icon = <UpdateIcon fill="#49494a" {...iconProps} />;
      break;
    }

    default: {
      icon = null;
      break;
    }
  }

  return <View style={style}>{icon}</View>;
};

export default OrderStatusIcon;
