import React, {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {SafeAreaView, View, ScrollView} from 'react-native';
import _ from 'lodash';

import globalStyles from '../../../styles';
import styles from './styles';
import {
  PrimaryButton,
  RadioButton,
  Pressable,
  FormTextInput,
  Text,
  LinearProgress,
  CloseButton,
} from '../../../components/controls';
import {useDispatch, useSelector} from '../../../lib/hooks';
import {
  addJobSetExtra,
  addJobSetWaiting,
  addJobSetWantsElectricBike,
  addJobSetPickupLocation,
} from '../../../actions';
import {
  booking as bookingApi,
  address as addressApi,
} from '../../../api/public';
import {
  Extras as ExtrasGroups,
  EXTRAS_CODES,
} from '../../../types/service-codes';
import Select from '../../../components/controls/Select';
import PublicContentView from '../../../components/shared/PublicContentView';
import ShoppingCartIcon from '../../../images/md-icons/shopping_cart/materialicons/24px.svg';

const Address = ({postalCode, houseNumber, annex, onChange}) => {
  const [address, setAddress] = useState('');

  /* eslint-disable react-hooks/exhaustive-deps */
  const _fetchAddress = useCallback(
    _.debounce(async (_postalCode, _houseNumber, _annex) => {
      const {address} = await addressApi.fetch(
        _postalCode,
        _houseNumber,
        _annex,
      );
      setAddress(address);
    }, 500),
    [setAddress],
  );

  useEffect(() => {
    if (postalCode?.length === 6) {
      _fetchAddress(postalCode, houseNumber, annex);
    }
  }, [postalCode, houseNumber, annex, _fetchAddress]);

  const _onChange = (address) => {
    onChange({
      postalCode,
      houseNumber,
      annex,
      ...address,
    });
  };

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <FormTextInput
          value={postalCode}
          style={{width: 115}}
          label="Postcode"
          inputProps={{
            maxLength: 6,
            autoCapitalize: 'characters',
          }}
          onChangeText={(text) => _onChange({postalCode: text.toUpperCase()})}
        />
        <FormTextInput
          value={houseNumber}
          style={{width: 115}}
          label="Huisnummer"
          inputProps={{
            maxLength: 6,
          }}
          onChangeText={(text) => _onChange({houseNumber: text})}
        />
        <FormTextInput
          value={annex}
          style={{width: 115}}
          label="Toevoeging"
          onChangeText={(text) => _onChange({annex: text})}
        />
      </View>
      <Text style={{lineHeight: 32}}>{address}</Text>
    </>
  );
};

const Extras = ({navigation, onDismiss}) => {
  const dispatch = useDispatch();
  const dealer = useSelector((state) => state.dealer);

  const stored = useSelector((state) => ({
    extra: state.extra,
    waiting: state.waiting,
    wants_electric_bike: state.wants_electric_bike,
    pick_up_location: state.pick_up_location,
  }));

  const [storedExtra, setExtra] = useState(stored.extra);
  const [waiting, setWaiting] = useState(stored.waiting);
  const [wants_electric_bike, setWantsElectricBike] = useState(
    stored.wants_electric_bike,
  );
  const [pick_up_location, setPickupLocation] = useState(
    stored.pick_up_location,
  );
  const [loading, setLoading] = useState(true);
  const [extras, setExtras] = useState({});

  useLayoutEffect(() => {
    navigation?.setOptions({
      headerTitle: () => (
        <View style={{alignItems: 'center'}}>
          <LinearProgress style={{width: 126}} step={6} total={7} />
        </View>
      ),
      headerRight: () => <View />,
    });
  }, [navigation]);

  useEffect(() => {
    const fetch = async () => {
      const extras = await bookingApi.extras(dealer.id);
      setExtras(extras);
      setLoading(false);
    };

    fetch();
  }, [dealer]);

  const onNext = async () => {
    dispatch(addJobSetExtra(storedExtra));
    dispatch(addJobSetWaiting(waiting));
    dispatch(addJobSetWantsElectricBike(wants_electric_bike));
    dispatch(addJobSetPickupLocation(pick_up_location));

    if (onDismiss) {
      onDismiss();
    } else {
      navigation.navigate('Confirm');
    }
  };

  const groups = Object.keys(extras)
    .map((key) => {
      if (ExtrasGroups[key]) {
        return {
          key,
          title: ExtrasGroups[key].title,
        };
      }
      return null;
    })
    .filter(Boolean);

  const content = (
    <>
      {!onDismiss ? (
        <View style={globalStyles.headerView}>
          <Text style={globalStyles.headerText}>Vervangend vervoer?</Text>
          <Text style={{marginTop: 10, fontSize: 18}}>
            Wachten tijdens het werk op locatie of maak een keuze voor
            vervangend vervoer.
          </Text>
        </View>
      ) : (
        <View style={globalStyles.modalHeaderView}>
          <CloseButton
            style={{position: 'absolute', top: 8, left: 8}}
            onPress={onDismiss}
          />
          <Text style={globalStyles.medium}>Vervangend vervoer</Text>
        </View>
      )}
      <View style={globalStyles.contentView}>
        {!loading && (
          <>
            {groups?.map((group) => {
              const options = extras[group.key].map((extra) => ({
                value: extra.code,
                text: extra.title,
              }));
              let selected;
              if (storedExtra) {
                selected = options.find((item) => item.value === storedExtra);
              } else {
                selected = options[0];
                setExtra(selected.value);
              }

              let extraOption = null;
              switch (selected.value) {
                case EXTRAS_CODES.EXTRA_NONE:
                  extraOption = (
                    <View>
                      <View style={styles.row}>
                        <Text style={globalStyles.mediumPlus}>Wachten?</Text>
                      </View>
                      <Pressable
                        style={styles.radioButtonRow}
                        onPress={() => setWaiting(true)}>
                        <RadioButton checked={waiting} />
                        <View style={styles.radioButtonDescription}>
                          <Text>Ja</Text>
                        </View>
                      </Pressable>
                      <Pressable
                        style={styles.radioButtonRow}
                        onPress={() => setWaiting(false)}>
                        <RadioButton checked={!waiting} />
                        <View style={styles.radioButtonDescription}>
                          <Text>Nee</Text>
                        </View>
                      </Pressable>
                    </View>
                  );
                  break;
                case EXTRAS_CODES.BIKE:
                  extraOption = (
                    <View>
                      <View style={styles.row}>
                        <Text style={globalStyles.mediumPlus}>Elektrisch?</Text>
                      </View>
                      <Pressable
                        style={styles.radioButtonRow}
                        onPress={() => setWantsElectricBike(true)}>
                        <RadioButton checked={wants_electric_bike} />
                        <View style={styles.radioButtonDescription}>
                          <Text>Ja</Text>
                        </View>
                      </Pressable>
                      <Pressable
                        style={styles.radioButtonRow}
                        onPress={() => setWantsElectricBike(false)}>
                        <RadioButton checked={!wants_electric_bike} />
                        <View style={styles.radioButtonDescription}>
                          <Text>Nee</Text>
                        </View>
                      </Pressable>
                    </View>
                  );
                  break;
                case EXTRAS_CODES.PICK_UP:
                  extraOption = (
                    <View>
                      <View style={styles.row}>
                        <Text style={[globalStyles.mediumPlus, styles.label]}>
                          Ophaallocatie
                        </Text>
                        <Address
                          {...pick_up_location?.pick_up}
                          onChange={(address) =>
                            setPickupLocation({
                              ...pick_up_location,
                              pick_up: address,
                            })
                          }
                        />
                      </View>
                      <View style={styles.row}>
                        <Text style={globalStyles.mediumPlus}>
                          Afleverlocatie hetzelfde?
                        </Text>
                      </View>
                      <Pressable
                        style={styles.radioButtonRow}
                        onPress={() =>
                          setPickupLocation({
                            ...pick_up_location,
                            deliver_different_address: false,
                          })
                        }>
                        <RadioButton
                          checked={!pick_up_location?.deliver_different_address}
                        />
                        <View style={styles.radioButtonDescription}>
                          <Text>Ja</Text>
                        </View>
                      </Pressable>
                      <Pressable
                        style={styles.radioButtonRow}
                        onPress={() =>
                          setPickupLocation({
                            ...pick_up_location,
                            deliver_different_address: true,
                          })
                        }>
                        <RadioButton
                          checked={pick_up_location?.deliver_different_address}
                        />
                        <View style={styles.radioButtonDescription}>
                          <Text>Nee</Text>
                        </View>
                      </Pressable>
                      {pick_up_location?.deliver_different_address && (
                        <View style={styles.row}>
                          <Address
                            {...pick_up_location?.deliver}
                            onChange={(address) =>
                              setPickupLocation({
                                ...pick_up_location,
                                deliver: address,
                              })
                            }
                          />
                        </View>
                      )}
                    </View>
                  );
                  break;
                default:
                  extraOption = null;
                  break;
              }

              return (
                <React.Fragment key={group.key}>
                  <View style={styles.article}>
                    <Text style={[globalStyles.mediumPlus, styles.label]}>
                      {group.title}
                    </Text>
                    <Select
                      defaultText="Selecteer"
                      options={options}
                      value={selected}
                      onChange={(option) => setExtra(option.value)}
                    />
                  </View>
                  {extraOption}
                </React.Fragment>
              );
            })}
          </>
        )}
      </View>
    </>
  );

  const footer = (
    <View style={globalStyles.footerView}>
      <PrimaryButton
        style={{minWidth: 152}}
        onPress={onNext}
        icon={
          !onDismiss &&
          (() => <ShoppingCartIcon fill="#ffffff" width={18} height={18} />)
        }>
        {onDismiss ? 'Opslaan' : 'Controleren'}
      </PrimaryButton>
    </View>
  );

  return (
    <SafeAreaView style={globalStyles.mainView}>
      {onDismiss ? (
        <>
          <ScrollView>{content}</ScrollView>
          <View style={{borderTopWidth: 1, borderTopColor: '#dcdcdc'}}>
            {footer}
          </View>
        </>
      ) : (
        <PublicContentView>
          {{
            content,
            footer,
          }}
        </PublicContentView>
      )}
    </SafeAreaView>
  );
};

export default Extras;
