import React, {useRef, useState} from 'react';
import {View, ScrollView, useWindowDimensions} from 'react-native';

import styles from './styles';
import Footer from '../Footer';
import PublicContentContext from '../../context/PublicContentContext';

let scrollBarWidth = null;

const PublicContentView = ({footerStyle, children}) => {
  const windowDimensions = useWindowDimensions();
  const scrollRef = useRef(null);
  const [rootLayout, setRootLayout] = useState(null);
  const [contentLayout, setContentLayout] = useState(null);
  const [footerLayout, setFooterLayout] = useState(null);
  const [float, setFloat] = useState(true);

  const onRootLayout = (e) => {
    const {layout} = e.nativeEvent;
    setRootLayout(layout);
  };

  const onContentLayout = (e) => {
    const {layout} = e.nativeEvent;
    setContentLayout(layout);
  };

  const onFooterLayout = (e) => {
    const {layout} = e.nativeEvent;
    setFooterLayout(layout);
  };

  const onScroll = (e) => {
    const {contentOffset, contentSize, layoutMeasurement} = e.nativeEvent;

    const footerVisible =
      contentSize.height - contentOffset.y - layoutMeasurement.height <
      footerLayout.height;
    setFloat(!footerVisible);
  };

  if (rootLayout && contentLayout) {
    scrollBarWidth = rootLayout.width - contentLayout.width;
  }

  let {content, footer} = children;
  if (!content) {
    content = children;
  }

  return (
    <>
      <View onLayout={onRootLayout} style={styles.root}>
        <ScrollView
          ref={scrollRef}
          contentContainerStyle={{flex: 1}}
          scrollEventThrottle={1}
          onScroll={onScroll}>
          <PublicContentContext.Provider value={{scrollRef}}>
            <View
              onLayout={onContentLayout}
              style={[
                styles.contentContainer,
                {
                  minHeight: rootLayout
                    ? rootLayout?.height
                    : windowDimensions.height,
                },
              ]}>
              {content}
            </View>
            {footer && (
              <View style={[styles.footer, footerStyle]}>{footer}</View>
            )}
            <View onLayout={onFooterLayout}>
              <Footer
                onScrollToTop={() =>
                  scrollRef.current?.scrollTo({x: 0, y: 0, animated: true})
                }
              />
            </View>
          </PublicContentContext.Provider>
        </ScrollView>
        {float && footer && (
          <View
            style={[
              styles.footer,
              footerStyle,
              {
                display: 'flex',
                position: 'absolute',
                bottom: 0,
                paddingRight: scrollBarWidth,
              },
            ]}>
            {footer}
          </View>
        )}
      </View>
    </>
  );
};

export default PublicContentView;
