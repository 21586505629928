import React, {useEffect, useLayoutEffect, useState} from 'react';
import {SafeAreaView, View, ActivityIndicator, Image} from 'react-native';

import styles from './styles';
import globalStyles from '../../styles';
import {dealer as dealerApi, request as requestApi} from '../../api/public';
import {DownloadLink, Link, Pressable, Text} from '../../components/controls';
import {PublicContentView} from '../../components/shared';
import Format from '../../lib/format';
import {useDispatch, useSelector} from '../../lib/hooks';
import {setDealer} from '../../actions';
import {apiRoot as publicApiRoot} from '../../api/public/endpoints';
import ExpandLessIcon from '../../images/md-icons/expand_less/materialicons/24px.svg';
import ExpandMoreIcon from '../../images/md-icons/expand_more/materialicons/24px.svg';
import ChevronLeftIcon from '../../images/md-icons/chevron_left/materialicons/24px.svg';

const Pickup = ({route, navigation}) => {
  const dispatch = useDispatch();
  const order_id = route.params?.order_id;
  const dealer = useSelector((state) => state.dealer);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState({});
  const [request, setRequest] = useState({});
  const [imageWidth, setImageWidth] = useState(null);
  const [expand, setExpand] = useState(false);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerLeft: () => (
        <Pressable
          style={{paddingLeft: 12}}
          onPress={() => {
            navigation.navigate('Chat', {order_id});
          }}>
          <ChevronLeftIcon />
        </Pressable>
      ),
      headerTitle: () => (
        <View style={{alignItems: 'center'}}>
          <Text>&nbsp;</Text>
        </View>
      ),
    });
  }, [navigation, order_id]);

  useEffect(() => {
    const fetch = async () => {
      const request = await requestApi.fetch(order_id);
      const {order} = request;

      const dealer = await dealerApi.details(order.dealer_id);
      dispatch(setDealer(dealer));
      setOrder(order);
      setRequest(request);
      setLoading(false);
    };

    fetch();
  }, [order_id, navigation, dispatch]);

  const onPhotoLayout = (e) => {
    const {layout} = e.nativeEvent;
    setImageWidth(layout.width);
  };

  const imageHeight = imageWidth ? imageWidth * (186.56 / 414) : null;

  const is_lease = !!order?.lease_company;

  const approved_tasks = request?.tasks?.filter(
    (task) => task.is_approved === true && (!task.is_extra || task.amount > 0),
  );
  const not_approved_tasks = request?.tasks?.filter(
    (task) => task.is_approved === false,
  );

  return (
    <SafeAreaView style={globalStyles.mainView}>
      {loading && <ActivityIndicator size="large" color="#231fda" />}
      {!loading && (
        <PublicContentView>
          {{
            content: (
              <>
                <Image
                  onLayout={onPhotoLayout}
                  style={[styles.photo, {height: imageHeight}]}
                  source={{
                    uri: `${publicApiRoot}/dealer/photo/${dealer.id}`,
                  }}
                />
                <View style={[globalStyles.contentView, styles.contentView]}>
                  <View style={styles.article}>
                    <View style={styles.row}>
                      <Text style={globalStyles.xxLarge}>{dealer.name}</Text>
                    </View>
                  </View>
                  <View style={styles.article}>
                    <View style={styles.row}>
                      <Text style={globalStyles.mediumPlus}>Ons adres</Text>
                      <DownloadLink
                        href={`https://maps.google.com?q=${dealer.address} ${dealer.city}`}>
                        Route
                      </DownloadLink>
                    </View>
                    <View style={[styles.row, styles.last]}>
                      <Text>
                        {dealer.address} in {dealer.city}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.article}>
                    <View style={styles.row}>
                      <Text style={globalStyles.mediumPlus}>Opdracht</Text>
                    </View>
                    {approved_tasks.map((task, index) => (
                      <View key={index} style={styles.row}>
                        <Text>{task.description}</Text>
                        {!is_lease && <Text>{Format.price(task.amount)}</Text>}
                      </View>
                    ))}
                    {!is_lease && (
                      <View style={styles.row}>
                        <View>
                          <Text>Totaal</Text>
                          <Text style={globalStyles.small}>Inclusief BTW</Text>
                        </View>
                        <Text>{Format.price(order.total)}</Text>
                      </View>
                    )}
                  </View>
                  {not_approved_tasks?.length > 0 && (
                    <View style={styles.article}>
                      <Pressable
                        style={styles.row}
                        onPress={() => setExpand(!expand)}>
                        <Text style={globalStyles.mediumPlus}>
                          Afgewezen&nbsp;({not_approved_tasks.length})
                        </Text>
                        {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </Pressable>
                      {expand &&
                        not_approved_tasks.map((task, index) => (
                          <View key={index} style={styles.row}>
                            <Text>{task.description}</Text>
                            {!is_lease && (
                              <Text>{Format.price(task.amount)}</Text>
                            )}
                          </View>
                        ))}
                    </View>
                  )}
                  {!is_lease && (
                    <View style={styles.article}>
                      <View style={styles.row}>
                        <Text style={globalStyles.mediumPlus}>Betaling</Text>
                      </View>
                      <View style={styles.row}>
                        <Text>Voldaan</Text>
                        <Text>{Format.price(order.total - order.due)}</Text>
                      </View>
                      <View style={styles.row}>
                        <Text>Openstaand</Text>
                        <Text>{Format.price(order.due)}</Text>
                      </View>
                      {order.due > 0 && (
                        <View style={styles.row}>
                          <Link
                            onPress={() => {
                              navigation.navigate('Pay', {order_id});
                            }}>
                            Openstaand bedrag betalen
                          </Link>
                        </View>
                      )}
                    </View>
                  )}
                </View>
              </>
            ),
            footer: null,
          }}
        </PublicContentView>
      )}
    </SafeAreaView>
  );
};

export default Pickup;
