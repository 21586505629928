import {StyleSheet} from 'react-native';

const styles = {
  photo: {
    width: '100%',
  },
  dealer: {
    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  dealerName: {
    paddingBottom: 12,
  },
  article: {
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
    marginTop: -1,
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingVertical: 16,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    marginBottom: -1,
    minHeight: 56,
  },
  tasks: {},
  task: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingLeft: 16,
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    marginBottom: -1,
  },
  taskDescription: {
    flex: 1,
    paddingVertical: 7,
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerView: {
    flexDirection: 'row',
    paddingHorizontal: 0,
    paddingVertical: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 0,
  },
  footerRow: {
    flexDirection: 'row',
  },
  footerButton: {
    flex: 1,
    borderRadius: 0,
    minHeight: 68,
    justifyContent: 'center',
    textTransform: 'uppercase',
  },
  rejectButton: {
    backgroundColor: '#992929',
  },
  modalHeaderView: {
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 16,
  },
  modalContentView: {
    marginBottom: 0,
    borderBottomColor: '#dcdcdc',
    borderBottomWidth: 1,
  },
  closeButton: {
    position: 'absolute',
    left: 0,
  },
  diagnosisPhoto: {
    marginVertical: 16,
    width: '100%',
    height: 271,
  },
  rejectLink: {
    color: '#972727',
  },
  link: {
    textDecorationLine: 'underline',
  },
};

export default StyleSheet.create(styles);
