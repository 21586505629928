import React, {useCallback, useEffect, useState} from 'react';
import {SafeAreaView, View, ActivityIndicator} from 'react-native';
import _ from 'lodash';

import styles from './styles';
import globalStyles from '../../../styles';
import {Success, Failure, PublicContentView} from '../../../components/shared';
import {PrimaryButton, DefaultButton, Text} from '../../../components/controls';
import {payment as paymentApi, order as orderApi} from '../../../api/public';

const SUCCESS_TITLE = 'Bedankt voor je betaling!';
const FAILURE_TITLE = 'Er is iets fout gegaan met de betaling.';
const FAILURE_DESCR = 'Probeer de betaling opnieuw.';

const Complete = (props) => {
  const {route, navigation, onComplete} = props;
  const order_id = route.params?.order_id;
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [order, setOrder] = useState({});

  const navigateToChat = useCallback(() => {
    navigation.replace('Overview', {
      screen: 'Chat',
      params: {order_id},
    });
  }, [navigation, order_id]);

  useEffect(() => {
    if (!order_id) {
      return;
    }

    const fetch = async () => {
      const order = await orderApi.details(order_id);
      const result = await paymentApi.result(order_id);

      if (result?.success) {
        navigateToChat();
        return;
      }

      setSuccess(result?.success);
      setOrder(order);
      setLoading(false);
    };

    fetch();
  }, [order_id, navigateToChat]);

  const description = _.isFunction(props.description)
    ? props.description({order})
    : props.description;

  return (
    <SafeAreaView style={globalStyles.mainView}>
      {loading && <ActivityIndicator size="large" color="#231fda" />}
      {!loading && (
        <>
          <PublicContentView>
            {{
              content: (
                <>
                  <View style={[globalStyles.headerView, styles.headerView]}>
                    <Text style={globalStyles.headerText}>
                      {success ? SUCCESS_TITLE : FAILURE_TITLE}
                    </Text>
                  </View>
                  <View style={[globalStyles.contentView, styles.contentView]}>
                    <Text
                      style={[
                        globalStyles.regular,
                        {fontSize: 18, marginBottom: 16},
                      ]}>
                      {success ? description : FAILURE_DESCR}
                    </Text>
                    <View
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      {success ? <Success /> : <Failure />}
                    </View>
                  </View>
                </>
              ),
              footer: (
                <>
                  {!success && (
                    <View
                      style={[
                        globalStyles.footerView,
                        {alignItems: 'stretch'},
                      ]}>
                      <PrimaryButton
                        style={[globalStyles.footerButton, {flex: 1}]}
                        onPress={() => {
                          navigation.replace('Pay', {order_id});
                        }}>
                        Opnieuw proberen
                      </PrimaryButton>
                    </View>
                  )}
                  {success && onComplete && (
                    <View
                      style={[
                        globalStyles.footerView,
                        {alignItems: 'stretch'},
                      ]}>
                      <DefaultButton
                        style={[globalStyles.transparentButton, {flex: 1}]}
                        textStyle={{
                          color: '#231fda',
                          textTransform: 'uppercase',
                          fontSize: 14,
                        }}
                        onPress={onComplete}>
                        <Text>Sluiten</Text>
                      </DefaultButton>
                    </View>
                  )}
                </>
              ),
            }}
          </PublicContentView>
        </>
      )}
    </SafeAreaView>
  );
};

export default Complete;
