import React from 'react';
import {View, ScrollView} from 'react-native';
import moment from 'moment';

import {Text, InfoButton, Persona} from '../../controls';
import {PersonaSize} from '../../controls/Persona';
import HtmlView from '../HtmlView';

import styles from './styles';
import globalStyles from '../../../styles';
import Format from '../../../lib/format';
import WarningIcon from '../../../images/md-icons/warning/materialicons/24px.svg';

const Task = ({task}) => {
  if (!task) {
    return <></>;
  }

  const parts_total = task.is_overall_price
    ? 0
    : task?.parts.reduce((acc, part) => acc + part.price * part.quantity, 0);
  const task_total =
    task?.price + task?.markup_fee + task?.environment_surcharge + parts_total;

  const rows = [
    {
      description: 'Werkzaamheden',
      amount: task?.price,
    },
  ]
    .concat(
      task?.parts.map((part) => ({
        description: part.designation,
        part_description: part.description,
        no: part.no,
        amount: task.is_overall_price
          ? null
          : part.price * (part.quantity || 1),
      })),
    )
    .concat([
      task?.markup_fee > 0
        ? {description: 'Klein materiaal', amount: task?.markup_fee}
        : null,
      task?.environment_surcharge > 0
        ? {description: 'Milieuheffing', amount: task?.environment_surcharge}
        : null,
    ])
    .filter(Boolean);

  let duration;
  if (task?.diagnosis_datetime) {
    const timestamp = moment(task?.diagnosis_datetime);
    duration = timestamp.format('DD-MM-YYYY');
    if (timestamp.isSame(moment(), 'day')) {
      duration = `${moment
        .duration(moment().diff(timestamp))
        .humanize()} geleden`;
    }
  }

  return (
    <>
      <ScrollView>
        <View
          style={[
            styles.row,
            {justifyContent: 'inherit', alignItems: 'center'},
          ]}>
          <Text style={globalStyles.mediumPlus}>{task.description}</Text>
          {task.is_maintenance_task && (
            <InfoButton
              style={{marginLeft: 10}}
              title="Wat zit er allemaal in de onderhoudsbeurt?"
              info="Buiten de vaste onderdelen wordt er altijd een diagnose gedaan. Eventueel meerwerk sturen we later als voorstel."
            />
          )}
        </View>
        {rows.map((row, index) => (
          <View key={index} style={styles.row}>
            <View style={{flex: 1}}>
              <Text
                style={[
                  {
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    marginRight: 4,
                  },
                ]}>
                {row.description}
              </Text>
              {(row.part_description || row.no) && (
                <Text style={globalStyles.small}>
                  {row.part_description || row.no}
                </Text>
              )}
            </View>
            <Text>{row.amount !== null ? Format.price(row.amount) : ''}</Text>
          </View>
        ))}
        <View style={[styles.row, {marginBottom: -1}]}>
          <View>
            <Text style={globalStyles.medium}>Totaal</Text>
            <Text style={globalStyles.small}>Inclusief BTW</Text>
          </View>
          <Text style={globalStyles.medium}>{Format.price(task_total)}</Text>
        </View>
        {task.is_required && (
          <>
            <View
              style={[
                styles.row,
                {borderTopWidth: 1, borderTopColor: '#dcdcdc'},
              ]}>
              <Text style={globalStyles.mediumPlus}>Reden werkvoorstel</Text>
            </View>
            <View style={[styles.row, {justifyContent: 'flex-start'}]}>
              <WarningIcon style={{marginRight: 32}} fill="#992929" />
              <Text>Voldoet niet aan APK eis.</Text>
            </View>
          </>
        )}
      </ScrollView>
      {task.diagnosis && (
        <View
          style={{
            backgroundColor: '#f2f2f2',
            flexDirection: 'row',
            paddingHorizontal: 16,
            paddingTop: 16,
            paddingBottom: 13,
            maxHeight: 88,
            overflow: 'hidden',
          }}>
          <Persona
            imageInitials={`${task.diagnosis_user_first_name[0]}${task.diagnosis_user_last_name[0]}`}
            size={PersonaSize.size40}
          />
          <View
            style={{
              flex: 1,
              paddingLeft: 16,
              overflow: 'hidden',
            }}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingBottom: 4,
              }}>
              <Text style={[globalStyles.mediumBold, {lineHeight: 16}]}>
                {task.diagnosis_user_first_name}
              </Text>
              <Text style={[globalStyles.smallRegular, {lineHeight: 14}]}>
                {duration}
              </Text>
            </View>
            <HtmlView clampLines={2} value={task.diagnosis} />
          </View>
        </View>
      )}
    </>
  );
};

export default Task;
