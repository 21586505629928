import endpoints from './endpoints';
import {fetch} from '../../lib/fetch';

export default {
  fetch: async (license) => {
    const result = await fetch(`${endpoints.car}/${license}`);

    if (!result || result.status !== 200) {
      return null;
    }

    const json = await result.json();
    return json;
  },

  state: async (license, mileage = null) => {
    const result = await fetch(`${endpoints.car}/state/${license}`, {
      method: 'POST',
      body: JSON.stringify({
        mileage,
      }),
    });

    if (!result || result.status !== 200) {
      return null;
    }

    const json = await result.json();
    return json;
  },
};
