import React from 'react';
import {useNavigation} from '@react-navigation/native';

import {Pressable} from '../../controls';
import Message from '../Message';
import withHover from '../../hoc/with-hover';

const DealerMessage = (props) => {
  const navigation = useNavigation();
  const {id, ...rest} = props;

  return (
    <Pressable onPress={() => navigation.navigate('Chat', {order_id: id})}>
      <Message {...rest} />
    </Pressable>
  );
};

export default withHover(DealerMessage, 'dealer-message');
