import React, {useState} from 'react';
import {SafeAreaView, View} from 'react-native';
import Modal from 'modal-react-native-web';

import styles from './styles';
import globalStyles from '../../../styles';
import Pressable from '../Pressable';
import DefaultButton from '../DefaultButton';
import Text from '../Text';
import InfoIcon from '../../../images/md-icons/info/materialicons/24px.svg';

const InfoButton = ({title, info, style}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Pressable
        style={[styles.root, style]}
        onPress={() => setShowModal(true)}>
        <InfoIcon />
      </Pressable>
      <Modal
        visible={showModal}
        transparent={true}
        onDismiss={() => setShowModal(false)}
        animationType="fade"
        ariaHideApp={false}>
        <SafeAreaView style={styles.container}>
          <Pressable
            style={{
              position: 'fixed',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
            onPress={() => setShowModal(false)}
          />
          <div className="action-sheet">
            <View style={styles.content}>
              <Text style={[globalStyles.xLarge, styles.title]}>{title}</Text>
              <Text
                style={[globalStyles.regular, {fontSize: 18, lineHeight: 27}]}>
                {info}
              </Text>
              <DefaultButton
                style={styles.button}
                onPress={() => setShowModal(false)}>
                OK
              </DefaultButton>
            </View>
          </div>
        </SafeAreaView>
      </Modal>
    </>
  );
};

export default InfoButton;
