import React from 'react';
import {Text} from 'react-native';

import globalStyles from '../../../styles';

export default (props) => {
  const {style, children, ...rest} = props;
  return (
    <Text style={[globalStyles.regular, style]} {...rest}>
      {children}
    </Text>
  );
};
