import React, {useState} from 'react';
import {View, Pressable, Platform} from 'react-native';

import styles from './styles';
import globalStyles from '../../../styles';
import IdealLogo from '../../../images/ideal.svg';
import LogoPoweredBy from '../../../images/logo-powered-by.svg';
import ArrowUpwardIcon from '../../../images/md-icons/arrow_upward/materialicons/24px.svg';
import ExpandMoreIcon from '../../../images/md-icons/expand_more/materialicons/24px.svg';
import ExpandLessIcon from '../../../images/md-icons/expand_less/materialicons/24px.svg';
import {useSelector} from '../../../lib/hooks';
import {DownloadLink, Text} from '../../controls';

const Footer = ({style, onScrollToTop}) => {
  const [expandContact, setExpandContact] = useState(false);
  const [expandDealer, setExpandDealer] = useState(false);

  const dealer = useSelector((state) => state.dealer);

  return (
    <>
      {dealer && (
        <>
          <View style={[styles.root, style]}>
            <View style={{flex: 1}}>
              <View style={[styles.row, styles.flexEnd]}>
                <Pressable
                  onPress={onScrollToTop}
                  style={[
                    {
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingTop: 20,
                    },
                    Platform.select({web: {cursor: 'pointer'}}),
                  ]}>
                  <Text style={[styles.link, globalStyles.smallMedium]}>
                    Terug naar boven
                  </Text>
                  <Text>&nbsp;</Text>
                  <ArrowUpwardIcon />
                </Pressable>
              </View>
              <Pressable
                style={[
                  styles.row,
                  styles.borderBottom,
                  styles.spaceBetween,
                  Platform.select({web: {cursor: 'pointer'}}),
                  {paddingTop: 40, paddingBottom: 20},
                ]}
                onPress={() => setExpandContact(!expandContact)}>
                <Text style={globalStyles.smallMedium}>Contact</Text>
                {expandContact ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Pressable>
              {expandContact && (
                <View style={{paddingVertical: 10}}>
                  <View style={[styles.row, styles.spaceBetween]}>
                    <Text style={globalStyles.smallRegular}>
                      Telefoonnummer
                    </Text>
                    <Text style={globalStyles.smallRegular}>
                      {dealer?.phone_number}
                    </Text>
                  </View>
                  <View style={[styles.row, styles.spaceBetween]}>
                    <Text style={globalStyles.smallRegular}>Email</Text>
                    <Text style={globalStyles.smallRegular}>
                      {dealer?.email}
                    </Text>
                  </View>
                </View>
              )}
              <Pressable
                style={[
                  styles.row,
                  styles.borderTop,
                  styles.borderBottom,
                  styles.spaceBetween,
                  Platform.select({web: {cursor: 'pointer'}}),
                  {paddingVertical: 20},
                ]}
                onPress={() => setExpandDealer(!expandDealer)}>
                <Text style={globalStyles.smallMedium}>{dealer?.name}</Text>
                {expandDealer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Pressable>
              {expandDealer && (
                <View style={{paddingVertical: 10}}>
                  <View
                    style={[
                      styles.row,
                      styles.spaceBetween,
                      {alignItems: 'flex-start'},
                    ]}>
                    <Text style={globalStyles.smallRegular}>
                      Vestigingsadres
                    </Text>
                    <View style={{alignItems: 'flex-end'}}>
                      <Text style={globalStyles.smallRegular}>
                        {dealer?.address}
                      </Text>
                      <Text style={globalStyles.smallRegular}>
                        {dealer?.city}
                      </Text>
                    </View>
                  </View>
                  {dealer?.chamber_of_commerce && (
                    <View
                      style={[
                        styles.row,
                        styles.spaceBetween,
                        {marginTop: 15},
                      ]}>
                      <Text style={globalStyles.smallRegular}>KVK</Text>
                      <Text style={globalStyles.smallRegular}>
                        {dealer?.chamber_of_commerce}
                      </Text>
                    </View>
                  )}
                  {dealer?.vat_number && (
                    <View style={[styles.row, styles.spaceBetween]}>
                      <Text style={globalStyles.smallRegular}>Btw</Text>
                      <Text style={globalStyles.smallRegular}>
                        {dealer?.vat_number}
                      </Text>
                    </View>
                  )}
                </View>
              )}
              <View
                style={[
                  styles.row,
                  styles.borderTop,
                  styles.spaceBetween,
                  {
                    alignItems: 'flex-start',
                    spaddingBottom: 40,
                  },
                ]}>
                {dealer?.urls?.terms_and_conditions && (
                  <>
                    <DownloadLink href={dealer?.urls.terms_and_conditions}>
                      <Text style={[globalStyles.small, {color: '#4a4a49'}]}>
                        Algemene voorwaarden
                      </Text>
                    </DownloadLink>
                  </>
                )}
                {dealer?.has_payment && <IdealLogo />}
              </View>
            </View>
            <View
              style={[
                styles.row,
                styles.spaceBetween,
                {
                  backgroundColor: '#4a4a49',
                  paddingVertical: 0,
                  paddingTop: 15,
                  paddingBottom: 10,
                },
              ]}>
              <LogoPoweredBy />
              {dealer?.urls && (
                <View
                  style={[
                    {
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      borderBottomWidth: 0,
                      alignItems: 'center',
                    },
                  ]}>
                  <DownloadLink href="https://bahnkick.com/privacy/#gebruik-van-cookies">
                    <Text style={[globalStyles.small, {color: '#ffffff'}]}>
                      Cookies
                    </Text>
                  </DownloadLink>
                  <Text
                    style={{
                      color: '#ffffff',
                    }}>
                    &nbsp;|&nbsp;
                  </Text>
                  <DownloadLink href="https://bahnkick.com/privacy">
                    <Text style={[globalStyles.small, {color: '#ffffff'}]}>
                      Privacybeleid
                    </Text>
                  </DownloadLink>
                </View>
              )}
            </View>
          </View>
        </>
      )}
    </>
  );
};

export default Footer;
