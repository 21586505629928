import {StyleSheet} from 'react-native';

export const StyleConstants = {
  dayView: {
    padding: 8,
  },
};

export default StyleSheet.create({
  days: {
    position: 'relative',
  },
  dayView: {
    position: 'absolute',
    aspectRatio: 1,
    padding: StyleConstants.dayView.padding,
  },
  dayText: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  grayText: {
    color: '#c4c4c4',
  },
  whiteText: {
    color: '#fff',
  },
  defaultText: {
    color: '#4a4a49',
  },
  past: {
    borderWidth: 0,
  },
  available: {
    borderRadius: 90,
    borderColor: '#299976',
    borderWidth: 1,
  },
  availableText: {
    color: '#299976',
  },
  warning: {
    borderRadius: 90,
    borderColor: '#FF6C00',
    borderWidth: 1,
  },
  warningText: {
    color: '#FF6C00',
  },
  alert: {
    borderRadius: 90,
    borderColor: '#972727',
    borderWidth: 1,
  },
  alertText: {
    color: '#972727',
  },
  selected: {
    borderRadius: 90,
    borderColor: '#231fda',
    borderWidth: 2,
    backgroundColor: '#231fda',
  },
  empty: {},
  month: {
    justifyContent: 'center',
    marginHorizontal: 16,
  },
});
