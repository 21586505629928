import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  button: {
    backgroundColor: '#4a4a49',
  },

  text: {
    fontFamily: 'Roboto_500Medium',
    fontSize: 16,
    color: '#ffffff',
    textTransform: 'uppercase',
  },

  disabled: {
    opacity: 0.25,
  },
});
