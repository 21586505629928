/* globals PRODUCTION, TEST */

import React from 'react';
import {SafeAreaView, View} from 'react-native';

import globalStyles from '../../../styles';

import styles from './styles';
import {Link, Text} from '../../../components/controls';
import PublicContentView from '../../../components/shared/PublicContentView';

const Error = ({navigation}) => {
  return (
    <SafeAreaView style={globalStyles.mainView}>
      {PRODUCTION && !TEST && (
        <>
          <View style={globalStyles.headerView}>
            <Text style={globalStyles.headerText}>Oeps!</Text>
          </View>
          <PublicContentView>
            <View style={globalStyles.contentView}>
              <Text
                style={{
                  paddingHorizontal: 20,
                  paddingVertical: 20,
                }}>
                Pagina is niet gevonden
              </Text>
            </View>
          </PublicContentView>
        </>
      )}
      {(!PRODUCTION || TEST) && (
        <>
          <View style={globalStyles.headerView}>
            <Text style={globalStyles.headerText}>Development</Text>
          </View>
          <View style={styles.article}>
            <View style={styles.articleHeader}>
              <Text style={globalStyles.mediumPlus}>Selecteer een dealer</Text>
            </View>
            <View style={styles.list}>
              <View style={styles.row}>
                <Text>MGH Amsterdam</Text>
                <Link
                  onPress={() =>
                    navigation.replace('Intro', {
                      dealer_id: 'db158529-483e-4638-b099-f8dc1442636f',
                    })
                  }>
                  Link
                </Link>
              </View>
              <View style={styles.row}>
                <Text>MGH Heemstede</Text>
                <Link
                  onPress={() =>
                    navigation.replace('Intro', {
                      dealer_id: '483f907a-8c5b-4128-b62d-b6ed4c0ffa83',
                    })
                  }>
                  Link
                </Link>
              </View>
              <View style={styles.row}>
                <Text>Bahnkick Demo</Text>
                <Link
                  onPress={() =>
                    navigation.replace('Intro', {
                      dealer_id: '1d7f477c-2a35-4ff2-84f5-a1e87208d37a',
                    })
                  }>
                  Link
                </Link>
              </View>
            </View>
          </View>
        </>
      )}
    </SafeAreaView>
  );
};

export default Error;
