import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {View, Pressable as RNPressable, Keyboard, Platform} from 'react-native';
import {useFocusEffect} from '@react-navigation/native';

import FocusManagerContext from '../../context/FocusManagerContext';
import KeyboardContext from '../../context/KeyboardContext';
import trace from '../../../lib/trace';

const Pressable = (props) => {
  let timer = useRef(null);
  let mounted = useRef(false);

  const {
    onPress,
    children,
    style,
    disabledStyle,
    allowBubbling,
    disabled,
    ...rest
  } = props;
  const [clickGuard, setClickGuard] = useState(false);
  const [focusChanged, setFocusChanged] = useState(false);
  const {activeElement, setFocus} = useContext(FocusManagerContext);
  const {keyboardShown} = useContext(KeyboardContext);

  const clearTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = null;
  }, [timer]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
      clearTimer();
    };
  }, [clearTimer]);

  useFocusEffect(
    useCallback(() => {
      setClickGuard(false);
      setFocusChanged(false);
    }, []),
  );

  const _onPress = useCallback(
    async (e) => {
      try {
        if (allowBubbling && e?.stopPropagation) {
          e.stopPropagation = () => {};
        }

        await onPress();
      } catch (e) {
        trace(e);
      } finally {
        if (mounted.current) {
          setClickGuard(false);
        }

        if (keyboardShown) {
          Keyboard.dismiss();
        }

        clearTimer();
      }
    },
    [allowBubbling, clearTimer, keyboardShown, onPress],
  );

  useEffect(() => {
    if (focusChanged && !activeElement) {
      trace('Focused changed on press');
      _onPress();
      setFocusChanged(false);
    }
  }, [activeElement, focusChanged, _onPress]);

  if (clickGuard) {
    return (
      <View {...rest} style={[{opacity: 0.5}, style]}>
        {children}
      </View>
    );
  }

  if (disabled) {
    return <View style={style}>{children}</View>;
  }

  return (
    <RNPressable
      {...rest}
      hitSlop={20}
      style={[style, Platform.OS === 'web' ? {cursor: 'pointer'} : null]}
      android_ripple={{color: '#dcdcdc', radius: 90}}
      onPress={(e) => {
        setClickGuard(true);
        if (activeElement) {
          trace('Active element blurred');
          activeElement.blur();
          setFocusChanged(true);
          timer.current = setTimeout(() => setFocus(null), 5000);
        } else {
          _onPress(e);
        }
      }}>
      {children}
    </RNPressable>
  );
};

export default Pressable;
