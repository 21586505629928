import React, {useState, useCallback, useEffect, useRef} from 'react';
import {Button} from 'react-native-paper';

const PrimaryButton = ({
  disabled,
  icon,
  onPress,
  disableClickGuard,
  children,
  clickGuardTimeout = 2500,
}) => {
  const isMounted = useRef(false);
  const [clickGuard, setClickGuard] = useState(false);

  const _onPress = useCallback(
    (e) => {
      if (!clickGuard) {
        onPress(e);

        if (disableClickGuard) {
          return;
        }

        setClickGuard(true);
        setTimeout(() => {
          if (isMounted.current) {
            setClickGuard(false);
          }
        }, clickGuardTimeout);
      }
    },
    [clickGuard, disableClickGuard, clickGuardTimeout, onPress],
  );

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Button
      icon={icon}
      disabled={disabled || clickGuard}
      mode="contained"
      onPress={_onPress}>
      {children}
    </Button>
  );
};

export default PrimaryButton;
