import endpoints from './endpoints';
import {fetch} from '../../lib/fetch';

const payment = {
  issuers: async () => {
    const response = await fetch(`${endpoints.payment}/issuers`);
    const result = await response.json();
    return result;
  },
  checkout: async (order_id, issuer, return_url) => {
    const response = await fetch(`${endpoints.order}/payment/${order_id}`, {
      method: 'POST',
      body: JSON.stringify({issuer, return_url}),
    });

    const result = await response.json();
    return result;
  },
  result: async (order_id) => {
    const response = await fetch(`${endpoints.order}/payment/${order_id}`);
    const result = await response.json();
    return result;
  },
  payLater: async (order_id) => {
    const response = await fetch(`${endpoints.order}/pay/later/${order_id}`, {
      method: 'POST',
      body: JSON.stringify({order_id}),
    });

    const result = await response.json();
    return result;
  },
};

export default payment;
