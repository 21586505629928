export default {
  footerView: {
    flexDirection: 'row',
    flexShrink: 0,
    flexGrow: 0,
    marginTop: -1,
    marginBottom: 16,
    paddingTop: 16,
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: 'flex-end',
  },
  footerText: {},
  footerButton: {
    flexBasis: 170,
    flexShrink: 1,
    flexGrow: 0,
    marginLeft: 8,
    minWidth: 80,
  },
};
