import {actionTypes} from '../actions';

export const initial_state = {
  lease: null,
  license: null,
  mileage: null,
  firstName: null,
  lastName: null,
  email: null,
  phone_number: null,
  date: null,
  comment: null,
  extra: null,
  waiting: false,
  wants_electric_bike: false,
  pick_up_location: null,
  services: null,
  apkSelected: null,
  categories: null,
  images: [],
  company_name: null,
  not_approved: null,
  storedPackage: null,
  package_is_chosen: null,
  car_state: null,
  ref: null,
};

export const reducer = (sessionState, action) => {
  const {type, value} = action;
  switch (type) {
    case actionTypes.INIT: {
      return {
        ...sessionState,
        ...value,
      };
    }

    case actionTypes.SET_DEALER: {
      return {
        ...sessionState,
        dealer: value,
      };
    }

    case actionTypes.ADD_JOB_SET_IS_LEASE: {
      return {
        ...sessionState,
        is_lease: value,
      };
    }

    case actionTypes.ADD_JOB_SET_LEASE: {
      return {
        ...sessionState,
        lease: value,
        package: null,
      };
    }

    case actionTypes.ADD_JOB_SET_SHOW_ALL_SERVICES: {
      return {
        ...sessionState,
        showAllServices: value,
      };
    }

    case actionTypes.ADD_JOB_SET_CAR_REPAIR_TYPE_ID: {
      return {
        ...sessionState,
        car_repair_type_id: value,
      };
    }

    case actionTypes.ADD_JOB_SET_LICENSE: {
      return {
        ...sessionState,
        license: value,
        package: null,
      };
    }

    case actionTypes.ADD_JOB_SET_MILEAGE: {
      return {
        ...sessionState,
        mileage: value,
      };
    }

    case actionTypes.ADD_JOB_SET_FIRST_NAME: {
      return {
        ...sessionState,
        firstName: value,
      };
    }

    case actionTypes.ADD_JOB_SET_LAST_NAME: {
      return {
        ...sessionState,
        lastName: value,
      };
    }

    case actionTypes.ADD_JOB_SET_EMAIL: {
      return {
        ...sessionState,
        email: value,
      };
    }

    case actionTypes.ADD_JOB_SET_TYPE: {
      return {
        ...sessionState,
        type: value,
      };
    }

    case actionTypes.ADD_JOB_SET_PHONE: {
      return {
        ...sessionState,
        phone_number: value,
      };
    }

    case actionTypes.ADD_JOB_SET_CATEGORIES: {
      return {
        ...sessionState,
        categories: value,
      };
    }

    case actionTypes.ADD_JOB_SET_SERVICES: {
      return {
        ...sessionState,
        services: value,
      };
    }

    case actionTypes.ADD_JOB_SET_EXTRA: {
      return {
        ...sessionState,
        waiting: false,
        wants_electric_bike: false,
        pick_up_location: null,
        extra: value,
      };
    }

    case actionTypes.ADD_JOB_SET_APK: {
      return {
        ...sessionState,
        apkSelected: value,
      };
    }

    case actionTypes.ADD_JOB_SET_DATE: {
      return {
        ...sessionState,
        date: value,
      };
    }

    case actionTypes.ADD_JOB_SET_COMMENT: {
      return {
        ...sessionState,
        comment: value,
      };
    }

    case actionTypes.ADD_JOB_SET_IMAGES: {
      return {
        ...sessionState,
        images: value,
      };
    }

    case actionTypes.ADD_JOB_SET_WAITING: {
      return {
        ...sessionState,
        waiting: value,
      };
    }

    case actionTypes.ADD_JOB_SET_WANTS_ELECTRIC_BIKE: {
      return {
        ...sessionState,
        wants_electric_bike: value,
      };
    }

    case actionTypes.ADD_JOB_SET_PICK_UP_LOCATION: {
      return {
        ...sessionState,
        pick_up_location: value,
      };
    }

    case actionTypes.ADD_JOB_SET_COMPANY_CAR: {
      return {
        ...sessionState,
        is_company_car: value,
        company_name: null,
      };
    }

    case actionTypes.ADD_JOB_SET_COMPANY_NAME: {
      return {
        ...sessionState,
        company_name: value,
      };
    }

    case actionTypes.ADD_JOB_SET_PACKAGE: {
      return {
        ...sessionState,
        package: value,
      };
    }

    case actionTypes.ADD_JOB_SET_PACKAGE_IS_CHOSEN: {
      return {
        ...sessionState,
        package_is_chosen: value,
      };
    }

    case actionTypes.ADD_JOB_SET_CAR_STATE: {
      return {
        ...sessionState,
        car_state: value,
      };
    }

    case actionTypes.ADD_JOB_RESET: {
      const {hard, init} = value || {};

      let hardReset = {};
      if (hard) {
        hardReset = {
          is_lease: null,
          is_company_car: null,
        };
      }

      return {
        ...sessionState,
        ...initial_state,
        ...hardReset,
        ...init,
      };
    }

    case actionTypes.APPROVE_SET_NOT_APPROVED: {
      return {
        ...sessionState,
        not_approved: value,
      };
    }

    default: {
      return sessionState;
    }
  }
};
