import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  inputViewStyle: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  input: {
    flex: 1,
    fontFamily: 'Roboto_400Regular',
    fontSize: 16,
    lineHeight: 24,
    color: '#4a4a49',
    textAlignVertical: 'bottom',
    paddingLeft: 32,
    paddingRight: 16,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 16,
  },
  uploads: {
    position: 'absolute',
    bottom: 32,
    left: -16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  imageView: {
    flex: 1,
    backgroundColor: '#000',
    zIndex: 0,
  },
  closeButtonView: {
    height: 60,
    paddingLeft: 16,
    justifyContent: 'center',
  },
  closeButton: {
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },
  deleteButtonView: {
    position: 'absolute',
    bottom: 16,
    right: 16,
    width: '100%',
    alignItems: 'flex-end',
  },
  deleteButton: {
    backgroundColor: 'red',
    width: 'auto',
    paddingHorizontal: 32,
  },
  deleteButtonText: {
    color: '#fff',
  },
});
