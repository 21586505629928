import endpoints from './endpoints';
import {fetch} from '../../lib/fetch';
import config from '../../configuration';

const functionHostKey = config.publicFunctionHostKey;

const messages = {
  list: async (id) => {
    const result = await fetch(`${endpoints.order}/${id}/messages`, {
      headers: {
        'x-functions-key': functionHostKey,
      },
    });

    const json = await result.json();
    return json;
  },
  sendMessage: async (id, message, images) => {
    const result = await fetch(`${endpoints.order}/${id}/message`, {
      headers: {
        'x-functions-key': functionHostKey,
      },
      method: 'POST',
      body: JSON.stringify({message, images}),
    });

    const json = await result.json();
    return json;
  },
};

export default messages;
