import React, {useLayoutEffect, useState, useEffect} from 'react';
import {SafeAreaView, View, ScrollView} from 'react-native';
import moment from 'moment';
import _ from 'lodash';

import globalStyles from '../../../styles';
import styles from './styles';
import {
  Text,
  PrimaryButton,
  Pressable,
  LinearProgress,
  CloseButton,
} from '../../../components/controls';
import {useDispatch, useSelector} from '../../../lib/hooks';
import {addJobSetDate} from '../../../actions';
import PublicContentView from '../../../components/shared/PublicContentView';
import ShoppingCartIcon from '../../../images/md-icons/shopping_cart/materialicons/24px.svg';
import {dealer as dealerApi, booking as bookingApi} from '../../../api/public';

const Slot = ({time, checked, onPress}) => (
  <Pressable onPress={() => onPress(time)}>
    <Text style={[styles.slot, styles.available, checked && styles.checked]}>
      {time.format('H:mm')}
    </Text>
  </Pressable>
);

const Time = ({navigation, onDismiss}) => {
  const dispatch = useDispatch();
  const dealer = useSelector((state) => state.dealer);
  const date = useSelector((state) => state.date);
  const services = useSelector((state) => state.services);
  const [loading, setLoading] = useState(true);
  const [availability, setAvailability] = useState({});
  const [skipExtras, setSkipExtras] = useState(false);

  useLayoutEffect(() => {
    navigation?.setOptions({
      headerTitle: () => (
        <View style={{alignItems: 'center'}}>
          <LinearProgress style={{width: 126}} step={5} total={7} />
        </View>
      ),
      headerRight: () => <View />,
    });
  }, [navigation]);

  useEffect(() => {
    const fetch = async () => {
      const availability = await dealerApi.timeslots(dealer.id, services, date);
      setAvailability(availability);

      const extras = await bookingApi.extras(dealer.id);
      if (Object.values(extras).flat().length <= 1) {
        setSkipExtras(true);
      }

      setLoading(false);
    };

    fetch();
  }, [dealer, date, services]);

  const onSlotChange = (time) => {
    const change = moment(date).utc();
    change.hours(time.hours());
    change.minutes(time.minutes());
    dispatch(addJobSetDate(change));
  };

  const onNext = async () => {
    if (onDismiss) {
      onDismiss();
    } else {
      navigation.navigate(skipExtras ? 'Confirm' : 'Extras');
    }
  };

  let isValid = false;

  let frames = [];
  if (!loading) {
    if (availability[moment(date).format('YYYY-MM-DD')]?.slots) {
      const {slots} = availability[moment(date).format('YYYY-MM-DD')];

      if (slots.length) {
        frames = slots.reduce((acc, time) => {
          if (!acc.length || acc[acc.length - 1].length === 4) {
            acc.push([]);
          }

          const checked = moment(date).utc().format('HH:mm') === time;
          const slot = {
            time: moment(time, 'HH:mm'),
            checked,
          };

          acc[acc.length - 1].push(slot);
          return acc;
        }, []);

        if (frames.length) {
          while (frames[frames.length - 1].length < 4) {
            frames[frames.length - 1].push(null);
          }
        }

        isValid =
          date !== null &&
          _.flatten(frames).filter((item) => item?.checked).length;
      }
    } else if (dealer?.begin_checkin && dealer?.end_checkin) {
      let time = moment(dealer.begin_checkin, 'HH:mm:ss');
      const end = moment(dealer.end_checkin, 'HH:mm:ss');

      let count = 0;
      while (time.isSameOrBefore(end, 'minutes')) {
        const index = Math.floor(count / 4);
        if (!frames[index]) {
          frames[index] = [null, null, null, null];
        }

        const checked =
          moment(date).utc().format('HH:mm') === time.format('HH:mm');
        const slot = {
          time: time.clone(),
          checked,
        };

        frames[index][count % 4] = slot;

        time = time.add(30, 'minutes');
        count++;
      }

      isValid =
        date !== null &&
        moment(date).hours() >=
          moment(dealer.begin_checkin, 'HH:mm:ss').hours() &&
        moment(date).hours() <= moment(dealer.end_checkin, 'HH:mm:ss').hours();
    }
  }

  const content = (
    <>
      <View style={[globalStyles.headerView, styles.headerView]}>
        {!onDismiss ? (
          <View
            style={{
              paddingHorizontal: 16,
              paddingBottom: 24,
            }}>
            <Text style={globalStyles.headerText}>Tijd afspraak</Text>
            <Text style={{marginTop: 10, fontSize: 18}}>
              Wat is een goede tijd voor de overdracht van je auto?
            </Text>
          </View>
        ) : (
          <View style={[globalStyles.modalHeaderView, {borderBottomWidth: 0}]}>
            <CloseButton
              style={{position: 'absolute', top: 8, left: 8}}
              onPress={onDismiss}
            />
            <Text style={globalStyles.medium}>Tijd afspraak</Text>
          </View>
        )}
      </View>
      <View style={[globalStyles.contentView, {paddingTop: 24}]}>
        {!loading &&
          frames.map((slots, index) => (
            <View key={index} style={styles.frame}>
              {slots.map((slot, index) => (
                <View key={index}>
                  {slot ? (
                    <Slot {...slot} onPress={onSlotChange} />
                  ) : (
                    <View style={styles.slot} />
                  )}
                </View>
              ))}
            </View>
          ))}
      </View>
    </>
  );

  const footer = (
    <View style={globalStyles.footerView}>
      {!loading && (
        <PrimaryButton
          disabled={!isValid}
          onPress={onNext}
          icon={
            !onDismiss &&
            skipExtras &&
            (() => <ShoppingCartIcon fill="#ffffff" width={18} height={18} />)
          }>
          {onDismiss ? 'Opslaan' : skipExtras ? 'Controleren' : 'Volgende'}
        </PrimaryButton>
      )}
    </View>
  );

  return (
    <SafeAreaView style={globalStyles.mainView}>
      {onDismiss ? (
        <>
          <ScrollView>{content}</ScrollView>
          <View style={{borderTopWidth: 1, borderTopColor: '#dcdcdc'}}>
            {footer}
          </View>
        </>
      ) : (
        <PublicContentView>
          {{
            content,
            footer,
          }}
        </PublicContentView>
      )}
    </SafeAreaView>
  );
};

export default Time;
