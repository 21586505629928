import {StyleSheet} from 'react-native';

const styles = {
  root: {
    backgroundColor: '#f2f2f2',
    minHeight: 588,
  },
  link: {
    color: '#4a4a49',
    textDecorationLine: 'underline',
  },
  row: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingVertical: 6,
  },
  borderTop: {
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
    marginBottom: -1,
  },
  spaceBetween: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexEnd: {
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};

export default StyleSheet.create(styles);
