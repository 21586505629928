import React, {useEffect, useState} from 'react';
import {View} from 'react-native';

import Text from '../Text';
import Format from '../../../lib/format';
import {address as addressApi} from '../../../api/public';

export default (props) => {
  const {split = true} = props;
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      if (!props.address) {
        return;
      }

      const {postalCode, houseNumber, annex} = props.address;
      const {address} = await addressApi.fetch(postalCode, houseNumber, annex);

      let display = address;
      const match = address?.match(/(.*),([^,]*)$/);
      if (match?.length === 3) {
        display = [match[1], match[2]];
      }

      setAddress(display);
      setLoading(false);
    };

    fetch();
  }, [props]);

  return loading ? (
    <Text style={props.style}>&nbsp;</Text>
  ) : (
    <>
      {split && address?.length === 2 ? (
        <View>
          <Text style={props.style}>{address[0].trim()}</Text>
          <Text style={props.style}>{address[1].trim()}</Text>
        </View>
      ) : (
        <Text style={props.style}>
          {address || Format.address(props.address)}
        </Text>
      )}
    </>
  );
};
