const OrderStatus = {
  Draft: 'DRAFT',
  Created: 'CREATED',
  Pending: 'PENDING',
  Approved: 'APPROVED',
  Confirmed: 'CONFIRMED',
  Rejected: 'REJECTED',
  Ready: 'READY',
  Diagnosis: 'DIAGNOSIS',
  BusyDiagnosis: 'BUSY_DIAGNOSIS',
  ApprovalPending: 'APPROVAL_PENDING',
  ApprovalRequested: 'APPROVAL_REQUESTED',
  ApprovalFinished: 'APPROVAL_FINISHED',
  Busy: 'BUSY',
  Done: 'DONE',
  InvoiceCreated: 'INVOICE_CREATED',
  Finished: 'FINISHED',
  PaymentReceived: 'PAYMENT_RECEIVED',
  EmailReminderSent: 'EMAIL_REMINDER_SENT',
  ReminderSent: 'REMINDER_SENT',
  DateChanged: 'DATE_CHANGED',
  TimeChanged: 'TIME_CHANGED',

  text: (status) => {
    switch (status) {
      case OrderStatus.Draft:
        return 'Concept';
      case OrderStatus.Created:
        return 'Aanvraag';
      case OrderStatus.Pending:
        return 'Offerte';
      case OrderStatus.Confirmed:
      case OrderStatus.Active:
      case OrderStatus.Rejected:
        return 'Opdracht';
      case OrderStatus.Diagnosis:
        return 'Check-in';
      case OrderStatus.ApprovalPending:
      case OrderStatus.ApprovalRequested:
        return 'Voorstel';
      case OrderStatus.ApprovalFinished:
        return 'Meerwerk';
      case OrderStatus.Done:
      case OrderStatus.Finished:
        return 'Gereed';
      case OrderStatus.ReminderSent:
        return 'SMS';
      case OrderStatus.EmailReminderSent:
        return 'E-mail';
      case OrderStatus.PaymentReceived:
        return 'Betaling';
      case OrderStatus.DateChanged:
        return 'Datum';
      case OrderStatus.TimeChanged:
        return 'Tijd';
      default:
        break;
    }

    return status;
  },
};

export default OrderStatus;
