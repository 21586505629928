export const actionTypes = {
  INIT: 'INIT',
  SET_DEALER: 'SET_DEALER',
  ADD_JOB_SET_IS_LEASE: 'ADD_JOB_SET_IS_LEASE',
  ADD_JOB_SET_LEASE: 'ADD_JOB_SET_LEASE',
  ADD_JOB_SET_CAR_REPAIR_TYPE_ID: 'ADD_JOB_SET_CAR_REPAIR_TYPE_ID',
  ADD_JOB_SET_LICENSE: 'ADD_JOB_SET_LICENSE',
  ADD_JOB_SET_MILEAGE: 'ADD_JOB_SET_MILEAGE',
  ADD_JOB_SET_SHOW_ALL_SERVICES: 'ADD_JOB_SET_SHOW_ALL_SERVICES',
  ADD_JOB_SET_FIRST_NAME: 'ADD_JOB_SET_FIRST_NAME',
  ADD_JOB_SET_LAST_NAME: 'ADD_JOB_SET_LAST_NAME',
  ADD_JOB_SET_EMAIL: 'ADD_JOB_SET_EMAIL',
  ADD_JOB_SET_PHONE: 'ADD_JOB_SET_PHONE',
  ADD_JOB_SET_CATEGORIES: 'ADD_JOB_SET_CATEGORIES',
  ADD_JOB_SET_SERVICES: 'ADD_JOB_SET_SERVICES',
  ADD_JOB_SET_APK: 'ADD_JOB_SET_APK',
  ADD_JOB_SET_EXTRA: 'ADD_JOB_SET_EXTRA',
  ADD_JOB_SET_DATE: 'ADD_JOB_SET_DATE',
  ADD_JOB_SET_COMMENT: 'ADD_JOB_SET_COMMENT',
  ADD_JOB_SET_IMAGES: 'ADD_JOB_SET_IMAGES',
  ADD_JOB_SET_WAITING: 'ADD_JOB_SET_WAITING',
  ADD_JOB_RESET: 'ADD_JOB_RESET',
  ADD_JOB_SET_WANTS_ELECTRIC_BIKE: 'ADD_JOB_SET_WANTS_ELECTRIC_BIKE',
  ADD_JOB_SET_PICK_UP_LOCATION: 'ADD_JOB_SET_PICK_UP_LOCATION',
  ADD_JOB_SET_COMPANY_CAR: 'ADD_JOB_SET_COMPANY_CAR',
  ADD_JOB_SET_COMPANY_NAME: 'ADD_JOB_SET_COMPANY_NAME',
  ADD_JOB_SET_PACKAGE: 'ADD_JOB_SET_PACKAGE',
  ADD_JOB_SET_PACKAGE_IS_CHOSEN: 'ADD_JOB_SET_PACKAGE_IS_CHOSEN',
  ADD_JOB_SET_CAR_STATE: 'ADD_JOB_SET_CAR_STATE',
  APPROVE_SET_NOT_APPROVED: 'APPROVE_SET_NOT_APPROVED',
};

export const setDealer = (value) => {
  return {type: actionTypes.SET_DEALER, value};
};

export const addJobIsLease = (value) => {
  return {type: actionTypes.ADD_JOB_SET_IS_LEASE, value};
};

export const addJobSetLease = (value) => {
  return {type: actionTypes.ADD_JOB_SET_LEASE, value};
};

export const addJobSetCarRepairTypeId = (value) => {
  return {type: actionTypes.ADD_JOB_SET_CAR_REPAIR_TYPE_ID, value};
};

export const addJobSetLicense = (value) => {
  return {type: actionTypes.ADD_JOB_SET_LICENSE, value};
};

export const addJobSetMileage = (value) => {
  return {type: actionTypes.ADD_JOB_SET_MILEAGE, value};
};

export const addJobSetShowAllServices = (value) => {
  return {type: actionTypes.ADD_JOB_SET_SHOW_ALL_SERVICES, value};
};

export const addJobSetCompanyCar = (value) => {
  return {type: actionTypes.ADD_JOB_SET_COMPANY_CAR, value};
};

export const addJobSetCompanyName = (value) => {
  return {type: actionTypes.ADD_JOB_SET_COMPANY_NAME, value};
};

export const addJobSetFirstName = (value) => {
  return {type: actionTypes.ADD_JOB_SET_FIRST_NAME, value};
};

export const addJobSetLastName = (value) => {
  return {type: actionTypes.ADD_JOB_SET_LAST_NAME, value};
};

export const addJobSetEmail = (value) => {
  return {type: actionTypes.ADD_JOB_SET_EMAIL, value};
};

export const addJobSetPhone = (value) => {
  return {type: actionTypes.ADD_JOB_SET_PHONE, value};
};

export const addJobSetCategories = (value) => {
  return {type: actionTypes.ADD_JOB_SET_CATEGORIES, value};
};

export const addJobSetServices = (value) => {
  return {type: actionTypes.ADD_JOB_SET_SERVICES, value};
};

export const addJobSetPackage = (value) => {
  return {type: actionTypes.ADD_JOB_SET_PACKAGE, value};
};

export const addJobSetAPK = (value) => {
  return {type: actionTypes.ADD_JOB_SET_APK, value};
};

export const addJobSetExtra = (value) => {
  return {type: actionTypes.ADD_JOB_SET_EXTRA, value};
};

export const addJobSetDate = (value) => {
  return {type: actionTypes.ADD_JOB_SET_DATE, value};
};

export const addJobSetComment = (value) => {
  return {type: actionTypes.ADD_JOB_SET_COMMENT, value};
};

export const addJobSetImages = (value) => {
  return {type: actionTypes.ADD_JOB_SET_IMAGES, value};
};

export const addJobSetWaiting = (value) => {
  return {type: actionTypes.ADD_JOB_SET_WAITING, value};
};

export const addJobSetWantsElectricBike = (value) => {
  return {type: actionTypes.ADD_JOB_SET_WANTS_ELECTRIC_BIKE, value};
};

export const addJobSetPickupLocation = (value) => {
  return {type: actionTypes.ADD_JOB_SET_PICK_UP_LOCATION, value};
};

export const addJobSetPackageIsChosen = (value) => {
  return {type: actionTypes.ADD_JOB_SET_PACKAGE_IS_CHOSEN, value};
};

export const addJobSetCarState = (value) => {
  return {type: actionTypes.ADD_JOB_SET_CAR_STATE, value};
};

export const addJobReset = (options) => {
  return {type: actionTypes.ADD_JOB_RESET, value: options};
};

export const approveSetNotApproved = (value) => {
  return {type: actionTypes.APPROVE_SET_NOT_APPROVED, value};
};
