const config = {
  production: true,
  development: false,
  publicApiRoot: 'https://bahnkick-api.azurewebsites.net/api',
  hubName: 'bahnkick-dms-notifications',
  hubEndpoint:
    'Endpoint=sb://bahnkick.servicebus.windows.net/;SharedAccessKeyName=DefaultListenSharedAccessSignature;SharedAccessKey=s1rly2rsk4SR3uHM3qnq09PilJaDP/fpJhtD6PlJed8=',
};

export default config;
