import React, {useLayoutEffect, useEffect, useState} from 'react';
import {SafeAreaView, View, ActivityIndicator} from 'react-native';
import moment from 'moment';
import _ from 'lodash';

import styles from './styles';
import globalStyles from '../../../styles';
import {request as requestApi, order as orderApi} from '../../../api/public';
import {
  Checkbox,
  CloseButton,
  FormSheetModal,
  Pressable,
  PrimaryButton,
  Dialog,
  Text,
  InfoButton,
  LinearProgress,
} from '../../../components/controls';
import {PublicContentView, Task, Extra} from '../../../components/shared';
import Format from '../../../lib/format';
import {useDispatch, useSelector} from '../../../lib/hooks';
import {approveSetNotApproved} from '../../../actions';
import {EXTRAS_CODES} from '../../../types/service-codes';
import ExpandMoreIcon from '../../../images/md-icons/expand_more/materialicons/24px.svg';
import ExpandLessIcon from '../../../images/md-icons/expand_less/materialicons/24px.svg';

const Detail = ({navigation, route}) => {
  const dispatch = useDispatch();
  const order_id = route.params?.order_id;

  const notApproved = useSelector((state) => state.not_approved);
  const [order, setOrder] = useState(null);
  const [request, setRequest] = useState({});
  const [approvals, setApprovals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [task, setTask] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [extra, setExtra] = useState(null);
  const [expand, setExpand] = useState(false);
  const [expandPastApprovals, setExpandPastApprovals] = useState(false);
  const [expandPastRejections, setExpandPastRejections] = useState(false);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerTitle: () => (
        <View style={{alignItems: 'center'}}>
          <LinearProgress style={{width: 126}} step={1} total={2} />
        </View>
      ),
      headerRight: () => <View />,
    });
  }, [navigation]);

  useEffect(() => {
    const fetch = async () => {
      const order = await orderApi.details(order_id);
      const request = await requestApi.fetch(order_id, {is_initial: true});

      const extras = request?.tasks?.filter((task) => task.is_extra === true);
      const approvals = request?.tasks?.filter(
        (task) =>
          task.id && task.is_approved === null && task.is_extra !== true,
      );

      order.request
        ?.filter((service) => service.type === 'EXTRA' && !service.is_approved)
        .forEach((service) => {
          const extra =
            extras.find(
              (item) =>
                item.dealer_service_code === service.dealer_service_code,
            ) || {};

          let description;
          switch (service.dealer_service_code) {
            case EXTRAS_CODES.EXTRA_NONE:
              description = 'Vervangend vervoer';
              break;
            default:
              description = service.title;
              break;
          }

          approvals.push({
            ...extra,
            ...service,
            description,
            is_extra: true,
          });
        });

      setOrder(order);
      setRequest(request);
      setApprovals(approvals);
      setLoading(false);
    };

    fetch();
  }, [navigation, order_id, dispatch]);

  const onApprove = async () => {
    navigation.navigate('Check', {order_id});
  };

  const onConfirmReject = async () => {
    dispatch(
      approveSetNotApproved(approvals.map((task) => task.id).filter(Boolean)),
    );

    setShowAlert(true);
  };

  const saveApproved = async () => {
    const approved = approvals
      .filter((task) => task.id)
      .reduce((acc, task) => {
        if (!acc[task.request_id]) {
          acc[task.request_id] = [];
        }

        if (!notApproved || !notApproved.includes(task.id)) {
          acc[task.request_id].push(task.id);
        }

        return acc;
      }, {});

    return await orderApi.confirm(order_id, approved);
  };

  const onReject = async () => {
    const result = await saveApproved();
    if (result.success) {
      navigation.popToTop();
      navigation.replace('Overview', {screen: 'Chat', params: {order_id}});
    }
  };

  const onShowTask = async (task_id) => {
    const task = await orderApi.fetchTask(task_id);
    setTask(task);
  };

  const onShowExtra = async (extra) => {
    let task = {};
    if (extra.id) {
      task = await orderApi.fetchTask(extra.id);
    }
    setExtra({
      ...extra,
      ...task,
    });
  };

  const onCheckedChanged = (checked, id) => {
    let newState = notApproved || [];
    if (!checked) {
      newState.push(id);
    } else {
      newState = newState.filter((item) => item !== id);
    }

    dispatch(approveSetNotApproved(newState.slice(0)));
  };

  const approveCount = approvals?.filter(
    (task) => task.id && !notApproved?.includes(task.id),
  ).length;

  const pastApprovals = request?.tasks?.filter((task) => task.is_approved);
  const pastRejections = request?.tasks?.filter(
    (task) => task.is_approved === false,
  );
  const total = approvals
    ?.filter((task) => !notApproved?.includes(task.id))
    .reduce((acc, value) => acc + (value.amount || 0), 0);

  return (
    <SafeAreaView style={globalStyles.mainView}>
      {loading && <ActivityIndicator size="large" color="#231fda" />}
      {!loading && (
        <>
          <PublicContentView>
            {{
              content: (
                <>
                  <View style={globalStyles.headerView}>
                    <Text style={[globalStyles.headerText, styles.headerText]}>
                      Offerte beoordelen
                    </Text>
                    <Text style={{marginTop: 10, fontSize: 18}}>
                      Laat een vinkje staan als je een item wilt goedkeuren.
                      Druk op een item voor de details.
                    </Text>
                  </View>
                  <View style={globalStyles.contentView}>
                    <View style={styles.article}>
                      <Pressable
                        style={[styles.row, {alignItems: 'center'}]}
                        onPress={() => setExpand(!expand)}>
                        <Text style={globalStyles.mediumBold}>Afspraak</Text>
                        {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </Pressable>
                      {expand && (
                        <>
                          <View style={styles.row}>
                            <Text>{request?.order?.car_name}</Text>
                            <Text>
                              {Format.license(request?.order?.license)}
                            </Text>
                          </View>
                          <View style={styles.row}>
                            <Text>Datum</Text>
                            <Text>
                              {moment(request?.order?.datetime).format(
                                'dddd D MMMM YYYY',
                              )}
                            </Text>
                          </View>
                          <View style={styles.row}>
                            <Text>Tijd</Text>
                            <Text>
                              {moment(order?.datetime).format('H:mm')}
                            </Text>
                          </View>
                        </>
                      )}
                    </View>
                    <View style={styles.article}>
                      <View style={[styles.row, {alignItems: 'center'}]}>
                        <View
                          style={{flexDirection: 'row', alignItems: 'center'}}>
                          <Text style={globalStyles.mediumBold}>Offerte</Text>
                          <InfoButton
                            style={{marginLeft: 10}}
                            title="Hoe werkt de offerte en wat is er te zien?"
                            info="Laat een vinkje staan bij de items die je wilt goedkeuren. Details zijn beschikbaar voor items met een link."
                          />
                        </View>
                      </View>
                      <View style={styles.tasks}>
                        {approvals?.map((task) => {
                          const has_details =
                            task.parts?.length ||
                            task.markup_fee !== null ||
                            task.environment_surcharge !== null ||
                            task.is_required ||
                            task.has_diagnosis ||
                            task.is_extra;

                          return (
                            <View key={task.id} style={styles.task}>
                              {
                                <Checkbox
                                  style={{
                                    pointerEvents: !task.id ? 'none' : 'all',
                                  }}
                                  disabled={!task.id}
                                  checked={
                                    task.id
                                      ? !notApproved?.includes(task.id)
                                      : approveCount > 0
                                  }
                                  onPress={(checked) =>
                                    onCheckedChanged(checked, task.id)
                                  }
                                />
                              }
                              <Pressable
                                disabled={!has_details}
                                style={styles.taskDescription}
                                onPress={() => {
                                  task.is_extra
                                    ? onShowExtra(task)
                                    : onShowTask(task.id);
                                }}>
                                <View style={{flex: 1}}>
                                  <Text>{task.description}</Text>
                                  <Text
                                    style={[globalStyles.small, styles.link]}>
                                    {task.is_extra && (
                                      <Text style={globalStyles.small}>
                                        Details
                                      </Text>
                                    )}
                                    {task.parts?.length > 0 && (
                                      <Text style={globalStyles.small}>
                                        {task.parts.length}&nbsp;onderdelen
                                      </Text>
                                    )}
                                    {task.is_required && (
                                      <Text style={globalStyles.small}>
                                        {task.parts?.length > 0 ? ', ' : ''}APK
                                        eis
                                      </Text>
                                    )}
                                  </Text>
                                </View>
                                <Text>
                                  {!notApproved?.includes(task.id) &&
                                  _.isNumber(task.amount)
                                    ? Format.price(task.amount)
                                    : null}
                                </Text>
                              </Pressable>
                            </View>
                          );
                        })}
                        <View style={[styles.row, {borderBottomWidth: 0}]}>
                          <View>
                            <Text style={globalStyles.mediumSemi}>Totaal</Text>
                            <Text style={globalStyles.small}>
                              Inclusief BTW
                            </Text>
                          </View>
                          <Text style={globalStyles.mediumSemi}>
                            {Format.price(total)}
                          </Text>
                        </View>

                        {pastApprovals?.length > 0 && (
                          <View style={styles.article}>
                            <Pressable
                              style={styles.articleHeader}
                              onPress={() =>
                                setExpandPastApprovals(!expandPastApprovals)
                              }>
                              <Text style={globalStyles.mediumPlus}>
                                Eerder goedgekeurd ({pastApprovals.length})
                              </Text>
                              {expandPastApprovals ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </Pressable>
                            {expandPastApprovals &&
                              pastApprovals.map((task) => (
                                <View key={task.id} style={styles.row}>
                                  <Text>{task.description}</Text>
                                  <Text>{Format.price(task.amount)}</Text>
                                </View>
                              ))}
                          </View>
                        )}
                        {pastRejections?.length > 0 && (
                          <View style={styles.article}>
                            <Pressable
                              style={styles.articleHeader}
                              onPress={() =>
                                setExpandPastRejections(!expandPastRejections)
                              }>
                              <Text style={globalStyles.mediumPlus}>
                                Eerder afgewezen ({pastRejections.length})
                              </Text>
                              {expandPastRejections ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </Pressable>
                            {expandPastRejections &&
                              pastRejections.map((task) => (
                                <View key={task.id} style={styles.row}>
                                  <Text
                                    style={{
                                      textDecorationLine: 'line-through',
                                    }}>
                                    {task.description}
                                  </Text>
                                </View>
                              ))}
                          </View>
                        )}
                      </View>
                    </View>
                  </View>
                </>
              ),
              footer: (
                <>
                  {approvals?.length > 0 && (
                    <View style={globalStyles.footerView}>
                      {approveCount > 0 ? (
                        <PrimaryButton onPress={onApprove}>
                          Volgende
                        </PrimaryButton>
                      ) : (
                        <PrimaryButton onPress={onConfirmReject}>
                          Volgende
                        </PrimaryButton>
                      )}
                    </View>
                  )}
                </>
              ),
            }}
          </PublicContentView>

          {task && (
            <FormSheetModal visible={true} onDismiss={() => setTask(null)}>
              <SafeAreaView style={globalStyles.mainView}>
                <View style={[globalStyles.headerView, styles.modalHeaderView]}>
                  <CloseButton
                    style={styles.closeButton}
                    onPress={() => setTask(null)}
                  />
                  <Text>Details</Text>
                </View>
                <View
                  style={[globalStyles.contentView, styles.modalContentView]}>
                  <Task task={task} />
                </View>
                <View style={globalStyles.footerView}>
                  <PrimaryButton onPress={() => setTask(null)}>
                    Sluiten
                  </PrimaryButton>
                </View>
              </SafeAreaView>
            </FormSheetModal>
          )}

          {extra && (
            <>
              <FormSheetModal visible={true} onDismiss={() => setExtra(null)}>
                <SafeAreaView style={globalStyles.mainView}>
                  <View
                    style={[globalStyles.headerView, styles.modalHeaderView]}>
                    <CloseButton
                      style={styles.closeButton}
                      onPress={() => setExtra(null)}
                    />
                    <Text>Details</Text>
                  </View>
                  <View
                    style={[globalStyles.contentView, styles.modalContentView]}>
                    <Extra extra={extra} order={order} />
                  </View>
                  <View style={globalStyles.footerView}>
                    <PrimaryButton onPress={() => setExtra(null)}>
                      Sluiten
                    </PrimaryButton>
                  </View>
                </SafeAreaView>
              </FormSheetModal>
            </>
          )}

          <Dialog
            title="Offerte afkeuren?"
            visible={showAlert}
            onDismiss={() => setShowAlert(false)}
            buttons={[
              {text: 'Annuleren', onPress: () => setShowAlert(false)},
              {text: 'OK', onPress: onReject},
            ]}>
            <Text>
              Hiermee keur je de offerte af en opent de chat waarin je een reden
              kunt opgeven.
            </Text>
          </Dialog>
        </>
      )}
    </SafeAreaView>
  );
};

export default Detail;
