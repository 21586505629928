import React, {useLayoutEffect, useEffect, useState, useCallback} from 'react';
import {
  SafeAreaView,
  View,
  ActivityIndicator,
  Platform,
  Image,
} from 'react-native';
import md5 from 'md5';

import styles from './styles';
import globalStyles from '../../../styles';
import {
  order as orderApi,
  payment as paymentApi,
  dealer as dealerApi,
} from '../../../api/public';
import {
  PrimaryButton,
  RadioButton,
  Text,
  Select,
  Pressable,
} from '../../../components/controls';
import Format from '../../../lib/format';
import IdealIcon from '../../../images/ideal.svg';
import PublicContentView from '../../../components/shared/PublicContentView';
import {useDispatch, useSelector} from '../../../lib/hooks';
import {setDealer} from '../../../actions';
import config from '../../../configuration';

const Pay = ({route, navigation, canPayLater = true}) => {
  const order_id = route.params?.order_id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const [due, setDue] = useState({});
  const [issuers, setIssuers] = useState([]);
  const [bank, setBank] = useState('');
  const [payLater, setPayLater] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const dealer = useSelector((state) => state.dealer);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerTitle: () => (
        <View style={{alignItems: 'center'}}>
          <Text>&nbsp;</Text>
        </View>
      ),
    });
  }, [navigation]);

  const navigateToChat = useCallback(() => {
    navigation.replace('Overview', {
      screen: 'Chat',
      params: {
        order_id,
      },
    });
  }, [navigation, order_id]);

  useEffect(() => {
    if (!order_id) {
      return;
    }

    const fetch = async () => {
      const hash = md5(order_id);

      const invoice = await orderApi.fetch(hash);
      const order = await orderApi.details(order_id);
      const dealer = await dealerApi.details(order.dealer_id);
      const issuers = await paymentApi.issuers();

      const due = invoice.reduce((acc, request) => acc + request.due, 0);

      if (dealer.has_payment === false || due === 0) {
        navigateToChat();
      }

      dispatch(setDealer(dealer));
      setOrder(order);
      setDue(due);
      setIssuers(issuers);
      setLoading(false);
    };

    fetch();
  }, [navigation, order_id, dispatch, navigateToChat]);

  const onNext = async () => {
    try {
      setSubmitted(true);

      if (payLater) {
        const result = await paymentApi.payLater(order_id);
        if (result.success) {
          navigateToChat();
        }
      } else {
        if (Platform.OS === 'web') {
          let return_url;

          if (config.production && dealer.urls?.payment_landing) {
            const {payment_landing} = dealer.urls;
            const return_path = `?payment_landing=true&order_id=${order_id}`;
            return_url = `${payment_landing}${return_path}`;
          }

          if (!return_url) {
            const return_path = window.location.pathname.replace(
              '/Pay',
              '/Complete',
            );
            return_url = `${window.location.origin}${return_path}`;
          }

          const hash = md5(order_id);
          const response = await paymentApi.checkout(
            hash,
            bank.value,
            return_url,
          );

          const {checkout} = response;
          if (checkout) {
            window.top.location.href = checkout;
          }
        } else {
          throw new Error('Not implemmented yet');
        }
      }
    } finally {
      setSubmitted(false);
    }
  };

  const isValid = bank || payLater || due === 0;

  return (
    <SafeAreaView style={globalStyles.mainView}>
      {loading && <ActivityIndicator size="large" color="#231fda" />}
      {!loading && (
        <>
          <PublicContentView>
            {{
              content: (
                <>
                  <View style={[globalStyles.headerView, styles.headerView]}>
                    <Text style={globalStyles.headerText}>Betaling</Text>
                    <Text style={{marginTop: 10, fontSize: 18}}>
                      {due > 0 && (
                        <Text>
                          Kies de bank waarmee je
                          {canPayLater
                            ? ' nu wilt betalen of betaal later.'
                            : ' wilt betalen.'}
                        </Text>
                      )}
                      {due === 0 && (
                        <Text>
                          Er zijn geen openstaande bedragen gevonden. Je hoeft
                          dus niks te doen.
                        </Text>
                      )}
                    </Text>
                  </View>
                  <View style={[globalStyles.contentView, styles.contentView]}>
                    {due > 0 && (
                      <>
                        <View style={styles.article}>
                          <Text style={globalStyles.mediumPlus}>iDEAL</Text>
                          <Select
                            style={{marginTop: 16}}
                            icon={
                              bank ? (
                                <Image source={bank.icon} style={styles.icon} />
                              ) : (
                                <IdealIcon height={24} width={24} />
                              )
                            }
                            defaultText="Selecteer bank"
                            options={issuers}
                            value={bank}
                            onChange={setBank}
                          />
                        </View>
                        <View style={[styles.article, {padding: 0}]}>
                          <Pressable
                            style={styles.radioButtonRow}
                            onPress={() => setPayLater(false)}>
                            <RadioButton checked={!payLater} />
                            <View style={styles.radioButtonDescription}>
                              <Text>Nu betalen</Text>
                            </View>
                          </Pressable>
                          {canPayLater && order?.can_pay_later && (
                            <Pressable
                              style={styles.radioButtonRow}
                              onPress={() => setPayLater(true)}>
                              <RadioButton checked={payLater} />
                              <View style={styles.radioButtonDescription}>
                                <Text>Later betalen</Text>
                              </View>
                            </Pressable>
                          )}
                        </View>
                      </>
                    )}
                  </View>
                </>
              ),
              footer: (
                <>
                  {due > 0 ? (
                    <>
                      <View
                        style={[globalStyles.footerView, styles.footerView]}>
                        {payLater ? (
                          <Text style={globalStyles.mediumBold}>
                            Openstaand bedrag
                          </Text>
                        ) : (
                          <Text style={globalStyles.mediumBold}>
                            Te betalen bedrag
                          </Text>
                        )}
                        <Text style={globalStyles.mediumBold}>
                          {Format.price(due)}
                        </Text>
                      </View>
                    </>
                  ) : null}
                  <View style={globalStyles.footerView}>
                    <PrimaryButton
                      disabled={!isValid || submitted}
                      onPress={onNext}>
                      Volgende
                    </PrimaryButton>
                  </View>
                </>
              ),
            }}
          </PublicContentView>
        </>
      )}
    </SafeAreaView>
  );
};

export default Pay;
