import {StyleSheet} from 'react-native';

const styles = {
  headerView: {
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
  },
  article: {},
  articleHeader: {
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  row: {
    paddingHorizontal: 16,
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};

export default StyleSheet.create(styles);
