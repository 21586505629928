import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';

import withScreenIsFocused from '../components/hoc/with-screen-is-focused';
import ChevronLeftIcon from '../images/md-icons/chevron_left/materialicons/24px.svg';
import Pickup from '../screens/pickup';
import {Pay, Complete} from '../screens/checkout';
import Chat from '../screens/chat';

const PickupStack = createStackNavigator();

export default () => (
  <PickupStack.Navigator
    initialRouteName="Chat"
    screenOptions={{
      headerBackImage: () => <ChevronLeftIcon />,
      headerShown: true,
      headerTitle: '',
      headerStyle: {
        shadowColor: 'transparent',
        borderBottomWidth: 0,
        elevation: 0,
      },
      cardStyle: {flex: 1},
    }}>
    <PickupStack.Screen
      name="Details"
      component={withScreenIsFocused(Pickup)}
    />
    <PickupStack.Screen name="Chat" component={withScreenIsFocused(Chat)} />
    <PickupStack.Screen
      name="Pay"
      component={withScreenIsFocused((props) => (
        <Pay {...props} canPayLater={false} />
      ))}
    />
    <PickupStack.Screen
      name="Complete"
      component={withScreenIsFocused((props) => (
        <Complete
          {...props}
          description=""
          onComplete={() => {
            props.navigation.replace('Index', {
              order_id: props.route.params?.order_id,
            });
          }}
        />
      ))}
    />
  </PickupStack.Navigator>
);
