import React, {useEffect, useLayoutEffect, useState} from 'react';
import {SafeAreaView, View, Image} from 'react-native';
import _ from 'lodash';
import moment from 'moment';
import {validate} from 'uuid';

import styles from './styles';
import globalStyles from '../../../styles';
import {dealer as dealerApi} from '../../../api/public';
import {DefaultButton, Text} from '../../../components/controls';
import {PublicContentView} from '../../../components/shared';
import {useDispatch, useSelector} from '../../../lib/hooks';
import {addJobReset} from '../../../actions';
import {setDealer} from '../../../actions';
import {apiRoot as publicApiRoot} from '../../../api/public/endpoints';
import config from '../../../configuration';

const Intro = ({navigation, route}) => {
  const dispatch = useDispatch();
  const dealer_id = route.params?.dealer_id;
  const date = route.params?.date;
  const service = route.params?.service;
  const license = route.params?.license;

  const stored_ref = useSelector((state) => state.ref);
  const ref = (validate(route.params?.ref) && route.params?.ref) || stored_ref;

  const [loading, setLoading] = useState(true);
  const [imageWidth, setImageWidth] = useState(null);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  useEffect(() => {
    const fetch = async () => {
      let dealer = null;
      if (dealer_id) {
        try {
          dealer = await dealerApi.details(dealer_id);
        } catch {
          dealer = null;
        }
      }

      if (!dealer) {
        navigation?.replace('Error');
        return;
      }

      dispatch(setDealer(dealer));
      setLoading(false);
    };

    fetch();
  }, [navigation, dealer_id, dispatch]);

  const onPhotoLayout = (e) => {
    const {layout} = e.nativeEvent;
    setImageWidth(layout.width);
  };

  const imageHeight = imageWidth ? imageWidth * (186.56 / 414) : null;

  return (
    <SafeAreaView style={globalStyles.mainView}>
      <PublicContentView>
        <>
          <Image
            onLayout={onPhotoLayout}
            style={[styles.photo, {height: imageHeight}]}
            source={{
              uri: `${publicApiRoot}/dealer/photo/${dealer_id}`,
            }}
          />
          <View style={[globalStyles.contentView, styles.contentView]}>
            <Text
              style={[
                globalStyles.xLarge,
                {fontSize: 24, lineHeight: 30, textAlign: 'center'},
              ]}>
              Jouw afspraak bij onze werkplaats
            </Text>
            <Text
              style={[
                globalStyles.regular,
                {
                  fontSize: 18,
                  lineHeight: 22.5,
                  textAlign: 'center',
                  paddingTop: 7,
                  paddingBottom: 50,
                },
              ]}>
              Snel, gemakkelijk, updates op je mobiel.
            </Text>
            <View>
              {!loading && (
                <DefaultButton
                  style={[
                    globalStyles.transparentButton,
                    {
                      shadowOffset: {width: 0, height: 1.5},
                      shadowOpacity: 0.2,
                      shadowColor: '#000',
                      shadowRadius: 5,
                      elevation: 3,
                      width: 100,
                      paddingHorizontal: 0,
                      paddingTop: 10,
                      paddingBottom: 7,
                    },
                  ]}
                  onPress={() => {
                    if (!config.development) {
                      dispatch(
                        addJobReset({
                          hard: true,
                          init: {
                            date: date
                              ? moment.utc(date, 'YYYY-MM-DD', true)
                              : null,
                            license,
                            services: service
                              ? _.isArray(service)
                                ? service.map((service_code) => ({
                                    service_code,
                                  }))
                                : [{service_code: service}]
                              : null,
                            ref,
                          },
                        }),
                      );
                    }

                    if (ref) {
                      navigation.navigate('Type');
                    } else {
                      navigation.navigate('Car');
                    }
                  }}>
                  <Text
                    style={[
                      globalStyles.medium,
                      {
                        color: '#231fda',
                        textTransform: 'uppercase',
                        fontSize: 14,
                      },
                    ]}>
                    Starten
                  </Text>
                </DefaultButton>
              )}
            </View>
          </View>
        </>
      </PublicContentView>
    </SafeAreaView>
  );
};

export default Intro;
