import React, {useLayoutEffect} from 'react';
import {SafeAreaView, View, ScrollView} from 'react-native';

import globalStyles from '../../../styles';

import styles from './styles';
import {
  LinearProgress,
  PrimaryButton,
  Text,
} from '../../../components/controls';
import PublicContentView from '../../../components/shared/PublicContentView';
import {useSelector} from '../../../lib/hooks';

const More = ({navigation}) => {
  const history = useSelector((state) => state.car_state);

  useLayoutEffect(() => {
    navigation?.setOptions({
      headerTitle: () => (
        <View style={{alignItems: 'center'}}>
          <LinearProgress style={{width: 126}} step={3} total={7} />
        </View>
      ),
      headerRight: () => <View />,
    });
  }, [navigation]);

  const onNext = () => {
    navigation.navigate('Date');
  };

  const content = (
    <>
      <View style={[globalStyles.headerView, styles.headerView]}>
        <Text style={globalStyles.headerText}>Historie voorspelt toekomst</Text>
      </View>
      <View
        style={{
          paddingHorizontal: 16,
          paddingVertical: 16,
        }}>
        <Text style={globalStyles.mediumPlus}>Verwacht</Text>
        <Text>
          Gebaseerd op de historie van de auto en de opgegeven kilometerstand,
          verwachten wij dat de onderstaande onderdelen aan vervanging toe zijn.
          Wij gaan bij de start van de werkzaamheden extra opletten of
          vervanging echt nodig is!
        </Text>
      </View>
      <ScrollView>
        <View style={styles.articleHeader}>
          <Text style={globalStyles.mediumPlus}>Mogelijk meerwerk</Text>
        </View>
        {history.map((row, index) => (
          <React.Fragment key={index}>
            <View
              style={{
                padding: 16,
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                borderBottomWidth: 1,
                borderBottomColor: '#f2f2f2',
              }}>
              <View>
                <Text style={globalStyles.regular}>{row.designation}</Text>
              </View>
            </View>
          </React.Fragment>
        ))}
      </ScrollView>
    </>
  );

  const footer = (
    <View
      style={[
        globalStyles.footerView,
        {
          borderTopWidth: 1,
          borderTopColor: '#dcdcdc',
        },
      ]}>
      <PrimaryButton onPress={onNext}>Volgende</PrimaryButton>
    </View>
  );

  return (
    <SafeAreaView style={globalStyles.mainView}>
      <PublicContentView>{{content, footer}}</PublicContentView>
    </SafeAreaView>
  );
};

export default More;
