import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';

import withScreenIsFocused from '../components/hoc/with-screen-is-focused';
import ChevronLeftIcon from '../images/md-icons/chevron_left/materialicons/24px.svg';
import {Intro, Details, Check} from '../screens/approve';
import {Pay, Complete, Thanks} from '../screens/checkout';
import Chat from '../screens/chat';
import {CommonActions} from '@react-navigation/routers';

const ApprovalStack = createStackNavigator();

export default () => (
  <ApprovalStack.Navigator
    initialRouteName="Index"
    screenOptions={{
      headerBackImage: () => <ChevronLeftIcon />,
      headerTitle: '',
      headerStyle: {
        shadowColor: 'transparent',
        borderBottomWidth: 0,
        elevation: 0,
      },
      cardStyle: {flex: 1},
    }}>
    <ApprovalStack.Screen name="Index" component={withScreenIsFocused(Intro)} />
    <ApprovalStack.Screen
      name="Details"
      component={withScreenIsFocused(Details)}
    />
    <ApprovalStack.Screen name="Chat" component={withScreenIsFocused(Chat)} />
    <ApprovalStack.Screen name="Check" component={withScreenIsFocused(Check)} />
    <ApprovalStack.Screen name="Pay" component={withScreenIsFocused(Pay)} />
    <ApprovalStack.Screen
      name="Complete"
      component={withScreenIsFocused((props) => (
        <Complete
          {...props}
          description="Je hoeft nu niets meer te doen. We gaan de werkzaamheden op pakken."
          onComplete={() => {
            props.navigation.dispatch(
              CommonActions.reset({
                index: 1,
                routes: [
                  {
                    name: 'Overview',
                    params: {
                      screen: 'Index',
                      params: {order_id: props.route.params?.order_id},
                    },
                  },
                  {
                    name: 'Overview',
                    params: {
                      screen: 'Chat',
                      params: {order_id: props.route.params?.order_id},
                    },
                  },
                ],
              }),
            );
          }}
        />
      ))}
    />
    <ApprovalStack.Screen
      name="Thanks"
      component={withScreenIsFocused((props) => (
        <Thanks
          {...props}
          title="Bedankt voor je beoordeling!"
          description="Je hoeft nu niets meer te doen. We gaan de werkzaamheden op pakken."
        />
      ))}
    />
  </ApprovalStack.Navigator>
);
