import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  article: {
    paddingHorizontal: 16,
    paddingVertical: 15,
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
    marginTop: -1,
    minHeight: 56,
  },
  label: {
    lineHeight: 32,
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 10,
  },
  textfield: {
    fontSize: 16,
    flex: 1,
    padding: 8,
  },
  row: {
    borderTopWidth: 1,
    borderTopColor: '#f2f2f2',
    marginTop: -1,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    paddingHorizontal: 16,
    paddingVertical: 15,
    minHeight: 56,
  },
  radioButtonRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 16,
  },
  radioButtonDescription: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 56,
    flex: 1,
    marginLeft: 10,
    paddingRight: 16,
    paddingVertical: 0,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    marginBottom: -1,
  },
  last: {
    borderBottomWidth: 0,
  },
});
