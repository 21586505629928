import React from 'react';

export default (Component, className) => {
  const withHover = (props) => {
    return (
      <div className={className}>
        <Component {...props} />
      </div>
    );
  };

  return withHover;
};
