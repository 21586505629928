import {StyleSheet} from 'react-native';

const styles = {
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingVertical: 16,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    marginBottom: -1,
    minHeight: 56,
  },
};

export default StyleSheet.create(styles);
