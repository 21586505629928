import moment from 'moment';

import endpoints from './endpoints';
import config from '../../configuration';
import {fetch} from '../../lib/fetch';

const functionHostKey = config.publicFunctionHostKey;

export default {
  fetchPhoto: async (dealer_id) => {
    const res = await fetch(`${endpoints.dealer}/${dealer_id}/photo`, {
      headers: {
        'x-functions-key': functionHostKey,
      },
    });

    if (!res) {
      return null;
    }

    const result = await res.text();
    return result;
  },
  details: async (dealer_id) => {
    const res = await fetch(`${endpoints.dealer}/${dealer_id}`, {
      headers: {
        'x-functions-key': functionHostKey,
      },
    });
    if (!res) {
      return null;
    }

    const result = await res.json();
    return result;
  },
  availability: async (dealer_id, services, months) => {
    const res = await fetch(`${endpoints.dealer}/${dealer_id}/availability`, {
      headers: {
        'x-functions-key': functionHostKey,
      },
      method: 'POST',
      body: JSON.stringify({
        services: services.map((service) => service.id),
        from: moment(),
        to: moment().add(months, 'months').endOf('month'),
      }),
    });

    if (!res) {
      return null;
    }

    const result = await res.json();
    return result;
  },
  timeslots: async (dealer_id, services, date) => {
    const res = await fetch(`${endpoints.dealer}/${dealer_id}/availability`, {
      headers: {
        'x-functions-key': functionHostKey,
      },
      method: 'POST',
      body: JSON.stringify({
        services: services.map((service) => service.id),
        from: moment(date).isSame(moment(), 'date')
          ? moment()
          : moment.utc(date).startOf('day'),
        to: moment.utc(date).add(1, 'day').startOf('day'),
      }),
    });

    if (!res) {
      return null;
    }

    const result = await res.json();
    return result;
  },
  services: async (dealer_id, license, mileage, is_lease) => {
    const res = await fetch(`${endpoints.dealer}/${dealer_id}/services`, {
      headers: {
        'x-functions-key': functionHostKey,
      },
      method: 'POST',
      body: JSON.stringify({license, mileage, is_lease}),
    });
    if (!res) {
      return null;
    }

    const result = await res.json();
    return result;
  },
};
