import trace from '../lib/trace';
import config from '../configuration';

const wrappedFetch = async (url, options) => {
  const headers = {
    ...options?.headers,
    'x-functions-key': config.publicFunctionHostKey,
  };

  const start = Date.now();
  const result = await fetch(url, {
    ...options,
    headers,
  });
  const diff = Date.now() - start;
  trace(url, result.status, `${diff}ms`);

  return result;
};

export {wrappedFetch as fetch};
