import React, {useEffect, useState, useLayoutEffect} from 'react';
import {SafeAreaView, View, ActivityIndicator} from 'react-native';
import moment from 'moment';

import styles from './styles';
import globalStyles from '../../../styles';
import {request as requestApi, order as orderApi} from '../../../api/public';
import {
  Checkbox,
  CloseButton,
  FormSheetModal,
  Pressable,
  Dialog,
  Text,
  LinearProgress,
  PrimaryButton,
} from '../../../components/controls';
import {PublicContentView, Task} from '../../../components/shared';
import Format from '../../../lib/format';
import {useDispatch, useSelector} from '../../../lib/hooks';
import {approveSetNotApproved} from '../../../actions';
import ExpandMoreIcon from '../../../images/md-icons/expand_more/materialicons/24px.svg';
import ExpandLessIcon from '../../../images/md-icons/expand_less/materialicons/24px.svg';

const Detail = ({navigation, route}) => {
  const dispatch = useDispatch();
  const order_id = route.params?.order_id;

  const notApproved = useSelector((state) => state.not_approved);
  const [order, setOrder] = useState({});
  const [request, setRequest] = useState({});
  const [approvals, setApprovals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [task, setTask] = useState(null);
  const [expand, setExpand] = useState(false);
  const [expandPastApprovals, setExpandPastApprovals] = useState(false);
  const [expandPastRejections, setExpandPastRejections] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: true,
      headerTitle: () => (
        <View style={{alignItems: 'center'}}>
          <LinearProgress style={{width: 126}} step={1} total={2} />
        </View>
      ),
      headerRight: () => <View />,
    });
  }, [navigation]);

  useEffect(() => {
    const fetch = async () => {
      const order = await orderApi.details(order_id);
      const request = await requestApi.fetch(order_id);

      setOrder(order);
      setRequest(request);
      setApprovals(
        request?.tasks?.filter((task) => task.id && task.is_approved === null),
      );
      setLoading(false);
    };

    fetch();
  }, [navigation, order_id, dispatch]);

  const saveApproved = async () => {
    const approved = approvals.reduce((acc, task) => {
      if (!acc[task.request_id]) {
        acc[task.request_id] = [];
      }

      if (!notApproved || !notApproved.includes(task.id)) {
        acc[task.request_id].push(task.id);
      }

      return acc;
    }, {});

    return await orderApi.approve(order_id, approved);
  };

  const onApprove = async () => {
    navigation.navigate('Check', {order_id});
  };

  const onConfirmReject = async () => {
    dispatch(approveSetNotApproved(approvals.map((task) => task.id)));

    setShowAlert(true);
  };

  const onReject = async () => {
    const result = await saveApproved();
    if (result.success) {
      navigation.replace('Chat', {order_id});
    }
  };

  const onShowTask = async (task_id) => {
    const task = await orderApi.fetchTask(task_id);
    setTask(task);
  };

  const onCheckedChanged = (checked, id) => {
    let newState = notApproved || [];
    if (!checked) {
      newState.push(id);
    } else {
      newState = newState.filter((item) => item !== id);
    }

    dispatch(approveSetNotApproved(newState.slice(0)));
  };

  const approveCount = approvals?.filter(
    (task) => !notApproved?.includes(task.id),
  ).length;

  const pastApprovals = request?.tasks?.filter((task) => task.is_approved);
  const pastRejections = request?.tasks?.filter(
    (task) => task.is_approved === false,
  );
  const total = approvals
    ?.filter((task) => !notApproved?.includes(task.id))
    .reduce((acc, value) => acc + value.amount, 0);

  return (
    <SafeAreaView style={globalStyles.mainView}>
      {loading && <ActivityIndicator size="large" color="#231fda" />}
      {!loading && (
        <>
          <PublicContentView>
            {{
              content: (
                <>
                  <View style={globalStyles.headerView}>
                    <Text style={[globalStyles.headerText, styles.headerText]}>
                      Werkvoorstel beoordelen
                    </Text>
                    <Text style={{marginTop: 10, fontSize: 18}}>
                      Laat een vinkje staan als je een item wilt goedkeuren.
                      Druk op een item voor de details.
                    </Text>
                  </View>
                  <View style={globalStyles.contentView}>
                    <View style={styles.article}>
                      <Pressable
                        style={[styles.row, {alignItems: 'center'}]}
                        onPress={() => setExpand(!expand)}>
                        <Text style={globalStyles.mediumBold}>Afspraak</Text>
                        {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </Pressable>
                      {expand && (
                        <>
                          <View style={styles.row}>
                            <Text>{request?.order?.car_name}</Text>
                            <Text>
                              {Format.license(request?.order?.license)}
                            </Text>
                          </View>
                          <View style={styles.row}>
                            <Text>Datum</Text>
                            <Text>
                              {moment(request?.order?.datetime).format(
                                'dddd D MMMM YYYY',
                              )}
                            </Text>
                          </View>
                          <View style={styles.row}>
                            <Text>Tijd</Text>
                            <Text>
                              {moment(order?.datetime).format('H:mm')}
                            </Text>
                          </View>
                        </>
                      )}
                    </View>
                    <View style={styles.article}>
                      <View style={[styles.row, {alignItems: 'center'}]}>
                        <Text style={globalStyles.mediumPlus}>Offerte</Text>
                      </View>
                      <View style={styles.tasks}>
                        {approvals?.map((task) => {
                          const has_details =
                            task.parts?.length ||
                            task.markup_fee !== null ||
                            task.environment_surcharge !== null ||
                            task.is_required ||
                            task.has_diagnosis;

                          return (
                            <View key={task.id} style={styles.task}>
                              <Checkbox
                                checked={!notApproved?.includes(task.id)}
                                onPress={(checked) =>
                                  onCheckedChanged(checked, task.id)
                                }
                              />
                              <Pressable
                                disabled={!has_details}
                                style={styles.taskDescription}
                                onPress={() => onShowTask(task.id)}>
                                <View style={{flex: 1}}>
                                  <Text>{task.description}</Text>
                                  <Text
                                    style={[globalStyles.small, styles.link]}>
                                    {task.parts?.length > 0 && (
                                      <Text style={globalStyles.small}>
                                        {task.parts.length}&nbsp;onderdelen
                                      </Text>
                                    )}
                                    {task.is_required && (
                                      <Text style={globalStyles.small}>
                                        {task.parts?.length > 0 ? ', ' : ''}APK
                                        eis
                                      </Text>
                                    )}
                                  </Text>
                                </View>
                                <Text>
                                  {!notApproved?.includes(task.id)
                                    ? Format.price(task.amount)
                                    : null}
                                </Text>
                              </Pressable>
                            </View>
                          );
                        })}

                        <View style={[styles.row, {borderBottomWidth: 0}]}>
                          <View>
                            <Text style={globalStyles.mediumSemi}>Totaal</Text>
                            <Text style={globalStyles.small}>
                              Inclusief BTW
                            </Text>
                          </View>
                          <Text style={globalStyles.mediumSemi}>
                            {Format.price(total)}
                          </Text>
                        </View>

                        {pastApprovals?.length > 0 && (
                          <View style={styles.article}>
                            <Pressable
                              style={[styles.row, {alignItems: 'center'}]}
                              onPress={() =>
                                setExpandPastApprovals(!expandPastApprovals)
                              }>
                              <Text style={globalStyles.mediumPlus}>
                                Eerder goedgekeurd ({pastApprovals.length})
                              </Text>
                              {expandPastApprovals ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </Pressable>
                            {expandPastApprovals &&
                              pastApprovals.map((task) => (
                                <View key={task.id} style={styles.row}>
                                  <Text>{task.description}</Text>
                                  <Text>{Format.price(task.amount)}</Text>
                                </View>
                              ))}
                          </View>
                        )}
                        {pastRejections?.length > 0 && (
                          <View style={styles.article}>
                            <Pressable
                              style={[styles.row, {alignItems: 'center'}]}
                              onPress={() =>
                                setExpandPastRejections(!expandPastRejections)
                              }>
                              <Text style={globalStyles.mediumPlus}>
                                Eerder afgewezen ({pastRejections.length})
                              </Text>
                              {expandPastRejections ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </Pressable>
                            {expandPastRejections &&
                              pastRejections.map((task) => (
                                <View key={task.id} style={styles.row}>
                                  <Text
                                    style={{
                                      textDecorationLine: 'line-through',
                                    }}>
                                    {task.description}
                                  </Text>
                                </View>
                              ))}
                          </View>
                        )}
                      </View>
                    </View>
                  </View>
                </>
              ),
              footer: (
                <>
                  {approvals?.length > 0 && (
                    <View style={globalStyles.footerView}>
                      {approveCount > 0 ? (
                        <PrimaryButton onPress={onApprove}>
                          Volgende
                        </PrimaryButton>
                      ) : (
                        <PrimaryButton onPress={onConfirmReject}>
                          Volgende
                        </PrimaryButton>
                      )}
                    </View>
                  )}
                </>
              ),
            }}
          </PublicContentView>

          <FormSheetModal visible={!!task} onDismiss={() => setTask(null)}>
            <SafeAreaView style={globalStyles.mainView}>
              <View style={[globalStyles.headerView, styles.modalHeaderView]}>
                <CloseButton
                  style={styles.closeButton}
                  onPress={() => setTask(null)}
                />
                <Text>Voorstel</Text>
              </View>
              <View style={[globalStyles.contentView, styles.modalContentView]}>
                <Task task={task} />
              </View>
              <View style={globalStyles.footerView}>
                <PrimaryButton onPress={() => setTask(null)}>
                  Sluiten
                </PrimaryButton>
              </View>
            </SafeAreaView>
          </FormSheetModal>

          <Dialog
            title="Voorstel afkeuren?"
            visible={showAlert}
            onDismiss={() => setShowAlert(false)}
            buttons={[
              {text: 'Annuleren', onPress: () => setShowAlert(false)},
              {text: 'OK', onPress: onReject},
            ]}>
            <Text>
              Hiermee keur je het werk af en opent de chat waarin je een reden
              kunt opgeven.
            </Text>
          </Dialog>
        </>
      )}
    </SafeAreaView>
  );
};

export default Detail;
