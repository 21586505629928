import React from 'react';
import {View, TouchableOpacity} from 'react-native';
import moment from 'moment';
import _ from 'lodash';

import globalStyles from '../../../styles';
import styles from './styles';
import Text from '../Text';

const Calendar = ({
  cellWidth,
  month,
  date,
  availability,
  advance_notice,
  onPress,
  onShowAlert,
  onClear = _.noop,
}) => {
  const start = moment(month).startOf('month');
  const end = start.clone().endOf('month');
  const today = moment();

  const days = [];
  const weeks = end.diff(start.clone().startOf('week'), 'week');

  const height = (weeks + 1) * cellWidth;

  for (let i = 0; i <= end.diff(start, 'days'); i++) {
    const current = start.clone().startOf('day').add(12, 'hours').add(i, 'day');

    const past = current.isBefore(today, 'day');
    const selected = date && current.isSame(date, 'date');

    const top = current.diff(start.clone().startOf('week'), 'week') * cellWidth;
    const left = (current.isoWeekday() - 1) * cellWidth;

    const current_availability = availability[current.format('YYYY-MM-DD')];
    const available =
      !past &&
      ((current.isoWeekday() > 5 && current_availability?.capacity > 0) || // the weekends
        (current.isoWeekday() < 6 &&
          (!current_availability ||
            (current_availability.blocked === null &&
              current_availability.capacity === null) ||
            (current_availability.blocked === false &&
              current_availability.capacity > 0))));

    let pressure = !past && current_availability?.pressure;
    if (current.isSameOrBefore(moment().add(advance_notice), 'date')) {
      pressure = 2;
    }

    if (selected && !available) {
      // This is a undesired situation, so clear the selected date.
      setTimeout(() => onClear(), 0);
    }

    const day = (
      <TouchableOpacity
        key={current.format('DD-MM-YYYY')}
        style={[
          styles.dayView,
          {width: cellWidth, height: cellWidth, top, left},
        ]}
        onPress={
          available
            ? () => {
                if (pressure === 2) {
                  onShowAlert(current.utc());
                  return;
                }

                onPress(current.utc());
              }
            : _.noop
        }>
        <View
          style={[
            available &&
              (pressure === 1
                ? styles.warning
                : pressure === 2
                ? styles.alert
                : styles.available),
            past && styles.past,
            selected && styles.selected,
            styles.dayText,
          ]}>
          <Text
            style={[
              pressure === 1
                ? styles.warningText
                : pressure === 2
                ? styles.alertText
                : styles.availableText,
              (!available || past) && styles.grayText,
              selected && styles.whiteText,
            ]}>
            {i + 1}
          </Text>
        </View>
      </TouchableOpacity>
    );
    days.push(day);
  }

  return (
    <>
      <View style={[styles.month, {height: cellWidth}]}>
        <Text style={globalStyles.mediumBold}>
          {moment(month).format('MMMM YYYY')}
        </Text>
      </View>
      <View style={[styles.days, {maxWidth: cellWidth * 7, height}]}>
        {days}
      </View>
    </>
  );
};

export default React.memo(Calendar);
