import {StyleSheet} from 'react-native';

const styles = {
  headerView: {
    paddingBottom: 40,
  },
  title: {
    marginBottom: 10,
  },
  contentView: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  article: {
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
    marginTop: -1,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 19,
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    minHeight: 56,
  },
};

export default StyleSheet.create(styles);
