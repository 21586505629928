import React, {useState, useEffect} from 'react';
import {View} from 'react-native';

import Pressable from '../Pressable';
import styles from './styles';
import DefaultIcon from '../../../images/md-icons/radio_button_unchecked/materialicons/24px.svg';
import CheckedIcon from '../../../images/md-icons/radio_button_checked/materialicons/24px.svg';

const RadioButton = (props) => {
  const [checked, setChecked] = useState(props.checked);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const onPress = () => {
    if (props.disabled) {
      return;
    }

    const value = !checked;

    if (props.onPress) {
      props.onPress(value);
    }
  };

  return (
    <Pressable allowBubbling onPress={onPress} style={{opacity: 1}}>
      <View
        style={[styles.root, props.style, props.disabled && styles.disabled]}>
        {!checked && <DefaultIcon fill="#c4c4c4" />}
        {checked && <CheckedIcon fill="#231fda" />}
      </View>
    </Pressable>
  );
};

export default RadioButton;
