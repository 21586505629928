import React from 'react';
import Modal from 'modal-react-native-web';
import {Portal} from 'react-native-paper';

const FormSheetModal = ({
  visible,
  animationType = 'fade',
  onDismiss,
  children,
}) => (
  <Modal
    transparent={true}
    visible={visible}
    onDismiss={onDismiss}
    animationType={animationType}
    ariaHideApp={false}>
    <div className="modal-bg" onClick={onDismiss}>
      <div className="modal">
        <Portal.Host>{children}</Portal.Host>
      </div>
    </div>
  </Modal>
);

export default FormSheetModal;
