import endpoints from './endpoints';
import {fetch} from '../../lib/fetch';
import config from '../../configuration';

const functionHostKey = config.publicFunctionHostKey;

const order = {
  fetch: async (id) => {
    const result = await fetch(`${endpoints.order}/${id}`, {
      headers: {
        'x-functions-key': functionHostKey,
      },
    });

    const json = await result.json();
    return json;
  },
  details: async (id) => {
    const result = await fetch(`${endpoints.order}/details/${id}`, {
      headers: {
        'x-functions-key': functionHostKey,
      },
    });

    const json = await result.json();
    return json;
  },
  payment: async (id) => {
    const result = await fetch(`${endpoints.order}/payment/${id}`, {
      method: 'POST',
      headers: {
        'x-functions-key': functionHostKey,
      },
    });

    const json = await result.json();
    return json;
  },
  paymentResult: async (id, payment_id) => {
    const result = await fetch(
      `${endpoints.order}/payment/${id}?payment_id=${payment_id}`,
      {
        headers: {
          'x-functions-key': functionHostKey,
        },
      },
    );

    const json = await result.json();
    return json;
  },
  list: async (user_id) => {
    const result = await fetch(`${endpoints.orders}`, {
      headers: {
        'x-functions-key': functionHostKey,
      },
      method: 'POST',
      body: JSON.stringify({user_id}),
    });

    const json = await result.json();
    return json;
  },
  fetchTask: async (task_id) => {
    const result = await fetch(`${endpoints.order}/task/${task_id}`, {
      headers: {
        'x-functions-key': functionHostKey,
      },
    });

    const json = await result.json();
    return json;
  },
  fetchApprovals: async (id) => {
    const result = await fetch(`${endpoints.order}/approve/${id}`, {
      headers: {
        'x-functions-key': functionHostKey,
      },
    });

    const json = await result.json();
    return json;
  },
  approve: async (id, approved) => {
    const result = await fetch(`${endpoints.order}/approve/${id}`, {
      method: 'PATCH',
      headers: {
        'x-functions-key': functionHostKey,
      },
      body: JSON.stringify({approved}),
    });

    const json = await result.json();
    return json;
  },
  fetchPhoto: async (photo_id) => {
    const res = await fetch(`${endpoints.order}/photo/${photo_id}`, {
      headers: {
        'x-functions-key': functionHostKey,
      },
    });

    if (!res) {
      return null;
    }

    const result = await res.text();
    return result;
  },
  confirm: async (id, approved) => {
    const result = await fetch(`${endpoints.order}/confirm/${id}`, {
      method: 'PATCH',
      headers: {
        'x-functions-key': functionHostKey,
      },
      body: JSON.stringify({approved}),
    });

    const json = await result.json();
    return json;
  },
};

export default order;
