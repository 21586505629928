import {StyleSheet} from 'react-native';

const styles = {
  article: {},
  articleHeader: {
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
    marginTop: -1,
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  row: {
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
};

export default StyleSheet.create(styles);
