import {StyleSheet} from 'react-native';

const styles = {
  root: {
    position: 'relative',
    flex: 1,
  },
  contentContainer: {
    display: 'flex',
    minHeight: '100%',
  },
  footer: {
    width: '100%',
    backgroundColor: '#fff',
    borderTopWidth: 1,
    borderColor: '#dcdcdc',
    marginTop: -1,
  },
};

export default StyleSheet.create(styles);
