import React, {useState, useEffect, useLayoutEffect} from 'react';
import {SafeAreaView, View, ScrollView} from 'react-native';

import globalStyles from '../../../styles';
import styles from './styles';
import {
  FormTextInput,
  RadioButton,
  Pressable,
  FormNumberInput,
  InfoButton,
  Text,
  Select,
  LinearProgress,
  CloseButton,
  PrimaryButton,
} from '../../../components/controls';
import {PublicContentView} from '../../../components/shared';
import {
  addJobIsLease,
  addJobSetLease,
  addJobSetCarRepairTypeId,
  addJobSetLicense,
  addJobSetMileage,
  addJobSetCompanyCar,
} from '../../../actions';
import {useDispatch, useSelector} from '../../../lib/hooks';
import {car as carApi} from '../../../api/public';
import leaseCompanies from '../../../types/lease-companies';

const Index = ({navigation, onDismiss}) => {
  const dispatch = useDispatch();

  const stored = useSelector((state) => ({
    is_company_car: state.is_company_car,
    is_lease: state.is_lease,
    lease: state.lease,
    license: state.license,
    mileage: state.mileage,
    repair_type_id: state.repair_type_id,
  }));

  const [is_company_car, setIsCompanyCar] = useState(stored.is_company_car);
  const [is_lease, setIsLease] = useState(stored.is_lease);
  const [lease, setLease] = useState(stored.lease);
  const [license, setLicense] = useState(stored.license);
  const [mileage, setMileage] = useState(stored.mileage);
  const [repair_type_id, setRepairTypeId] = useState(stored.car_repair_type_id);
  const dealer = useSelector((state) => state.dealer);

  const [car, setCar] = useState({});

  useLayoutEffect(() => {
    navigation?.setOptions({
      headerTitle: () => (
        <View style={{alignItems: 'center'}}>
          <LinearProgress style={{width: 126}} step={1} total={7} />
        </View>
      ),
      headerRight: () => <View />,
    });
  }, [navigation]);

  useEffect(() => {
    const fetch = async () => {
      if (!license || license.length < 6) {
        setCar({});
        return;
      }

      try {
        const car = await carApi.fetch(license);
        setCar(car);

        if (car.repair_type_id) {
          setRepairTypeId(car.repair_type_id);
        }
      } catch {
        setCar({});
      }
    };

    fetch();
  }, [license, dispatch]);

  const isValid =
    license &&
    license.length > 5 &&
    (!is_lease || lease) &&
    (!dealer.has_quote || mileage);

  const onNext = () => {
    if (!isValid) {
      return;
    }

    dispatch(
      addJobSetLicense(license.replace(/[^0-9a-z]/gi, '').toUpperCase()),
    );
    dispatch(addJobSetMileage(mileage));
    dispatch(addJobIsLease(is_lease));
    dispatch(addJobSetCompanyCar(is_company_car));
    dispatch(addJobSetCarRepairTypeId(repair_type_id));
    dispatch(addJobSetLease(lease));
    setCar({});

    if (onDismiss) {
      onDismiss();
    } else {
      navigation.navigate('User');
    }
  };

  const content = (
    <>
      {!onDismiss ? (
        <>
          <View style={globalStyles.headerView}>
            <Text style={globalStyles.headerText}>Gegevens van je auto</Text>
            <Text style={{marginTop: 10, fontSize: 18}}>
              Voor het geven van de juiste mogelijkheden hebben we wat info van
              je auto nodig.
            </Text>
          </View>
        </>
      ) : (
        <View style={globalStyles.modalHeaderView}>
          <CloseButton
            style={{position: 'absolute', top: 8, left: 8}}
            onPress={onDismiss}
          />
          <Text style={globalStyles.medium}>Auto</Text>
        </View>
      )}
      <View style={globalStyles.contentView}>
        <View style={styles.article}>
          <Text style={[globalStyles.mediumPlus, styles.label]}>Kenteken</Text>
          <FormTextInput
            value={license}
            onChangeText={(text) =>
              setLicense(text?.replace(/[^0-9a-z]/gi, '').toUpperCase())
            }
            label="Voer cijfer-lettercombinatie in"
            required={true}
            errorMessage={'Kenteken is verplicht'}
            inputProps={{
              maxLength: 8,
              autoCapitalize: 'characters',
            }}
          />
          {car?.name && <Text style={{lineHeight: 20}}>{car.name}</Text>}
        </View>
        <View style={styles.article}>
          <View style={styles.label}>
            <Text style={globalStyles.mediumPlus}>
              Indicatie kilometerstand&nbsp;
            </Text>
            <InfoButton
              title="Waarom een indicatie van de kilometerstand opgeven?"
              info="Daarmee kunnen we je van de juiste offerte voorzien door bijvoorbeeld uit te zoeken welke onderhoudsbeurt er nodig is."
            />
          </View>
          <FormNumberInput
            value={mileage}
            onChangeNumber={(number) => setMileage(number)}
            required={dealer.has_quote}
            errorMessage={'Kilometerstand is verplicht'}
            digits={0}
            label="Voer schatting in"
            inputProps={{
              maxLength: 7,
            }}
          />
        </View>
        <View style={[styles.article, styles.noPadding]}>
          <View style={styles.row}>
            <Text style={globalStyles.mediumPlus}>Eigendom</Text>
          </View>

          <Pressable
            style={styles.radioButtonRow}
            onPress={() => {
              setIsLease(false);
              setIsCompanyCar(false);
            }}>
            <RadioButton checked={!is_company_car && !is_lease} />
            <View style={styles.radioButtonDescription}>
              <Text>Particulier</Text>
            </View>
          </Pressable>
          <Pressable
            style={styles.radioButtonRow}
            onPress={() => {
              setIsLease(false);
              setIsCompanyCar(true);
            }}>
            <RadioButton checked={is_company_car && !is_lease} />
            <View style={styles.radioButtonDescription}>
              <Text>Zakelijk</Text>
            </View>
          </Pressable>
          <Pressable
            style={styles.radioButtonRow}
            onPress={() => {
              setIsLease(true);
              setIsCompanyCar(false);
            }}>
            <RadioButton checked={!is_company_car && is_lease} />
            <View style={styles.radioButtonDescription}>
              <Text>Private lease</Text>
            </View>
          </Pressable>
          <Pressable
            style={styles.radioButtonRow}
            onPress={() => {
              setIsLease(true);
              setIsCompanyCar(true);
            }}>
            <RadioButton checked={is_company_car && is_lease} />
            <View style={[styles.radioButtonDescription, styles.last]}>
              <Text>Zakelijk lease</Text>
            </View>
          </Pressable>
        </View>
        {is_lease && (
          <View style={styles.article}>
            <Text style={[globalStyles.mediumPlus, styles.label]}>
              Leasemaatschappij
            </Text>
            <Select
              defaultText="Selecteer"
              value={lease}
              options={leaseCompanies}
              onChange={(selected) => setLease(selected)}
            />
          </View>
        )}
      </View>
    </>
  );

  const footer = (
    <View style={globalStyles.footerView}>
      <PrimaryButton onPress={onNext} disabled={!isValid}>
        {onDismiss ? 'Opslaan' : 'Volgende'}
      </PrimaryButton>
    </View>
  );

  return (
    <SafeAreaView style={globalStyles.mainView}>
      {dealer && (
        <>
          {onDismiss ? (
            <>
              <ScrollView>{content}</ScrollView>
              <View style={{borderTopWidth: 1, borderTopColor: '#dcdcdc'}}>
                {footer}
              </View>
            </>
          ) : (
            <PublicContentView>
              {{
                content,
                footer,
              }}
            </PublicContentView>
          )}
        </>
      )}
    </SafeAreaView>
  );
};

export default Index;
