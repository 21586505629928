import React, {useState, useRef, useEffect} from 'react';
import {SafeAreaView, View, ActivityIndicator, ScrollView} from 'react-native';
import moment from 'moment';
import {useNavigation} from '@react-navigation/core';

import globalStyles from '../../styles';
import styles from './styles';
import {
  order as orderApi,
  dealer as dealerApi,
  messages as messagesApi,
} from '../../api/public';
import withScreenIsFocused from '../../components/hoc/with-screen-is-focused';
import {
  Persona,
  Text,
  Pressable,
  OrderStatusIcon,
  OrderRequestStatusIcon,
  Link,
} from '../../components/controls';
import {PersonaSize} from '../../components/controls/Persona';
import {HtmlView, RichTextField} from '../../components/shared';
import OrderStatus from '../../types/OrderStatus';
import OrderRequestStatus from '../../types/OrderRequestStatus';
import NotificationsIcon from '../../images/md-icons/notifications/materialicons/24px.svg';
import SendIcon from '../../images/md-icons/send/materialicons/24px.svg';
import _ from 'lodash';

const MESSAGE_TYPE = 'MESSAGE';
const ORDER_TYPE = 'ORDER';
const ORDER_REQUEST_TYPE = 'ORDER_REQUEST';

const Message = ({
  first_name,
  last_name,
  is_generated,
  is_dealer,
  timestamp,
  message,
}) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 10,
      }}>
      <Persona
        is_dealer={is_dealer}
        imageInitials={`${first_name[0]}${(last_name && last_name[0]) || ''}`}
        size={PersonaSize.size40}
      />
      <View
        style={{
          flex: 1,
          paddingLeft: 16,
          paddingRight: 16,
        }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <Text style={globalStyles.mediumBold}>
            {first_name}&nbsp;{last_name}
          </Text>
          <Text
            style={[
              globalStyles.small,
              {
                color: '#7a7a7a',
              },
            ]}>
            {moment(timestamp).format('HH:mm')}
          </Text>
        </View>
        <HtmlView value={message} />
        {is_generated && (
          <Text style={{marginTop: 16, color: '#828282'}}>
            Dit bericht is automatisch gegenereerd.
          </Text>
        )}
      </View>
    </View>
  );
};

const Activity = ({type, status, description, timestamp, rank, order}) => {
  const navigation = useNavigation();
  const isCurrentStatus = order.status === status && rank === 1;

  let info = status;
  let icon = <NotificationsIcon style={{marginRight: 10}} />;
  let link = null;

  if (isCurrentStatus) {
    switch (order?.status) {
      case OrderStatus.Pending:
        link = (
          <Link
            linkStyle={globalStyles.xSmallMedium}
            onPress={() =>
              navigation.navigate('Confirm', {
                screen: 'Details',
                params: {order_id: order.id},
              })
            }>
            Beoordelen
          </Link>
        );
        break;
      case OrderStatus.ApprovalPending:
      case OrderStatus.ApprovalRequested:
        link = (
          <Link
            linkStyle={globalStyles.xSmallMedium}
            onPress={() =>
              navigation.navigate('Approve', {
                screen: 'Details',
                params: {order_id: order.id},
              })
            }>
            Beoordelen
          </Link>
        );
        break;
      case OrderStatus.Done:
        link = (
          <Link
            linkStyle={globalStyles.xSmallMedium}
            onPress={() =>
              navigation.navigate('Pickup', {
                screen: 'Details',
                params: {order_id: order.id},
              })
            }>
            Route &amp; overzicht
          </Link>
        );
        break;
      default:
        link = (
          <Link
            linkStyle={globalStyles.xSmallMedium}
            onPress={() =>
              navigation.navigate('Overview', {
                screen: 'Details',
                params: {order_id: order.id},
              })
            }>
            Bekijken
          </Link>
        );

        break;
    }
  }

  switch (type) {
    case ORDER_TYPE: {
      switch (status) {
        case OrderStatus.DateChanged:
        case OrderStatus.TimeChanged:
          info = `Update · ${OrderStatus.text(status)}`;
          break;
        default:
          info = OrderStatus.text(status);
          break;
      }

      if (description) {
        info += ` (${description})`;
      }

      icon = (
        <OrderStatusIcon
          status={status}
          style={{marginRight: 20}}
          iconProps={_.pickBy(
            {fill: !link && '#828282', width: 18, height: 18},
            _.identity,
          )}
        />
      );

      break;
    }
    case ORDER_REQUEST_TYPE: {
      info = OrderRequestStatus.text(status);

      icon = (
        <OrderRequestStatusIcon
          status={status}
          style={{marginRight: 20}}
          iconProps={_.pickBy(
            {fill: !link && '#828282', width: 18, height: 18},
            _.identity,
          )}
        />
      );

      break;
    }
    default:
      break;
  }

  return (
    <View
      style={{
        marginTop: 10,
        marginBottom: 10,
        paddingTop: 8,
        paddingRight: 16,
        paddingBottom: 8,
        paddingLeft: 16,
        backgroundColor: '#f2f2f2',
        borderRadius: 20,
      }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <View style={{flexDirection: 'row'}}>
          {icon}
          <Text
            style={[
              globalStyles.xSmallMedium,
              {color: isCurrentStatus ? '#4a4a49' : '#828282'},
            ]}>
            {info}
            {link && <>&nbsp;&middot;&nbsp;{link}</>}
          </Text>
        </View>
        <Text
          style={[
            globalStyles.xSmallMedium,
            {color: isCurrentStatus ? '#4a4a49' : '#828282'},
          ]}>
          {moment(timestamp).format('HH:mm')}
        </Text>
      </View>
    </View>
  );
};

const Chat = ({navigation, route}) => {
  const order_id = route.params?.order_id;
  const [clearCounter, setClearCounter] = useState(0);
  const [order, setOrder] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [images, setImages] = useState([]);

  const scrollViewRef = useRef(null);

  useEffect(() => {
    navigation.setOptions({
      headerShown: true,
    });

    const fetch = async () => {
      const order = await orderApi.details(order_id);
      const dealer = await dealerApi.details(order.dealer_id);
      const messages = await messagesApi.list(order_id);

      setMessages(messages);
      setOrder(order);
      setLoading(false);
      navigation.setOptions({
        headerTitle: () => (
          <View style={{alignItems: 'center'}}>
            <Text style={globalStyles.medium}>{dealer.name}</Text>
          </View>
        ),
      });
    };

    fetch();
  }, [order_id, navigation]);

  useEffect(() => {
    const intervalID = setInterval(async () => {
      const messages = await messagesApi.list(order_id);
      const order = await orderApi.details(order_id);
      setMessages(messages);
      setOrder(order);
    }, 5000);

    return () => clearInterval(intervalID);
  }, [order_id]);

  const onSend = async () => {
    if (!message.length && !images.length) {
      return;
    }

    setMessage('');
    setImages([]);
    setClearCounter(clearCounter + 1);

    const response = await messagesApi.sendMessage(order_id, message, images);

    if (response.success) {
      const newState = await messagesApi.list(order_id);
      setMessages(newState);
    }
  };

  return (
    <SafeAreaView style={globalStyles.mainView}>
      <ScrollView
        style={[
          globalStyles.contentView,
          {
            paddingVertical: 12,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 0,
          },
        ]}
        ref={scrollViewRef}
        onContentSizeChange={() =>
          scrollViewRef.current.scrollToEnd({animated: true})
        }>
        {loading && <ActivityIndicator size="large" color="#231fda" />}
        {!loading &&
          messages &&
          Object.keys(messages).map((key) => (
            <View key={key} style={styles.article}>
              <Text
                style={[
                  globalStyles.smallPlus,
                  {
                    textAlign: 'center',
                  },
                ]}>
                {moment(key, 'DD-MM-YYYY').format('DD MMM YYYY')}
              </Text>
              {messages[key].map((message) => {
                switch (message.type) {
                  case MESSAGE_TYPE:
                    return <Message key={message.id} {...message} />;
                  default:
                    return (
                      <Activity key={message.id} {...message} order={order} />
                    );
                }
              })}
            </View>
          ))}
      </ScrollView>
      <View style={{padding: 16, borderTopWidth: 1, borderTopColor: '#dcdcdc'}}>
        <RichTextField
          key={clearCounter}
          style={{flex: 1}}
          defaultValue={message}
          placeholder="Bericht toevoegen"
          buttons={
            <Pressable disabled={!message?.length} onPress={onSend}>
              <SendIcon fill={message?.length ? '#231fda' : '#dcdcdc'} />
            </Pressable>
          }
          onValueChanged={(value) => setMessage(value)}
          onImagesChanged={(value) => setImages(value)}
        />
      </View>
    </SafeAreaView>
  );
};

export default withScreenIsFocused(Chat);
