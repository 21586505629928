import React from 'react';
import {View} from 'react-native';
import {ProgressBar} from 'react-native-paper';

export default ({style, step, total}) => {
  return (
    <View style={[{width: '100%'}, style]}>
      <ProgressBar progress={step / total} color="#231fda" />
    </View>
  );
};
