import trace from './lib/trace';

import envSpecific from './env-configuration';

const config = {
  production: false,
  development: true,
  functionHostKey: 'BWzmt/TwOiHoyoBlQtP7LmNUfbMnnExIiFnsUEHgjVi/5UuiPpXkKA==',
  publicFunctionHostKey:
    'cHJjdyoQ7r7qT7GgvndSy4Qfu15Dwfg7/ewHqkYJSuFDaCNmA5WSBg==',

  hubName: 'bahnkick-dms-notifications-sandbox',
  hubEndpoint:
    'Endpoint=sb://bahnkick.servicebus.windows.net/;SharedAccessKeyName=DefaultListenSharedAccessSignature;SharedAccessKey=JwQvCKwQJVKlaVY29p94hGmCI5PDJwUYl22nCJ1F6WI=',

  //publicApiRoot: 'http://192.168.178.22:7071/api',

  //publicApiRoot: 'http://172.16.30.132:7071/api',

  publicApiRoot: 'http://localhost:7071/api',

  // publicApiRoot: 'http://10.136.120.220:7071/api',

  //publicApiRoot: 'https://bahnkick-dev-api.azurewebsites.net/api',

  //publicApiRoot: 'https://bahnkick-api.azurewebsites.net/api',

  ...envSpecific,
};

trace('Loaded configuration', config);

export default config;
