import React, {useEffect, useLayoutEffect, useState} from 'react';
import {SafeAreaView, View, Image} from 'react-native';

import styles from './styles';
import globalStyles from '../../../styles';
import {dealer as dealerApi, order as orderApi} from '../../../api/public';
import {DefaultButton, Text} from '../../../components/controls';
import {PublicContentView} from '../../../components/shared';
import {useDispatch} from '../../../lib/hooks';
import {apiRoot as publicApiRoot} from '../../../api/public/endpoints';
import OrderStatus from '../../../types/OrderStatus';
import {approveSetNotApproved} from '../../../actions';

const Intro = ({navigation, route}) => {
  const dispatch = useDispatch();
  const order_id = route.params?.order_id;
  const [dealer, setDealer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imageWidth, setImageWidth] = useState(null);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, [navigation]);

  useEffect(() => {
    const fetch = async () => {
      let dealer = null;
      let order = null;
      if (order_id) {
        try {
          order = await orderApi.details(order_id);
          dealer = await dealerApi.details(order.dealer_id);
        } catch {
          dealer = null;
          order = null;
        }
      }

      if (!dealer || !order) {
        navigation?.replace('Error');
        return;
      }

      if (
        order?.status !== OrderStatus.ApprovalRequested &&
        order?.status !== OrderStatus.ApprovalPending
      ) {
        navigation.replace('Overview', {screen: 'Chat', params: {order_id}});
      }

      setDealer(dealer);
      setLoading(false);
    };

    fetch();
  }, [navigation, order_id, dispatch]);

  const onPhotoLayout = (e) => {
    const {layout} = e.nativeEvent;
    setImageWidth(layout.width);
  };

  const imageHeight = imageWidth ? imageWidth * (186.56 / 414) : null;

  return (
    <SafeAreaView style={globalStyles.mainView}>
      <PublicContentView>
        <>
          {dealer && (
            <Image
              onLayout={onPhotoLayout}
              style={[styles.photo, {height: imageHeight}]}
              source={{
                uri: `${publicApiRoot}/dealer/photo/${dealer.id}`,
              }}
            />
          )}
          <View style={[globalStyles.contentView, styles.contentView]}>
            <Text
              style={[
                globalStyles.xLarge,
                {fontSize: 24, lineHeight: 30, textAlign: 'center'},
              ]}>
              De diagnose is afgerond
            </Text>
            <Text
              style={[
                globalStyles.regular,
                {
                  fontSize: 18,
                  lineHeight: 22.5,
                  textAlign: 'center',
                  paddingTop: 7,
                  paddingBottom: 50,
                },
              ]}>
              Bekijk ons advies voor aanvullend onderhoud en geef indien gewenst
              direct opdracht.
            </Text>
            <View>
              {!loading && (
                <DefaultButton
                  style={[
                    globalStyles.transparentButton,
                    {
                      shadowOffset: {width: 0, height: 1.5},
                      shadowOpacity: 0.2,
                      shadowColor: '#000',
                      shadowRadius: 5,
                      elevation: 3,
                      width: 120,
                      paddingHorizontal: 0,
                      paddingTop: 10,
                      paddingBottom: 7,
                    },
                  ]}
                  onPress={() => {
                    dispatch(approveSetNotApproved([]));
                    navigation.navigate('Chat', {order_id});
                  }}>
                  <Text
                    style={[
                      globalStyles.medium,
                      {
                        color: '#231fda',
                        textTransform: 'uppercase',
                        fontSize: 14,
                      },
                    ]}>
                    Beoordelen
                  </Text>
                </DefaultButton>
              )}
            </View>
          </View>
        </>
      </PublicContentView>
    </SafeAreaView>
  );
};

export default Intro;
