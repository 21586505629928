export default {
  setItem: (key, value) => {
    try {
      window.sessionStorage.setItem(key, value);
    } catch {}
  },
  getItem: (key, callback) => {
    try {
      const value = window.sessionStorage.getItem(key);
      callback(null, value);
    } catch (e) {
      callback(e);
    }
  },
};
