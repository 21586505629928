import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import {CommonActions} from '@react-navigation/routers';
import moment from 'moment';

import withScreenIsFocused from '../components/hoc/with-screen-is-focused';
import ChevronLeftIcon from '../images/md-icons/chevron_left/materialicons/24px.svg';
import {
  Car,
  Type,
  More,
  Extras,
  Date,
  Time,
  User,
  Confirm,
  Error,
  Intro,
} from '../screens/booking';
import {Pay, Complete} from '../screens/checkout';

const BookingStack = createStackNavigator();

const renderCompleteDescription = ({order}) => {
  if (!order || !order.datetime) {
    return null;
  }

  if (order.pickup_address) {
    return `We komen je auto halen op ${moment(order.datetime).format(
      'dddd D MMMM YYYY',
    )} om ${moment(order.datetime).utc().format('H:mm')}. Tot dan.`;
  }

  return `We zien je graag op ${moment(order.datetime).format(
    'dddd D MMMM YYYY',
  )} vanaf ${moment(order.datetime).utc().format('H:mm')}.`;
};

export default () => (
  <BookingStack.Navigator
    initialRouteName="Intro"
    screenOptions={{
      headerBackImage: () => <ChevronLeftIcon />,
      headerShown: true,
      headerTitle: '',
      headerStyle: {
        shadowColor: 'transparent',
        elevation: 0,
        borderBottomWidth: 0,
      },
      cardStyle: {flex: 1},
    }}>
    <BookingStack.Screen name="Intro" component={withScreenIsFocused(Intro)} />
    <BookingStack.Screen name="Car" component={withScreenIsFocused(Car)} />
    <BookingStack.Screen name="Type" component={withScreenIsFocused(Type)} />
    <BookingStack.Screen name="More" component={withScreenIsFocused(More)} />
    <BookingStack.Screen
      name="Extras"
      component={withScreenIsFocused(Extras)}
    />
    <BookingStack.Screen name="Date" component={withScreenIsFocused(Date)} />
    <BookingStack.Screen name="Time" component={withScreenIsFocused(Time)} />
    <BookingStack.Screen name="User" component={withScreenIsFocused(User)} />
    <BookingStack.Screen
      name="Confirm"
      component={withScreenIsFocused(Confirm)}
    />
    <BookingStack.Screen name="Pay" component={withScreenIsFocused(Pay)} />
    <BookingStack.Screen
      name="Complete"
      component={withScreenIsFocused((props) => (
        <Complete
          {...props}
          description={renderCompleteDescription}
          onComplete={() => {
            props.navigation.dispatch(
              CommonActions.reset({
                index: 1,
                routes: [
                  {
                    name: 'Overview',
                    params: {
                      screen: 'Index',
                      params: {order_id: props.route.params?.order_id},
                    },
                  },
                  {
                    name: 'Overview',
                    params: {
                      screen: 'Chat',
                      params: {order_id: props.route.params?.order_id},
                    },
                  },
                ],
              }),
            );
          }}
        />
      ))}
    />
    <BookingStack.Screen name="Error" component={withScreenIsFocused(Error)} />
  </BookingStack.Navigator>
);
