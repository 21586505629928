import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  contentView: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  article: {},
  articleHeader: {
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
    marginTop: -1,
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 16,
    minHeight: 56,
  },
  row: {
    paddingHorizontal: 16,
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 56,
  },
  last: {
    borderBottomWidth: 0,
  },

  termsRow: {
    alignItems: 'flex-start',
    backgroundColor: '#f2f2f2',
    paddingHorizontal: 16,
    paddingVertical: 16,
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
  },
  footerView: {
    borderTopWidth: 1,
    borderTopColor: '#dcdcdc',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingTop: 0,
    marginBottom: 0,
  },
  footerButton: {
    flex: 1,
    borderRadius: 0,
    minHeight: 68,
    justifyContent: 'center',
  },

  modalHeaderView: {
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 16,
  },
  closeButton: {
    position: 'absolute',
    left: 0,
  },
});
