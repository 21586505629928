import React, {useState, useEffect, useLayoutEffect} from 'react';
import {SafeAreaView, View, ActivityIndicator} from 'react-native';
import moment from 'moment';
import _ from 'lodash';

import globalStyles from '../../../styles';
import styles from './styles';
import {
  Text,
  PrimaryButton,
  Link,
  FormSheetModal,
  AddressText,
  LinearProgress,
  Pressable,
  Checkbox,
  DownloadLink,
} from '../../../components/controls';
import {booking as bookingApi, car as carApi} from '../../../api/public';
import config from '../../../configuration';
import {useDispatch, useSelector} from '../../../lib/hooks';
import Format from '../../../lib/format';
import {EXTRAS_CODES} from '../../../types/service-codes';
import {addJobReset} from '../../../actions';
import {PublicContentView} from '../../../components/shared';

import {Car, User, Type, Extras, Date as AddDate, Time} from '../index.js';
import OrderStatus from '../../../types/OrderStatus';

const Confirm = ({navigation}) => {
  const dispatch = useDispatch();
  const dealer = useSelector((state) => state.dealer);
  const firstName = useSelector((state) => state.firstName);
  const lastName = useSelector((state) => state.lastName);
  const email = useSelector((state) => state.email);
  const phone_number = useSelector((state) => state.phone_number);
  const date = useSelector((state) => state.date);
  const license = useSelector((state) => state.license);
  const mileage = useSelector((state) => state.mileage);
  const services = useSelector((state) => state.services);
  const extra = useSelector((state) => state.extra);
  const waiting = useSelector((state) => state.waiting);
  const wants_electric_bike = useSelector((state) => state.wants_electric_bike);
  const pick_up_location = useSelector((state) => state.pick_up_location);
  const is_company_car = useSelector((state) => state.is_company_car);
  const company_name = useSelector((state) => state.company_name);
  const is_lease = useSelector((state) => state.is_lease);
  const lease = useSelector((state) => state.lease);
  const storedPackage = useSelector((state) => state.package);
  const ref = useSelector((state) => state.ref);

  const [car, setCar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(null);
  const [termsAgreed, setTermsAgreed] = useState(true);
  const [skipExtras, setSkipExtras] = useState(false);

  let needs_quote = false;

  if (dealer.has_quote) {
    needs_quote =
      is_lease ||
      !services?.length ||
      services?.some((service) => {
        if (storedPackage?.service_id === service.id) {
          return !_.isNumber(storedPackage.amount);
        }

        return !_.isNumber(service.price);
      });
  }

  const due =
    !needs_quote &&
    services?.reduce((acc, service) => acc + service.price, 0) +
      (storedPackage?.amount || 0);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: () => (
        <View style={{alignItems: 'center'}}>
          <LinearProgress style={{width: 126}} step={7} total={7} />
        </View>
      ),
      headerRight: () => <View />,
    });
  }, [navigation]);

  useEffect(() => {
    const fetch = async () => {
      if (license) {
        const car = await carApi.fetch(license);
        setCar(car);
      }

      const extras = await bookingApi.extras(dealer.id);
      if (Object.values(extras).flat().length <= 1) {
        setSkipExtras(true);
      }

      setLoading(false);
    };

    fetch();
  }, [license, dealer.id]);

  const onConfirm = async () => {
    const result = await bookingApi.new(dealer.id, {
      company_name: is_company_car ? company_name : null,
      lease: is_lease ? lease : null,
      firstName,
      lastName,
      email,
      phone_number,
      date,
      license,
      mileage,
      services: services.map((service) => service.id),
      package: storedPackage
        ? {
            service_id: storedPackage.service_id,
            service_code: storedPackage.service_code,
            reference: storedPackage.reference,
          }
        : null,
      extra,
      pickup_address: pick_up_location?.pick_up || null,
      deliver_address:
        (pick_up_location?.deliver_different_address &&
          pick_up_location?.deliver) ||
        null,
      will_wait: waiting,
      wants_electric_bike,
      ref,
    });

    if (result.success) {
      if (!config.development) {
        dispatch(addJobReset());
      }

      if (
        dealer.has_payment &&
        (result.status === OrderStatus.Confirmed ||
          result.status === OrderStatus.Active)
      ) {
        navigation.replace('Pay', {
          order_id: result.order_id,
        });
      } else {
        navigation.replace('Overview', {
          screen: 'Chat',
          params: {
            order_id: result.order_id,
          },
        });
      }
    }
  };

  const onDismissModal = () => setModal(null);

  const isValid =
    ref ||
    ((!is_company_car || company_name?.length) &&
      (!is_lease || lease?.length) &&
      firstName?.length &&
      lastName?.length &&
      phone_number.length &&
      email.length);

  return (
    <>
      <SafeAreaView style={globalStyles.mainView}>
        {loading && <ActivityIndicator size="large" color="#231fda" />}
        {!loading && (
          <>
            <PublicContentView footerStyle={{borderTopWidth: 0}}>
              {{
                content: (
                  <>
                    <View style={globalStyles.headerView}>
                      <Text style={globalStyles.headerText}>
                        {needs_quote ? 'Aanvraag' : 'Opdracht'} controleren
                      </Text>
                      <Text style={{marginTop: 10, fontSize: 18}}>
                        {needs_quote
                          ? 'Je aanvraag kan je hier checken, eventueel wijzigen en als alles juist is verzenden.'
                          : 'Je kunt de opdracht hier nog checken, wijzigen en als alles juist is bevestigen.'}
                      </Text>
                    </View>
                    <View
                      style={[globalStyles.contentView, styles.contentView]}>
                      <View style={styles.article}>
                        <View style={styles.articleHeader}>
                          <Text style={globalStyles.mediumPlus}>Afspraak</Text>
                        </View>
                        <View style={styles.row}>
                          <Text>Datum</Text>
                          <Link
                            onPress={() =>
                              setModal(<AddDate onDismiss={onDismissModal} />)
                            }>
                            {moment(date).format('ddd D MMMM YYYY')}
                          </Link>
                        </View>
                        <View style={[styles.row, styles.last]}>
                          <Text>Aflevertijd</Text>
                          <Link
                            onPress={() =>
                              setModal(<Time onDismiss={onDismissModal} />)
                            }>
                            {moment(date).format('H:mm')}
                          </Link>
                        </View>
                      </View>
                      <View style={styles.article}>
                        <View style={styles.articleHeader}>
                          <Text style={globalStyles.mediumPlus}>Auto</Text>
                          {!ref && (
                            <Link
                              onPress={() =>
                                setModal(
                                  <Car
                                    route={{params: {dealer_id: dealer?.id}}}
                                    onDismiss={onDismissModal}
                                  />,
                                )
                              }>
                              Wijzigen
                            </Link>
                          )}
                        </View>
                        <View style={styles.row}>
                          <Text>{car?.name}</Text>
                          <Text>{Format.license(license)}</Text>
                        </View>
                        {!ref && (
                          <>
                            <View style={styles.row}>
                              <Text>Kilometerstand</Text>
                              <Text>
                                {_.isNumber(mileage)
                                  ? Format.number(mileage, 0, true)
                                  : 'Niet opgegeven'}
                              </Text>
                            </View>
                            <View style={styles.row}>
                              <Text>{is_lease ? 'Contract' : 'Eigendom'}</Text>
                              <Text>
                                {is_company_car ? 'Zakelijk' : 'Particulier'}
                              </Text>
                            </View>
                            {is_lease && (
                              <View style={styles.row}>
                                <Text>Leasemaatschappij</Text>
                                <Text>{lease}</Text>
                              </View>
                            )}
                          </>
                        )}
                      </View>
                      {!ref && (
                        <View style={styles.article}>
                          <View style={styles.articleHeader}>
                            <Text style={globalStyles.mediumPlus}>
                              Contactgegevens
                            </Text>
                            <Link
                              onPress={() =>
                                setModal(<User onDismiss={onDismissModal} />)
                              }>
                              Wijzigen
                            </Link>
                          </View>
                          {is_company_car && (
                            <View style={styles.row}>
                              <Text>Bedrijfsnaam</Text>
                              <Text>{company_name}</Text>
                            </View>
                          )}
                          <View style={styles.row}>
                            <Text>Voornaam</Text>
                            <Text>{firstName}</Text>
                          </View>
                          <View style={styles.row}>
                            <Text>Achternaam</Text>
                            <Text>{lastName}</Text>
                          </View>
                          <View style={styles.row}>
                            <Text>Mobiele telefoonnummer</Text>
                            <Text>{phone_number}</Text>
                          </View>
                          <View style={styles.row}>
                            <Text>Emailadres</Text>
                            <Text>{email}</Text>
                          </View>
                        </View>
                      )}
                      <View style={styles.article}>
                        <View style={styles.articleHeader}>
                          <Text style={globalStyles.mediumPlus}>Opdracht</Text>
                          <Link
                            onPress={() =>
                              setModal(<Type onDismiss={onDismissModal} />)
                            }>
                            Wijzigen
                          </Link>
                        </View>
                        {services
                          ?.sort((a, b) => {
                            return !_.isNumber(a.sort_number)
                              ? 1
                              : !_.isNumber(b.sort_number)
                              ? -1
                              : a.sort_number - b.sort_number;
                          })
                          .map((service, index, array) => (
                            <View
                              key={service.id}
                              style={[
                                styles.row,
                                index === array.length - 1 && styles.last,
                              ]}>
                              <View>
                                <Text>{service.title}</Text>
                                {(storedPackage?.service_code ===
                                  service.service_code ||
                                  !_.isNumber(service.price)) && (
                                  <Link
                                    onPress={() =>
                                      setModal(
                                        <Type onDismiss={onDismissModal} />,
                                      )
                                    }
                                    linkStyle={[
                                      globalStyles.smallRegular,
                                      {
                                        textDecorationLine: 'underline',
                                        color: '#4a4a49',
                                      },
                                    ]}>
                                    {storedPackage?.service_code ===
                                    service.service_code
                                      ? storedPackage.description
                                      : 'Offerte'}
                                  </Link>
                                )}
                              </View>
                              {!is_lease && (
                                <Text>
                                  {storedPackage &&
                                  storedPackage.service_id === service.id
                                    ? Format.price(storedPackage.amount)
                                    : _.isNumber(service.price)
                                    ? Format.price(service.price)
                                    : 'Prijs volgt'}
                                </Text>
                              )}
                            </View>
                          ))}
                      </View>
                      {!skipExtras && (
                        <View style={styles.article}>
                          <View style={styles.articleHeader}>
                            <Text style={globalStyles.mediumPlus}>
                              Vervangend vervoer
                            </Text>
                            <Link
                              onPress={() =>
                                setModal(<Extras onDismiss={onDismissModal} />)
                              }>
                              Wijzigen
                            </Link>
                          </View>
                          {extra === EXTRAS_CODES.EXTRA_NONE && (
                            <>
                              <View style={styles.row}>
                                <Text>Geen vervangend vervoer</Text>
                                <Text>{waiting ? 'Wachten' : ''}</Text>
                              </View>
                            </>
                          )}
                          {extra === EXTRAS_CODES.BIKE && (
                            <View style={styles.row}>
                              <Text>
                                {wants_electric_bike
                                  ? 'Elektrische fiets'
                                  : 'Leenfiets'}
                              </Text>
                            </View>
                          )}
                          {extra === EXTRAS_CODES.REPLACE_VEHICLE && (
                            <View style={styles.row}>
                              <Text>Huurauto</Text>
                            </View>
                          )}
                          {extra === EXTRAS_CODES.PICK_UP && (
                            <>
                              <View style={styles.row}>
                                <Text>Haal en brengservice</Text>
                              </View>
                              <View style={styles.row}>
                                <Text>Ophaallocatie</Text>
                                <AddressText
                                  style={{textAlign: 'right'}}
                                  address={pick_up_location?.pick_up}
                                />
                              </View>

                              <View style={styles.row}>
                                <Text>Afleverlocatie</Text>
                                {pick_up_location?.deliver_different_address ? (
                                  <AddressText
                                    style={{textAlign: 'right'}}
                                    address={pick_up_location?.deliver}
                                  />
                                ) : (
                                  <Text>Hetzelfde als ophaallocatie</Text>
                                )}
                              </View>
                            </>
                          )}
                        </View>
                      )}
                    </View>
                  </>
                ),
                footer: (
                  <View
                    style={[
                      globalStyles.footerView,
                      styles.footerView,
                      {
                        flexDirection: 'column',
                      },
                    ]}>
                    {!needs_quote && (
                      <>
                        <View style={[{alignSelf: 'stretch'}, styles.row]}>
                          <Text style={globalStyles.mediumPlus}>
                            Openstaande bedrag
                          </Text>
                          <Text style={globalStyles.mediumPlus}>
                            {Format.price(due)}
                          </Text>
                        </View>
                        <Pressable
                          style={[styles.row, styles.termsRow]}
                          onPress={() => setTermsAgreed(!termsAgreed)}>
                          <View>
                            <Checkbox
                              style={{width: 24, height: 24, marginRight: 15}}
                              checked={termsAgreed}
                            />
                          </View>
                          <View style={{flex: 1}}>
                            <Text>
                              Ik ga akkoord met de op mijn opdracht van
                              toepassing zijnde (algemene)
                              <DownloadLink
                                href={dealer?.urls.terms_and_conditions}>
                                {' '}
                                voorwaarden{' '}
                              </DownloadLink>
                              van {dealer?.name}
                            </Text>
                          </View>
                        </Pressable>
                      </>
                    )}
                    <View style={{padding: 16}}>
                      <PrimaryButton
                        style={styles.footerButton}
                        disabled={!isValid}
                        clickGuardTimeout={5 * 60 * 1000}
                        onPress={onConfirm}>
                        {needs_quote
                          ? 'Aanvraag verzenden'
                          : 'Opdracht bevestigen'}
                      </PrimaryButton>
                    </View>
                  </View>
                ),
              }}
            </PublicContentView>
          </>
        )}
      </SafeAreaView>

      <FormSheetModal visible={!!modal}>
        <View>{modal}</View>
      </FormSheetModal>
    </>
  );
};

export default Confirm;
