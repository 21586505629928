import React from 'react';
import {View} from 'react-native';

import styles from './styles';

const Success = () => (
  <View style={styles.checkmark}>
    <View style={styles.vector1} />
    <View style={styles.vector2} />
  </View>
);

export default Success;
