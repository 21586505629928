export default {
  buttonView: {
    borderRadius: 8,
    alignItems: 'center',
  },
  transparentButton: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#dcdcdc',
    height: 36,
    borderRadius: 4,
    justifyContent: 'center',
  },
};
