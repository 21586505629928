import {StyleSheet} from 'react-native';

const styles = {
  article: {
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
    paddingHorizontal: 20,
    paddingVertical: 15,
    minHeight: 56,
  },
  noPadding: {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  row: {
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    paddingHorizontal: 20,
    paddingVertical: 15,
    minHeight: 56,
  },
  radioButtonRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 16,
  },
  radioButtonDescription: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 56,
    flex: 1,
    marginLeft: 32,
    paddingRight: 16,
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
  },
  last: {
    borderBottomWidth: 0,
  },
  label: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 10,
  },
};

export default StyleSheet.create(styles);
