import React, {useEffect, useState} from 'react';
import {SafeAreaView, View} from 'react-native';
import {CommonActions} from '@react-navigation/routers';
import _ from 'lodash';

import styles from './styles';
import globalStyles from '../../../styles';
import {Success, PublicContentView} from '../../../components/shared';
import {order as orderApi} from '../../../api/public';
import {Text, DefaultButton} from '../../../components/controls';

const Thanks = (props) => {
  const {route, navigation, title} = props;
  const order_id = route.params?.order_id;
  const [order, setOrder] = useState({});

  useEffect(() => {
    if (!order_id) {
      return;
    }

    const fetch = async () => {
      const order = await orderApi.details(order_id);
      setOrder(order);
    };

    fetch();
  }, [order_id]);

  const description = _.isFunction(props.description)
    ? props.description({order})
    : props.description;

  return (
    <SafeAreaView style={globalStyles.mainView}>
      <PublicContentView>
        <View style={[globalStyles.headerView, styles.headerView]}>
          <Text style={globalStyles.headerText}>{title}</Text>
        </View>
        <View style={[globalStyles.contentView, styles.contentView]}>
          <Text
            style={[globalStyles.regular, {fontSize: 18, marginBottom: 16}]}>
            {description}
          </Text>
          <View
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Success />
          </View>
        </View>
        <View style={[globalStyles.footerView, {alignItems: 'stretch'}]}>
          <DefaultButton
            style={[globalStyles.transparentButton, {flex: 1}]}
            textStyle={{
              color: '#231fda',
              textTransform: 'uppercase',
              fontSize: 14,
            }}
            onPress={() =>
              navigation.dispatch(
                CommonActions.reset({
                  index: 1,
                  routes: [
                    {
                      name: 'Overview',
                      params: {screen: 'Index', params: {order_id}},
                    },
                    {
                      name: 'Overview',
                      params: {screen: 'Chat', params: {order_id}},
                    },
                  ],
                }),
              )
            }>
            <Text>Sluiten</Text>
          </DefaultButton>
        </View>
      </PublicContentView>
    </SafeAreaView>
  );
};

export default Thanks;
