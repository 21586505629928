import {StyleSheet} from 'react-native';

const styles = {
  headerView: {},
  contentView: {padding: 0},
  article: {
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    marginBottom: -1,
  },
  icon: {
    width: 24,
    height: 24,
  },
  radioButtonRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 16,
  },
  radioButtonDescription: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 56,
    flex: 1,
    marginLeft: 32,
    paddingRight: 16,
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    marginBottom: -1,
  },
  footerView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 16,
    marginBottom: 0,
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
  },
  footerRow: {
    flexDirection: 'row',
  },
};

export default StyleSheet.create(styles);
