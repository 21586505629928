import React, {useLayoutEffect, useState, useEffect} from 'react';
import {
  SafeAreaView,
  View,
  useWindowDimensions,
  ScrollView,
  ActivityIndicator,
} from 'react-native';
import moment from 'moment';

import globalStyles from '../../../styles';
import styles from './styles';
import {
  Calendar,
  Text,
  PrimaryButton,
  LinearProgress,
  CloseButton,
  Dialog,
} from '../../../components/controls';
import {useDispatch, useSelector} from '../../../lib/hooks';
import {addJobSetDate} from '../../../actions';
import {PublicContentView} from '../../../components/shared';
import {dealer as dealerApi} from '../../../api/public';
import fontSizes from '../../../styles/font-sizes';

const weekdays = ['M', 'D', 'W', 'D', 'V', 'Z', 'Z'];

const SCROLLBAR_WIDTH = 17;
const MONTHS_IN_YEAR = 12;

const AddDate = ({navigation, onDismiss}) => {
  const dimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const dealer = useSelector((state) => state.dealer);
  const date = useSelector((state) => state.date);
  const services = useSelector((state) => state.services);
  const [loading, setLoading] = useState(true);
  const [availability, setAvailability] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const initialMonths = [
    ...Array(dealer?.availability_window?.months + 1 || MONTHS_IN_YEAR).keys(),
  ].map((value, index) => moment().startOf('month').add(index, 'month'));
  const months = useState(initialMonths)[0];

  useLayoutEffect(() => {
    navigation?.setOptions({
      headerTitle: () => (
        <View style={{alignItems: 'center'}}>
          <LinearProgress style={{width: 126}} step={4} total={7} />
        </View>
      ),
      headerRight: () => <View />,
    });
  }, [navigation]);

  useEffect(() => {
    const fetch = async () => {
      const availability = await dealerApi.availability(
        dealer.id,
        services,
        dealer?.availability_window?.months || MONTHS_IN_YEAR,
      );
      setAvailability(availability);
      setLoading(false);
    };

    fetch();
  }, [dealer, services]);

  const onDateChange = (date) => {
    const time = moment(dealer.begin_checkin, 'HH:mm:ss');
    date.hours(time.hours());
    date.minutes(time.minutes());
    dispatch(addJobSetDate(date));
  };

  const onDateClear = () => {
    dispatch(addJobSetDate(null));
  };

  const onNext = async () => {
    if (onDismiss) {
      onDismiss();
    } else {
      navigation.navigate('Time');
    }
  };

  const onShowAlert = async () => {
    setShowAlert(true);
  };

  const isValid = !!date;

  const width =
    (dimensions.width > 1024 ? 414 : dimensions.width) - SCROLLBAR_WIDTH;
  const cellWidth = Math.floor(width / 7);

  const content = (
    <View style={[globalStyles.contentView, styles.contentView]}>
      {!loading &&
        months.map((item) => (
          <Calendar
            key={`${moment(item).year()}-${moment(item).month()}`}
            cellWidth={cellWidth}
            month={item}
            date={date && new Date(date)}
            advance_notice={dealer.advance_notice}
            availability={availability}
            onPress={onDateChange}
            onShowAlert={onShowAlert}
            onClear={onDateClear}
          />
        ))}
    </View>
  );

  const footer = (
    <View style={globalStyles.footerView}>
      <PrimaryButton disabled={!isValid} onPress={onNext}>
        {onDismiss ? 'Opslaan' : 'Volgende'}
      </PrimaryButton>
    </View>
  );

  return (
    <SafeAreaView style={globalStyles.mainView}>
      <View style={[globalStyles.headerView, styles.headerView]}>
        {!onDismiss ? (
          <View
            style={{
              paddingBottom: 24,
              borderBottomWidth: 1,
              borderBottomColor: '#dcdcdc',
            }}>
            <Text style={[globalStyles.headerText, styles.headerText]}>
              Datum afspraak
            </Text>
            <Text style={{paddingLeft: 16, marginTop: 10, fontSize: 18}}>
              Welke dag komt het uit dat je auto naar de werkplaats gaat?
            </Text>
          </View>
        ) : (
          <View style={globalStyles.modalHeaderView}>
            <CloseButton
              style={{position: 'absolute', top: 8, left: 8}}
              onPress={onDismiss}
            />
            <Text style={globalStyles.medium}>Datum afspraak</Text>
          </View>
        )}
        <>
          {loading && (
            <View>
              <ActivityIndicator size="large" color="#231fda" />
            </View>
          )}
          {!loading && (
            <View style={[styles.weekdays, {height: cellWidth}]}>
              {weekdays.map((weekday, index) => (
                <View
                  key={index}
                  style={[
                    styles.weekdayView,
                    {
                      left: index * cellWidth,
                      width: cellWidth,
                      height: cellWidth,
                    },
                  ]}>
                  <View style={styles.weekdayText}>
                    <Text
                      style={{
                        color: '#828282',
                        fontSize: 13,
                        fontFamily: 'Roboto_700Bold',
                      }}>
                      {weekday}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          )}
        </>
      </View>
      {onDismiss ? (
        <>
          <ScrollView>{content}</ScrollView>
          <View style={{borderTopWidth: 1, borderTopColor: '#dcdcdc'}}>
            {footer}
          </View>
        </>
      ) : (
        <PublicContentView>
          {{
            content,
            footer,
          }}
        </PublicContentView>
      )}
      {showAlert && (
        <Dialog
          visible={true}
          onDismiss={() => setShowAlert(false)}
          buttons={[{text: 'OK', onPress: () => setShowAlert(false)}]}>
          <View>
            <Text>
              De gekozen datum is voor ons een drukke dag. We kunnen hierdoor
              geen plek garanderen.
            </Text>
          </View>
          <View style={{marginTop: '1em'}}>
            <Text style={fontSizes.mediumPlus}>
              Voor spoedreparaties en overige acute zaken hebben wij altijd
              plek.
            </Text>
          </View>
          <View style={{marginTop: '1em'}}>
            <Text>
              Neem contact met ons op door te bellen naar {dealer.phone_number}.
            </Text>
          </View>
        </Dialog>
      )}
    </SafeAreaView>
  );
};

export default AddDate;
