import endpoints from './endpoints';
import {fetch} from '../../lib/fetch';

export default {
  fetch: async (order_id) => {
    const result = await fetch(`${endpoints.request}/${order_id}`);

    if (!result) {
      return null;
    }

    const json = await result.json();
    return json;
  },
};
