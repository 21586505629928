import React from 'react';

import {styles} from './styles';
import Text from '../Text';

const DownloadLink = ({href, style, linkStyle, children}) => {
  return (
    <a
      href={href}
      style={{...styles.anchor, ...style}}
      target="_blank"
      rel="noreferrer"
      onClick={(e) => {
        e.stopPropagation();
      }}>
      <Text style={{...styles.link, ...linkStyle}}>{children}</Text>
    </a>
  );
};

export default DownloadLink;
