import React, {
  useEffect,
  useState,
  useLayoutEffect,
  useContext,
  useRef,
} from 'react';
import {SafeAreaView, View, ScrollView} from 'react-native';
import _ from 'lodash';
import {Portal} from 'react-native-paper';

import globalStyles from '../../../styles';
import styles from './styles';
import {
  Text,
  Pressable,
  Checkbox,
  PrimaryButton,
  LinearProgress,
  CloseButton,
  Dialog,
  RadioButton,
  Link,
} from '../../../components/controls';
import {useDispatch, useSelector} from '../../../lib/hooks';
import {
  addJobSetPackage,
  addJobSetServices,
  addJobSetPackageIsChosen,
  addJobSetCarState,
} from '../../../actions';
import {Categories} from '../../../types/service-codes';
import {dealer as dealerApi, car as carApi} from '../../../api/public';
import PublicContentView from '../../../components/shared/PublicContentView';
import ExpandMoreIcon from '../../../images/md-icons/expand_more/materialicons/24px.svg';
import ExpandLessIcon from '../../../images/md-icons/expand_less/materialicons/24px.svg';
import Format from '../../../lib/format';
import PublicContentContext from '../../../components/context/PublicContentContext';
import {ActivityIndicator} from 'react-native-web';

const ServiceRow = ({
  is_lease,
  id,
  service_code,
  title,
  price,
  related,
  packages,
  is_last,
  checked,
  selectedPackage,
  hasScrolledIntoView,
  onCheckedChange,
  onSelectPackage,
  onScrolledIntoView,
}) => {
  const rowRef = useRef();
  const {scrollRef} = useContext(PublicContentContext);
  const package_is_chosen = useSelector((state) => state.package_is_chosen);

  const is_checked = checked.includes(service_code);
  const combo = related?.find((item) =>
    checked.includes(item.related_service_code),
  );

  const display_price = combo
    ? combo.price
    : selectedPackage?.service_id === id
    ? selectedPackage.amount
    : price;
  const is_excluded = combo?.exclude;
  if (is_excluded && is_checked) {
    // Reset the state of this impossible combination.
    onCheckedChange(service_code);
  }

  const _onSelectPackage = () => {
    onSelectPackage({title, service_code, service_id: id, packages});
  };

  const onLayout = async (e) => {
    if (
      !hasScrolledIntoView &&
      checked.length === 1 &&
      is_checked &&
      scrollRef
    ) {
      const TOP_BAR_HEIGHT = 64;
      const windowHeight = window?.innerHeight - TOP_BAR_HEIGHT;
      const {layout} = e.nativeEvent;

      if (layout.top + layout.height > windowHeight) {
        const scrollY = layout.top - windowHeight + layout.height;
        scrollRef.current.scrollTo({
          x: 0,
          y: scrollY,
        });
      }

      onScrolledIntoView(true);
    }
  };

  return (
    <View style={styles.row} ref={rowRef} onLayout={onLayout}>
      <Checkbox
        disabled={is_excluded}
        checked={is_checked}
        onPress={() => onCheckedChange(service_code)}
      />
      <View style={[styles.serviceDescription, is_last && styles.last]}>
        <View>
          <Text style={is_excluded && {color: '#828282'}}>{title}</Text>
          {is_checked && packages && (
            <Link
              onPress={_onSelectPackage}
              linkStyle={[
                globalStyles.small,
                {color: '#4a4a49', textDecorationLine: 'underline'},
              ]}>
              {!package_is_chosen
                ? 'Keuze maken'
                : selectedPackage &&
                  selectedPackage.service_code === service_code
                ? selectedPackage.description
                : 'Offerte'}
            </Link>
          )}
        </View>
        {!is_lease &&
          (is_checked || combo) &&
          !is_excluded &&
          (!packages || package_is_chosen) && (
            <Text style={[globalStyles.smallRegular, {color: '#828282'}]}>
              {_.isNumber(display_price)
                ? Format.price(display_price)
                : 'Prijs volgt'}
            </Text>
          )}
      </View>
    </View>
  );
};

const CollapsableCategory = ({
  title,
  titleStyle = globalStyles.mediumPlus,
  children,
  defaultExpand = false,
}) => {
  const [expand, setExpand] = useState(defaultExpand);

  return (
    <View>
      <Pressable style={styles.articleTitle} onPress={() => setExpand(!expand)}>
        <Text style={titleStyle}>{title}</Text>
        {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Pressable>
      {expand && children}
    </View>
  );
};

const Type = ({navigation, onDismiss}) => {
  const dispatch = useDispatch();
  const dealer = useSelector((state) => state.dealer);
  const license = useSelector((state) => state.license);
  const mileage = useSelector((state) => state.mileage);
  const is_lease = useSelector((state) => state.is_lease);
  const storedServices = useSelector((state) => state.services);
  const storedPackage = useSelector((state) => state.package);
  const package_is_chosen = useSelector((state) => state.package_is_chosen);

  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState([]);
  const [services, setServices] = useState({});
  const [showPackages, setShowPackages] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(
    is_lease ? null : storedPackage,
  );
  const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);

  useLayoutEffect(() => {
    navigation?.setOptions({
      headerTitle: () => (
        <View style={{alignItems: 'center'}}>
          <LinearProgress style={{width: 126}} step={3} total={7} />
        </View>
      ),
      headerRight: () => <View />,
    });
  }, [navigation]);

  useEffect(() => {
    if (storedServices?.length) {
      setChecked(storedServices.map((item) => item.service_code));
    }
  }, [storedServices]);

  useEffect(() => {
    if (!license) {
      return;
    }

    const fetch = async () => {
      const services = await dealerApi.services(
        dealer.id,
        license,
        mileage,
        is_lease,
      );

      const campaign = services['CAMPAIGN'];
      if (campaign && !storedServices) {
        setChecked(campaign.map((item) => item.service_code));
      }

      setServices(services);
      setLoading(false);
    };

    fetch();
  }, [license, dealer, mileage, is_lease, storedServices]);

  const onScrolledIntoView = () => {
    setHasScrolledIntoView(true);
  };

  const onCheckedChange = (service_code) => {
    if (checked.includes(service_code)) {
      if (selectedPackage?.service_code === service_code) {
        setSelectedPackage(null);
      }

      if (package_is_chosen === service_code) {
        dispatch(addJobSetPackageIsChosen(null));
      }

      checked.splice(checked.indexOf(service_code), 1);
    } else {
      checked.push(service_code);
    }

    setChecked(checked.slice(0));
  };

  const onSelectPackage = (service) => {
    setShowPackages(service);
  };

  const onNext = async () => {
    const all_services = Object.values(services).flatMap((items) => items);
    if (!package_is_chosen) {
      const service = all_services.find(
        (item) => checked.includes(item.service_code) && item.packages,
      );

      if (service) {
        const {title, service_code, id, packages} = service;
        setShowPackages({
          title,
          service_code,
          service_id: id,
          packages,
        });

        return;
      }
    }

    const selectedServices = all_services
      .filter((item) => checked.includes(item.service_code))
      .map(({id, service_code, title, price, related, sort_number}) => {
        const combo = related
          .sort((a, b) => a.order - b.order)
          .find((item) => checked.includes(item.related_service_code));

        return {
          id,
          service_code,
          title,
          price: combo ? combo.price : price,
          sort_number,
        };
      });

    dispatch(addJobSetServices(selectedServices));
    dispatch(addJobSetPackage(selectedPackage));

    if (onDismiss) {
      onDismiss();
    } else {
      const carState = await carApi.state(license, mileage);
      if (carState?.length) {
        dispatch(addJobSetCarState(carState));
        navigation.navigate('More');
      } else {
        navigation.navigate('Date');
      }
    }
  };

  let {POPULAR: popular, CAMPAIGN: campaign, ...rest} = services;
  let sticky = {
    title: Categories['POPULAR'],
    services: popular,
  };

  if (campaign) {
    sticky = {
      title: `Aanbevolen door ${dealer?.name}`,
      services: campaign,
    };

    rest = {
      POPULAR: popular,
      ...rest,
    };
  }

  const content = (
    <>
      {loading && <ActivityIndicator size="large" color="#231fda" />}
      {!loading && (
        <>
          {!onDismiss ? (
            <>
              <View style={[globalStyles.headerView, styles.headerView]}>
                <Text style={globalStyles.headerText}>
                  {campaign ? 'Ons advies' : 'Waar ben je naar op zoek?'}
                </Text>
              </View>

              {campaign && (
                <View
                  style={{
                    paddingHorizontal: 16,
                    paddingVertical: 16,
                  }}>
                  <Text>
                    Wij helpen graag! Op basis van de historie van de auto geven
                    wij advies over de uit te voeren werkzaamheden.
                  </Text>
                </View>
              )}
            </>
          ) : (
            <View style={globalStyles.modalHeaderView}>
              <CloseButton
                style={{position: 'absolute', top: 8, left: 8}}
                onPress={onDismiss}
              />
              <Text style={globalStyles.medium}>Opdracht</Text>
            </View>
          )}
          <View style={[globalStyles.contentView, styles.contentView]}>
            {!loading && (
              <>
                <View>
                  <View style={styles.articleTitle}>
                    <Text style={globalStyles.mediumPlus}>{sticky.title}</Text>
                  </View>
                  {sticky.services
                    ?.map((service, index, array) => (
                      <ServiceRow
                        key={service.id}
                        is_lease={is_lease}
                        id={service.id}
                        service_code={service.service_code}
                        title={service.title}
                        price={service.price}
                        related={service.related}
                        packages={service.packages}
                        is_last={index === array.length - 1}
                        checked={checked}
                        selectedPackage={selectedPackage}
                        hasScrolledIntoView={hasScrolledIntoView}
                        onCheckedChange={onCheckedChange}
                        onSelectPackage={onSelectPackage}
                        onScrolledIntoView={onScrolledIntoView}
                      />
                    ))
                    .filter(Boolean)}
                </View>
                {campaign && (
                  <View style={styles.articleTitle}>
                    <Text style={globalStyles.mediumPlus}>
                      Wil je daar nog iets aan toevoegen?
                    </Text>
                  </View>
                )}
                {Object.keys(rest).map((category) => (
                  <CollapsableCategory
                    key={category}
                    title={Categories[category] || category}
                    titleStyle={
                      campaign ? globalStyles.regular : globalStyles.mediumPlus
                    }
                    defaultExpand={rest[category]?.some((service) =>
                      checked.includes(service.service_code),
                    )}>
                    {rest[category]?.map((service, index, array) => (
                      <ServiceRow
                        key={service.id}
                        is_lease={is_lease}
                        id={service.id}
                        service_code={service.service_code}
                        title={service.title}
                        price={service.price}
                        related={service.related}
                        packages={service.packages}
                        is_last={index === array.length - 1}
                        checked={checked}
                        selectedPackage={selectedPackage}
                        hasScrolledIntoView={hasScrolledIntoView}
                        onCheckedChange={onCheckedChange}
                        onSelectPackage={onSelectPackage}
                        onScrolledIntoView={onScrolledIntoView}
                      />
                    ))}
                  </CollapsableCategory>
                ))}
              </>
            )}
            {!onDismiss && (
              <View
                style={{
                  paddingHorizontal: 16,
                  paddingVertical: 16,
                }}>
                <Text style={globalStyles.mediumPlus}>
                  Meer info doorgeven of niet gevonden?
                </Text>
                <Text>
                  Aan het einde van het proces kom je in de chat terecht. Daar
                  kun je meer informatie en/of andere wensen aan ons doorgeven.
                </Text>
              </View>
            )}
          </View>
          {showPackages && (
            <Dialog
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                marginLeft: 0,
                marginRight: 0,
                borderRadius: 0,
              }}
              title={showPackages.title}
              visible={true}
              dismissable={false}
              buttons={[
                {
                  text: 'OK',
                  onPress: () => {
                    dispatch(
                      addJobSetPackageIsChosen(showPackages.service_code),
                    );
                    setShowPackages(null);
                  },
                },
              ]}>
              {showPackages.packages.map((item, index) => (
                <Pressable
                  key={index}
                  style={{
                    flexDirection: 'row',
                    minHeight: 48,
                    alignItems: 'center',
                  }}
                  onPress={() => {
                    setSelectedPackage({
                      ...item,
                      service_id: showPackages.service_id,
                    });
                  }}>
                  <RadioButton
                    style={{marginRight: 32}}
                    checked={selectedPackage?.reference === item.reference}
                  />
                  <View
                    style={{
                      flexGrow: 1,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      flex: 1,
                    }}>
                    <Text>{item.description}</Text>
                    <Text style={[globalStyles.smallRegular, {lineHeight: 24}]}>
                      {Format.price(item.amount)}
                    </Text>
                  </View>
                </Pressable>
              ))}
              <Pressable
                style={{
                  flexDirection: 'row',
                  minHeight: 48,
                  alignItems: 'center',
                }}
                onPress={() => {
                  setSelectedPackage(null);
                }}>
                <RadioButton
                  style={{marginRight: 32}}
                  checked={!selectedPackage}
                />
                <Text>Offerte (adviseer mij)</Text>
              </Pressable>
            </Dialog>
          )}
        </>
      )}
    </>
  );

  const footer = (
    <View
      style={[
        globalStyles.footerView,
        {
          borderTopWidth: 1,
          borderTopColor: '#dcdcdc',
        },
      ]}>
      <PrimaryButton
        disabled={loading}
        disableClickGuard={true}
        onPress={onNext}>
        {onDismiss ? 'Opslaan' : 'Volgende'}
      </PrimaryButton>
    </View>
  );

  return (
    <Portal.Host>
      <SafeAreaView style={globalStyles.mainView}>
        {onDismiss ? (
          <>
            <ScrollView>{content}</ScrollView>
            <View style={{borderTopWidth: 1, borderTopColor: '#dcdcdc'}}>
              {footer}
            </View>
          </>
        ) : (
          <PublicContentView>
            {{
              content,
              footer,
            }}
          </PublicContentView>
        )}
      </SafeAreaView>
    </Portal.Host>
  );
};

export default Type;
