import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  article: {
    borderTopColor: '#dcdcdc',
    borderTopWidth: 1,
    marginTop: -1,
    paddingHorizontal: 16,
    paddingVertical: 15,
    minHeight: 56,
  },
  borderBottom: {
    borderBottomColor: '#dcdcdc',
    borderBottomWidth: 1,
  },
  noPadding: {
    padding: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  input: {
    marginTop: 10,
  },
  inputFirst: {
    marginTop: 10,
  },
  inputLast: {
    marginBottom: 0,
  },
  label: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 10,
  },
});
