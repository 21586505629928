import React from 'react';
import {View, ScrollView} from 'react-native';
import _ from 'lodash';
import moment from 'moment';

import {Text, AddressText} from '../../controls';

import styles from './styles';
import globalStyles from '../../../styles';
import Format from '../../../lib/format';
import Message from '../Message';
import {EXTRAS_CODES} from '../../../types/service-codes';

const Extra = ({extra, order}) => {
  if (!extra) {
    return <></>;
  }

  let description = 'Vervangend vervoer';
  let rows;
  switch (extra.dealer_service_code) {
    case EXTRAS_CODES.PICK_UP:
      description = extra.description;
      rows = [
        {
          description: `Halen · ${moment(order.datetime).format('H:mm')}`,
          address: order.pickup_address,
        },
        order.deliver_address && {
          description: 'Brengen',
          address: order.deliver_address,
        },
      ].filter(Boolean);
      break;
    case EXTRAS_CODES.BIKE:
      rows = [
        {
          description: order?.wants_electric_bike
            ? 'Elektrische fiets'
            : extra?.title,
          amount: extra?.price,
        },
      ];
      break;
    default:
      rows = [
        {
          description: extra?.title,
          amount: extra?.price,
        },
      ];
      break;
  }

  return (
    <>
      <ScrollView>
        <View
          style={[
            styles.row,
            {justifyContent: 'inherit', alignItems: 'center'},
          ]}>
          <Text style={globalStyles.mediumPlus}>{description}</Text>
        </View>
        {rows.map((row, index) => (
          <View key={index} style={styles.row}>
            <View style={{flex: 1}}>
              <Text
                style={[
                  {
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    marginRight: 4,
                  },
                ]}>
                {row.description}
              </Text>
              {row.address && (
                <AddressText
                  style={globalStyles.small}
                  address={row.address}
                  split={false}
                />
              )}
            </View>
            <Text>
              {_.isNumber(row.amount) ? Format.price(row.amount) : ''}
            </Text>
          </View>
        ))}
        {_.isNumber(extra?.price) && (
          <View style={[styles.row, {marginBottom: -1}]}>
            <View>
              <Text style={globalStyles.medium}>Totaal</Text>
              <Text style={globalStyles.small}>Inclusief BTW</Text>
            </View>
            <Text style={globalStyles.medium}>
              {Format.price(extra?.price)}
            </Text>
          </View>
        )}
      </ScrollView>
      {extra.diagnosis && (
        <Message
          style={{backgroundColor: '#f2f2f2'}}
          message_first_name={extra.diagnosis_user_first_name}
          message_last_name={extra.diagnosis_user_last_name}
          message_timestamp={extra?.diagnosis_datetime}
          message={extra.diagnosis}
        />
      )}
    </>
  );
};

export default Extra;
