import React from 'react';
import {View} from 'react-native';
import moment from 'moment';

import globalStyles from '../../../styles';

import {Text, Persona} from '../../controls';
import {PersonaSize} from '../../controls/Persona';
import HtmlView from '../HtmlView';

const Message = ({
  style,
  message_timestamp,
  message_first_name,
  message_last_name,
  message,
}) => {
  let duration;
  if (message_timestamp) {
    const timestamp = moment(message_timestamp);
    duration = timestamp.format('DD-MM-YYYY');
    if (timestamp.isSame(moment(), 'day')) {
      duration = timestamp.format('HH:mm');
    }
  }

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          paddingHorizontal: 16,
          paddingTop: 16,
          paddingBottom: 13,
          height: 88,
          overflow: 'hidden',
        },
        style,
      ]}>
      <Persona
        is_dealer={true}
        style={{alignSelf: 'center'}}
        imageInitials={`${message_first_name[0]}${
          message_last_name ? message_last_name[0] : ''
        }`}
        size={PersonaSize.size40}
      />
      <View
        style={{
          flex: 1,
          paddingLeft: 16,
          overflow: 'hidden',
        }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: 4,
          }}>
          <Text style={[globalStyles.mediumBold, {lineHeight: 16}]}>
            {message_first_name}&nbsp;{message_last_name}
          </Text>
          <Text style={[globalStyles.smallRegular, {lineHeight: 14}]}>
            {duration}
          </Text>
        </View>
        <HtmlView clampLines={2} value={message} />
      </View>
    </View>
  );
};

export default Message;
