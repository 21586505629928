import {StyleSheet} from 'react-native';

const styles = {
  photo: {
    width: '100%',
  },
  contentView: {
    alignItems: 'center',
    paddingHorizontal: 30,
    paddingVertical: 56,
  },
};

export default StyleSheet.create(styles);
