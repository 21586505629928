import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  headerView: {
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
  },
  contentView: {
    marginTop: -1,
  },
  articleTitle: {
    paddingHorizontal: 16,
    paddingVertical: 15,
    borderTopWidth: 1,
    borderTopColor: '#f2f2f2',
    marginTop: -1,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 56,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 16,
  },
  serviceDescription: {
    height: 56,
    flex: 1,
    marginLeft: 10,
    paddingRight: 16,
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#f2f2f2',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  last: {
    borderBottomWidth: 0,
  },
  resultsList: {
    paddingVertical: 19,
  },
  footerView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  underline: {
    textDecorationLine: 'underline',
  },
});
