import React, {useEffect} from 'react';
import {
  Button,
  Divider,
  Dialog as PaperDialog,
  Portal,
} from 'react-native-paper';
import {useNavigation} from '@react-navigation/core';

const Dialog = ({
  style,
  visible,
  dismissable,
  onDismiss,
  title,
  children,
  buttons,
  options = {
    hideDividers: true,
  },
}) => {
  let footer = <Button onPress={onDismiss}>OK</Button>;
  const navigation = useNavigation();

  if (buttons) {
    footer = buttons.map((button, index) => (
      <Button key={index} onPress={button.onPress}>
        {button.text}
      </Button>
    ));
  }

  useEffect(() => {
    navigation?.setOptions({
      cardStyle: {
        backgroundColor: visible ? 'rgba(0,0,0,0.5)' : null,
      },
    });

    return () => {
      navigation?.setOptions({
        cardStyle: {
          backgroundColor: null,
        },
      });
    };
  }, [navigation, visible]);

  return (
    <Portal>
      <PaperDialog
        style={style}
        visible={visible}
        dismissable={dismissable}
        onDismiss={onDismiss}>
        {title && (
          <>
            <PaperDialog.Title>{title}</PaperDialog.Title>
            {!options.hideDividers && <Divider />}
          </>
        )}
        <PaperDialog.Content>{children}</PaperDialog.Content>
        {!options.hideDividers && <Divider />}
        <PaperDialog.Actions>{footer}</PaperDialog.Actions>
      </PaperDialog>
    </Portal>
  );
};

export default Dialog;
