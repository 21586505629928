import {StyleSheet, Platform} from 'react-native';

let extras = {};
if (Platform.OS === 'web') {
  extras = {
    ...extras,
    cursor: 'pointer',
  };
}

export default StyleSheet.create({
  chip: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 12,
    borderRadius: 20,
    marginHorizontal: 5,
    backgroundColor: '#f2f2f2',
    ...extras,
  },
  chipActive: {
    backgroundColor: '#c4c4c4',
  },
  chipIcon: {
    marginRight: 4,
  },
  chipLabel: {
    color: '#828282',
  },
  chipLabelActive: {
    color: '#4a4a49',
  },
});
