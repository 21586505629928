export default {
  mainView: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    padding: 0,
    backgroundColor: '#fff',
    fontFamily: 'Roboto_400Regular',
    fontSize: 14,
  },
};
