import config from '../../configuration';

export const apiRoot = config.publicApiRoot;

const endpoints = {
  dealers: `${apiRoot}/dealers`,
  dealer: `${apiRoot}/dealer`,
  booking: `${apiRoot}/booking`,
  order: `${apiRoot}/order`,
  orders: `${apiRoot}/orders`,
  car: `${apiRoot}/car`,
  diagnosis: `${apiRoot}/diagnosis`,
  request: `${apiRoot}/request`,
  payment: `${apiRoot}/payment`,
  address: `${apiRoot}/address`,

  plans: `${apiRoot}/Plans`,
  services: `${apiRoot}/Services`,
  additionals: `${apiRoot}/Additionals`,
  extras: `${apiRoot}/Extras`,
};

export default endpoints;
