import React, {useState, useEffect} from 'react';
import {SafeAreaView, View, ActivityIndicator} from 'react-native';
import moment from 'moment';
import _ from 'lodash';
import {CommonActions} from '@react-navigation/native';

import globalStyles from '../../../styles';
import styles from './styles';
import {
  PrimaryButton,
  Pressable,
  Checkbox,
  DownloadLink,
  Link,
  Text,
} from '../../../components/controls';
import {
  order as orderApi,
  dealer as dealerApi,
  request as requestApi,
} from '../../../api/public';
import Format from '../../../lib/format';
import {useSelector} from '../../../lib/hooks';
import PublicContentView from '../../../components/shared/PublicContentView';
import ExpandLessIcon from '../../../images/md-icons/expand_less/materialicons/24px.svg';
import ExpandMoreIcon from '../../../images/md-icons/expand_more/materialicons/24px.svg';

const Check = ({route, navigation}) => {
  const order_id = route.params?.order_id;

  const notApproved = useSelector((state) => state.not_approved);
  const [order, setOrder] = useState(null);
  const [dealer, setDealer] = useState(null);
  const [request, setRequest] = useState({});
  const [loading, setLoading] = useState(true);
  const [termsAgreed, setTermsAgreed] = useState(true);
  const [expand, setExpand] = useState(false);
  const [expandPayment, setExpandPayment] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const order = await orderApi.details(order_id);
      const dealer = await dealerApi.details(order.dealer_id);
      const request = await requestApi.fetch(order_id, {is_initial: true});
      setOrder(order);
      setDealer(dealer);
      setRequest(request);

      setLoading(false);
    };

    fetch();
  }, [order_id]);

  const approvals = request?.tasks?.filter(
    (task) => task.is_approved === null && !notApproved?.includes(task.id),
  );
  const total = approvals?.reduce((acc, task) => acc + task.amount, 0);

  const saveApproved = async () => {
    const approved = approvals.reduce((acc, task) => {
      if (!acc[task.request_id]) {
        acc[task.request_id] = [];
      }

      if (!notApproved || !notApproved.includes(task.id)) {
        acc[task.request_id].push(task.id);
      }

      return acc;
    }, {});

    return await orderApi.approve(order_id, approved);
  };

  const onConfirm = async () => {
    const result = await saveApproved();

    if (result.success) {
      navigation.dispatch((state) => {
        let routes = state.routes.slice(0);
        routes[0].name = 'Pay';
        routes.splice(1);

        return CommonActions.reset({
          ...state,
          routes,
          index: 0,
        });
      });
    }
  };

  const onToggleTermsAgreed = () => {
    setTermsAgreed(!termsAgreed);
  };

  const isValid = termsAgreed;

  return (
    <SafeAreaView style={globalStyles.mainView}>
      {loading && <ActivityIndicator size="large" color="#231fda" />}
      {!loading && (
        <PublicContentView>
          {{
            content: (
              <>
                <View style={[globalStyles.headerView, styles.headerView]}>
                  <Text style={globalStyles.headerText}>
                    Opdracht controleren
                  </Text>
                  <Text style={{marginTop: 10, fontSize: 18}}>
                    Je kunt de opdracht hier nog checken, wijzigen en als alles
                    juist is bevestigen.
                  </Text>
                </View>
                <View style={[globalStyles.contentView, styles.contentView]}>
                  <View style={styles.article}>
                    <Pressable
                      style={[styles.article, styles.row]}
                      onPress={() => setExpand(!expand)}>
                      <Text style={globalStyles.mediumPlus}>Afspraak</Text>
                      {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Pressable>
                    {expand && (
                      <>
                        <View style={styles.row}>
                          <Text>{dealer?.name}</Text>
                          <Text>{dealer?.city}</Text>
                        </View>
                        <View style={styles.row}>
                          <Text>{order?.car_name}</Text>
                          <Text>{Format.license(order?.license)}</Text>
                        </View>
                        <View style={styles.row}>
                          <Text>Datum</Text>
                          <Text>
                            {moment(order?.datetime).format('ddd D MMMM YYYY')}
                          </Text>
                        </View>
                        <View style={styles.row}>
                          <Text>Tijd</Text>
                          <Text>{moment(order?.datetime).format('H:mm')}</Text>
                        </View>
                      </>
                    )}
                  </View>
                  <View style={styles.article}>
                    <View style={styles.articleHeader}>
                      <Text style={globalStyles.mediumPlus}>Opdracht</Text>
                      <Link
                        onPress={() =>
                          navigation.navigate('Details', {order_id})
                        }>
                        Wijzigen
                      </Link>
                    </View>

                    {approvals
                      ?.sort((a, b) => {
                        return !_.isNumber(a.sort_number)
                          ? 1
                          : !_.isNumber(b.sort_number)
                          ? -1
                          : a.sort_number - b.sort_number;
                      })
                      .map((task) => (
                        <View key={task.id} style={styles.row}>
                          <Text>{task.description}</Text>
                          <Text>{Format.price(task.amount)}</Text>
                        </View>
                      ))}
                    <View style={[styles.row]}>
                      <View>
                        <Text style={globalStyles.mediumSemi}>Totaal</Text>
                        <Text style={globalStyles.small}>Inclusief BTW</Text>
                      </View>
                      <Text style={globalStyles.mediumSemi}>
                        {Format.price(total)}
                      </Text>
                    </View>
                  </View>

                  <Pressable
                    style={[styles.article, styles.row]}
                    onPress={() => setExpandPayment(!expandPayment)}>
                    <Text style={globalStyles.mediumPlus}>Betaling</Text>
                    {expandPayment ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </Pressable>
                  {expandPayment && (
                    <>
                      <View style={styles.row}>
                        <Text>Voldaan</Text>
                        <Text>
                          {Format.price(
                            request?.order?.total - request?.order?.due,
                          )}
                        </Text>
                      </View>
                      <View style={styles.row}>
                        <Text>Openstaand</Text>
                        <Text>{Format.price(request?.order?.due + total)}</Text>
                      </View>
                    </>
                  )}
                </View>
              </>
            ),
            footer: (
              <View
                style={[
                  globalStyles.footerView,
                  styles.footerView,
                  {
                    flexDirection: 'column',
                  },
                ]}>
                <View style={[{alignSelf: 'stretch'}, styles.row]}>
                  <Text style={globalStyles.mediumPlus}>
                    Openstaande bedrag
                  </Text>
                  <Text style={globalStyles.mediumPlus}>
                    {Format.price(request?.order?.due + total)}
                  </Text>
                </View>
                <Pressable
                  style={[styles.row, styles.termsRow]}
                  onPress={onToggleTermsAgreed}>
                  <View>
                    <Checkbox
                      style={{width: 24, height: 24, marginRight: 15}}
                      checked={termsAgreed}
                    />
                  </View>
                  <View style={{flex: 1}}>
                    <Text>
                      Ik ga akkoord met de op mijn opdracht van toepassing
                      zijnde (algemene)
                      <DownloadLink href={dealer?.urls.terms_and_conditions}>
                        {' '}
                        voorwaarden{' '}
                      </DownloadLink>
                      van {dealer?.name}
                    </Text>
                  </View>
                </Pressable>
                <View style={{padding: 16}}>
                  <PrimaryButton
                    style={styles.footerButton}
                    disabled={!isValid}
                    onPress={onConfirm}>
                    Opdracht bevestigen
                  </PrimaryButton>
                </View>
              </View>
            ),
          }}
        </PublicContentView>
      )}
    </SafeAreaView>
  );
};

export default Check;
