export default {
  headerView: {
    flexGrow: 0,
    marginTop: 0,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
  },
  headerText: {
    fontFamily: 'Roboto_700Bold',
    fontSize: 24,
  },
  modalHeaderView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: 56,
    paddingHorizontal: 8,
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#dcdcdc',
  },
};
