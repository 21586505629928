import React, {useLayoutEffect, useState} from 'react';
import {SafeAreaView, View, ScrollView} from 'react-native';

import globalStyles from '../../../styles';
import styles from './styles';
import {
  PrimaryButton,
  FormTextInput,
  Pressable,
  InfoButton,
  Text,
  LinearProgress,
  CloseButton,
} from '../../../components/controls';
import {
  addJobSetFirstName,
  addJobSetLastName,
  addJobSetEmail,
  addJobSetPhone,
  addJobSetCompanyName,
} from '../../../actions';
import {useDispatch, useSelector} from '../../../lib/hooks';
import PublicContentView from '../../../components/shared/PublicContentView';
import ExpandMoreIcon from '../../../images/md-icons/expand_more/materialicons/24px.svg';
import ExpandLessIcon from '../../../images/md-icons/expand_less/materialicons/24px.svg';

/* eslint-disable no-control-regex */
const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;
const phoneRegex = /^06[0-9]{8}/gi;

const Index = ({navigation, onDismiss}) => {
  const dispatch = useDispatch();
  const stored = useSelector((state) => ({
    firstName: state.firstName,
    lastName: state.lastName,
    phone_number: state.phone_number,
    email: state.email,
    company_name: state.company_name,
  }));

  const is_lease = useSelector((state) => state.is_lease);
  const is_company_car = useSelector((state) => state.is_company_car);

  const [firstName, setFirstName] = useState(stored.firstName);
  const [lastName, setLastName] = useState(stored.lastName);
  const [email, setEmail] = useState(stored.email);
  const [phone_number, setPhoneNumber] = useState(stored.phone_number);
  const [company_name, setCompanyName] = useState(stored.company_name);

  const [emailError, setEmailError] = useState(
    email && !email?.match(emailRegex),
  );
  const [phoneError, setPhoneError] = useState(
    phone_number && !phone_number?.match(phoneRegex),
  );
  const [expand, setExpand] = useState(false);

  useLayoutEffect(() => {
    navigation?.setOptions({
      headerTitle: () => (
        <View style={{alignItems: 'center'}}>
          <LinearProgress style={{width: 126}} step={2} total={7} />
        </View>
      ),
      headerRight: () => <View />,
    });
  }, [navigation]);

  let isValid =
    email?.match(emailRegex) &&
    phone_number?.match(phoneRegex) &&
    !!firstName &&
    !!lastName;

  if (isValid && is_company_car) {
    isValid = !!company_name;
  }

  const onNext = () => {
    if (!isValid) {
      return;
    }

    dispatch(addJobSetCompanyName(company_name));
    dispatch(addJobSetFirstName(firstName));
    dispatch(addJobSetLastName(lastName));
    dispatch(addJobSetPhone(phone_number));
    dispatch(addJobSetEmail(email));

    if (onDismiss) {
      onDismiss();
    } else {
      navigation.navigate('Type');
    }
  };

  const content = (
    <>
      {!onDismiss ? (
        <View style={[globalStyles.headerView]}>
          <Text style={globalStyles.headerText}>Jouw gegevens</Text>
          <Text style={{marginTop: 10, fontSize: 18}}>
            Je naam en hoe kunnen we je bereiken over de opdracht?
          </Text>
        </View>
      ) : (
        <View style={globalStyles.modalHeaderView}>
          <CloseButton
            style={{position: 'absolute', top: 8, left: 8}}
            onPress={onDismiss}
          />
          <Text style={globalStyles.medium}>Contact</Text>
        </View>
      )}
      <View style={globalStyles.contentView}>
        <View style={styles.article}>
          <Text style={[globalStyles.mediumPlus, styles.label]}>Naam</Text>
          {is_company_car && (
            <FormTextInput
              value={company_name || ''}
              onChangeText={(text) => setCompanyName(text)}
              style={styles.input}
              required={true}
              errorMessage={'Bedrijfsnaam is verplicht'}
              label="Bedrijfsnaam"
            />
          )}
          <FormTextInput
            value={firstName || ''}
            onChangeText={(text) => setFirstName(text)}
            style={styles.input}
            required={true}
            errorMessage={'Voornaam is verplicht'}
            label="Voornaam"
          />
          <FormTextInput
            value={lastName || ''}
            onChangeText={(text) => setLastName(text)}
            style={[styles.input, styles.inputLast]}
            required={true}
            errorMessage={'Achternaam is verplicht'}
            label="Achternaam"
          />
        </View>
        <View style={styles.article}>
          <View style={styles.label}>
            <Text style={globalStyles.mediumPlus}>Contactgegevens&nbsp;</Text>
            <InfoButton
              title="Waarom hebben we je contactgegevens nodig?"
              info={
                is_lease
                  ? 'Belangrijke berichten (bevestiging afspraak, voltooid werk) sturen we per SMS. Overige berichten ontvang je per e-mail.'
                  : 'Belangrijke berichten (offerte, meerwerk en voltooid werk) sturen we per SMS. Bevestigingen ontvang je per email.'
              }
            />
          </View>
          <FormTextInput
            value={phone_number || ''}
            onChangeText={(value) => {
              setPhoneNumber(value);
              setPhoneError(false);
            }}
            onEndEditing={() => {
              const valid =
                !phone_number?.length && phone_number?.match(phoneRegex);
              setPhoneError(!valid);
            }}
            style={styles.input}
            hasError={phoneError}
            required={true}
            errorMessage={
              !phone_number?.length
                ? 'Mobiele telefoonnummer is verplicht'
                : !phone_number?.match(phoneRegex)
                ? 'Ongeldige waarde mobiele telefoonnummer'
                : null
            }
            digits={0}
            label="Mobiele telefoonnummer"
            inputProps={{
              maxLength: 10,
              keyboardtype: 'numeric',
            }}
          />
          <FormTextInput
            value={email || ''}
            onChangeText={(value) => {
              setEmail(value);
              setEmailError(false);
            }}
            onEndEditing={() => {
              const valid = email?.length && email?.match(emailRegex);
              setEmailError(!valid);
            }}
            style={[styles.input, styles.inputFirst]}
            hasError={emailError}
            required={true}
            errorMessage={
              !email?.length
                ? 'Email verplicht'
                : !email?.match(emailRegex)
                ? 'Email heeft een ongeldige waarde'
                : null
            }
            label="Emailadres"
            inputProps={{
              keyboardtype: 'email-address',
            }}
          />
        </View>
        {!is_lease && is_company_car && (
          <View style={[styles.article, styles.borderBottom]}>
            <Pressable onPress={() => setExpand(!expand)}>
              <View style={styles.row}>
                <Text style={globalStyles.mediumPlus}>
                  Beoordeling offerte door iemand anders?
                </Text>
                {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </View>
            </Pressable>
            {expand && (
              <Text style={{marginTop: 10}}>
                Stuur de SMS die je later ontvangt door naar de voor
                beoordelingen en betalingen bevoegde persoon.
              </Text>
            )}
          </View>
        )}
      </View>
    </>
  );

  const footer = (
    <View style={globalStyles.footerView}>
      <PrimaryButton disabled={!isValid} onPress={onNext}>
        {onDismiss ? 'Opslaan' : 'Volgende'}
      </PrimaryButton>
    </View>
  );

  return (
    <SafeAreaView style={globalStyles.mainView}>
      {onDismiss ? (
        <>
          <ScrollView>{content}</ScrollView>
          <View style={{borderTopWidth: 1, borderTopColor: '#dcdcdc'}}>
            {footer}
          </View>
        </>
      ) : (
        <PublicContentView>
          {{
            content,
            footer,
          }}
        </PublicContentView>
      )}
    </SafeAreaView>
  );
};

export default Index;
