import 'react-native-gesture-handler';
import 'intl';
import 'intl/locale-data/jsonp/nl-NL';
import 'moment/locale/nl';

import {StatusBar} from 'expo-status-bar';
import React, {useEffect} from 'react';
import {View} from 'react-native';
import {NavigationContainer, DefaultTheme} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import * as Linking from 'expo-linking';
import {
  configureFonts,
  DefaultTheme as PaperDefaultTheme,
  Provider as PaperProvider,
} from 'react-native-paper';
import {enableScreens} from 'react-native-screens';

import {
  useFonts,
  Roboto_100Thin,
  Roboto_300Light,
  Roboto_400Regular,
  Roboto_500Medium,
  Roboto_700Bold,
  Roboto_900Black,
} from '@expo-google-fonts/roboto';

import moment from 'moment';

import {Text} from './components/controls';
import withSessionState from './components/hoc/with-session-state';
import {useDispatch} from './lib/hooks';
import {addJobReset} from './actions';
import config from './configuration';

import {
  BookingFlow,
  ConfirmationFlow,
  ApprovalFlow,
  PickupFlow,
  OverviewFlow,
} from './flows';

enableScreens();

moment.locale('nl');

const Stack = createStackNavigator();

const prefix = Linking.makeUrl('/');
const routes = {
  screens: {
    Booking: {
      path: '',
      screens: {
        Intro: ':dealer_id',
        Pay: ':order_id/Pay',
        Complete: ':order_id/Complete',
      },
    },
    Confirm: {
      path: 'Confirm',
      screens: {
        Index: ':order_id',
        Pay: ':order_id/Pay',
        Complete: ':order_id/Complete',
        Thanks: ':order_id/Thanks',
        Chat: ':order_id/Chat',
      },
    },
    Approve: {
      path: 'Approve',
      screens: {
        Index: ':order_id',
        Pay: ':order_id/Pay',
        Complete: ':order_id/Complete',
        Chat: ':order_id/Chat',
      },
    },
    Pickup: {
      path: 'Pickup',
      screens: {
        Chat: ':order_id',
        Pay: ':order_id/Pay',
        Complete: ':order_id/Complete',
      },
    },
    Overview: {
      path: 'Overview',
      screens: {
        Chat: ':order_id',
        Pay: ':order_id/Pay',
        Complete: ':order_id/Complete',
      },
    },
  },
};

const theme = {
  ...PaperDefaultTheme,
  colors: {
    ...PaperDefaultTheme.colors,
    primary: '#231fda',
    background: '#ffffff',
    text: '#4a4a49',
  },
  fonts: configureFonts({
    web: {
      regular: {
        fontFamily: 'Roboto_400Regular',
        fontWeight: '400',
      },
      medium: {
        fontFamily: 'Roboto_500Medium',
        fontWeight: '500',
      },
    },
    native: {
      regular: {
        fontFamily: 'Roboto_400Regular',
        fontWeight: '400',
      },
      medium: {
        fontFamily: 'Roboto_500Medium',
        fontWeight: '500',
      },
    },
  }),
};

const App = () => {
  const dispatch = useDispatch();

  let [fontsLoaded] = useFonts({
    Roboto_100Thin,
    Roboto_300Light,
    Roboto_400Regular,
    Roboto_500Medium,
    Roboto_700Bold,
    Roboto_900Black,
  });

  const linking = {
    prefixes: [prefix],
    config: routes,
  };

  useEffect(() => {
    try {
      if (window?.localStorage) {
        window.localStorage.clear();
      }
    } catch {}

    if (config.development) {
      // Don't reset the job for development purposes.
      return;
    }

    dispatch(addJobReset());
  }, [dispatch]);

  if (!fontsLoaded) {
    return <View />;
  }

  return (
    <PaperProvider theme={theme}>
      {/* eslint-disable react/style-prop-object */}
      <StatusBar style="auto" />
      <NavigationContainer
        linking={linking}
        fallback={<Text>Loading...</Text>}
        documentTitle={{
          formatter: (options, route) => `Bahnkick`,
        }}
        theme={{
          ...DefaultTheme,
          colors: {
            background: 'rgb(255, 255, 255)',
            text: 'rgb(74, 74, 73)',
          },
        }}>
        <Stack.Navigator>
          <Stack.Screen
            name="Booking"
            options={{
              headerShown: false,
            }}
            component={BookingFlow}
          />
          <Stack.Screen
            name="Confirm"
            options={{
              headerShown: false,
            }}
            component={ConfirmationFlow}
          />
          <Stack.Screen
            name="Approve"
            options={{
              headerShown: false,
            }}
            component={ApprovalFlow}
          />
          <Stack.Screen
            name="Pickup"
            options={{
              headerShown: false,
            }}
            component={PickupFlow}
          />
          <Stack.Screen
            name="Overview"
            options={{
              headerShown: false,
            }}
            component={OverviewFlow}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </PaperProvider>
  );
};

export default withSessionState(App);
