import React from 'react';

import styles from './styles';
import Pressable from '../Pressable';
import Text from '../Text';

const Link = ({style, linkStyle, children, onPress}) => {
  return (
    <Pressable style={style} onPress={onPress}>
      <Text style={[styles.link, linkStyle]}>{children}</Text>
    </Pressable>
  );
};

export default Link;
